import React from "react";

export const PrivacyPolicy = () => {
  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/UI/Index">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="home">
                    <a href="/UI/PrivacyPolicy">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-details-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="product-list-wrapper">
                <div className="single-product">
                  <div className="product-content">
                    <h2 className="product-name" align="center">
                      <a href="#">PRIVACY POLICY</a>
                    </h2>
                    <br />

                    <div className="product-desc">
                      <p style={{ textAlign: "justify" }}>
                        We, Nimal Maula Incociar Projects LLP (hereinafter
                        referred to as the “Company”) where such expression
                        shall, unless repugnant to the context thereof, be
                        deemed to include its respective legal heirs,
                        representatives, administrators, permitted successors
                        and assigns are the creator of this Privacy Policy, and
                        We ensure our steady commitment to Your privacy, and to
                        the protection of your invaluable information. This
                        privacy policy contains information about an online
                        website called ‘www.wallpap.in’ (hereinafter referred to
                        as the “Website”). In order to provide You with Our
                        uninterrupted use of services, We may collect and, in
                        some circumstances, disclose information about you. To
                        ensure better protection of Your privacy, We provide
                        this notice explaining Our information collection and
                        disclosure policies, and the choices You make about the
                        way Your information is collected and used.
                        <br />
                        <br />
                        This Privacy Policy shall be in compliance with the
                        General Data Protection Regulation (GDPR) in effect from
                        May 25, 2018 and any and all provisions that may read to
                        the contrary shall be deemed to be void and
                        unenforceable as of that date. If you do not agree with
                        the terms and conditions of our Privacy Policy,
                        including in relation to the manner of collection or use
                        of your information, please do not use or access the
                        Site. If you have any questions or concerns regarding
                        this Privacy Policy, you should contact our Customer
                        Support Desk at care@wallpap.in.
                        <br />
                        <br />
                        ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE
                        MEANING ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER,
                        ALL HEADING USED HEREIN ARE ONLY FOR THE PURPOSE OF
                        ARRANGING THE VARIOUS PROVISIONS OF THE AGREEMENT IN ANY
                        MANNER. NEITHER THE USER NOR THE CREATERS OF THIS
                        PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE
                        PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.
                      </p>

                      <br />
                      <h5>1. DEFINITIONS</h5>
                      <p style={{ textAlign: "justify" }}>
                        i) The term ‘You’ &‘User’, shall mean any legal person
                        or entity accessing or using the services provided on
                        this Website, who is competent to enter into binding
                        contracts, as per the provisions of the Indian Contract
                        Act, 1872.
                        <br />
                        <br />
                        ii) The terms ‘We’, ‘Us’, ‘Our’ shall mean the Website
                        and/or the Company, as the context so requires.
                        <br />
                        <br />
                        iii) The term ‘Services’ shall mean the business of
                        being an e-commerce Website that provides Users with
                        curated, well-priced and genuine foreign wallpapers.
                        <br />
                        <br />
                        iv) The terms ‘Party’ &‘Parties’ shall respectively be
                        used to refer to the User and the Company individually
                        and collectively, as the context so requires.
                        <br />
                        <br />
                        v) “Personal Information” shall mean and refer to any
                        personally identifiable information that We may collect
                        from You. For removal of any doubts, please refer to
                        Clause 2.
                        <br />
                        <br />
                        vi) “Third Parties” refer to any website, company or
                        individual apart from the User and the creator of this
                        Website.
                      </p>

                      <br />
                      <h5>2. INFORMATION COLLECTED</h5>
                      <p style={{ textAlign: "justify" }}>
                        We are committed to respecting Your online privacy. We
                        further recognize Your need for appropriate protection
                        and management of any Personal Information You share
                        with us. Personal information shall include:
                        <br />
                        <br />
                        a) Personal data such as, but not limited to, Name,
                        Location, Password, Email ID, Mobile Number, etc.
                        <br />
                        <br />
                        b) Tracking Information such as, but not limited to the
                        IP address of your device and Device ID when connected
                        to the Internet. [This information may include the URL
                        that you just came from (whether this URL is on the
                        Website or not), which URL you next go to (whether this
                        URL is on the Website or not), your computer browser
                        information, and other information associated with your
                        interaction with the Site.]
                        <br />
                        <br />
                        This privacy policy also applies to data we collect from
                        users who are not registered as members of this site,
                        including, but not limited to, browsing behaviour, pages
                        viewed etc. We also collect and store personal
                        information provided by you from time to time on the
                        Site. We only collect and use such information from you
                        that we consider necessary for achieving a seamless,
                        efficient and safe experience, customized to your needs
                        including:
                        <br />
                        <br />
                        a) To enable the provision of services opted for by you;
                        <br />
                        <br />
                        b) To communicate necessary account and service related
                        information from time to time;
                        <br />
                        <br />
                        c) To allow you to receive quality customer care
                        services;
                        <br />
                        <br />
                        d) To comply with applicable laws, rules and regulations
                        <br />
                        <br />
                        Where any service requested by you involves a third
                        party, such information as is reasonably necessary by
                        the Company to carry out your service request may be
                        shared with such third party.
                        <br />
                        <br />
                        We also do use your contact information to send you
                        offers based on your interests and prior activity. The
                        Company may also use contact information internally to
                        direct its efforts for service improvement, but shall
                        immediately delete all such information upon withdrawal
                        of your consent for the same through our ‘unsubscribe’
                        button or through an email to be sent tocare@wallpap.in.
                        <br />
                        <br />
                        We collect and store your search details on the Website,
                        including your search history, the usage of the website
                        and the features and time you have used the website for.
                        <br />
                        <br />
                        The Company will not use your financial information for
                        any purpose other than to complete a transaction with
                        you. To the extent possible, we provide you the option
                        of not divulging any specific information that you wish
                        for us not to collect, store or use. You may also choose
                        not to use a particular service or feature on the Site,
                        and opt out of any non-essential communications from the
                        Website.
                        <br />
                        <br />
                        Further, transacting over the internet has inherent
                        risks which can only be avoided by you following
                        security practices yourself, such as not revealing
                        account/login related information to any other person
                        and informing our customer care team about any
                        suspicious activity or where your account has/may has
                        been compromised.
                        <br />
                        <br />
                        At every stage prior to, during or after information
                        collection, you have the right to access all personally
                        identifiable information provided, rectify or alter all
                        personally identifiable information provided, restrict
                        the level of information to be retained as per your sole
                        discretion and object to the retention, use and
                        potential disclosure of the personally identifiable
                        information.
                      </p>

                      <br />
                      <h5>3. OUR USE OF YOUR INFORMATION</h5>
                      <p style={{ textAlign: "justify" }}>
                        The information provided by You shall be used to
                        <br />
                        <br />
                        a) Contact You when necessary and is also used for
                        targeted marketing campaigns i.e. email marketing,
                        remarketing, look alike campaigns etc., to conduct
                        surveys, learn more about You by linking Your data with
                        additional data through third-party data providers or
                        analysing the data with the help of analytics service
                        providers;
                        <br />
                        <br />
                        b) Improve and develop new products, services, and
                        features;
                        <br />
                        <br />
                        c) Analyse purchase, usage and traffic data.
                        <br />
                        <br />
                        d) To send communication related to order updates and
                        offers through e-mail, SMS and social media channels.
                        This option will be auto renewed after 180 days and if
                        you wish to unsubscribe from receiving offer updates,
                        you can do so.
                        <br />
                        <br />
                        For more details about the nature of such
                        communications, please refer to our Terms of Service.
                        Further, Your personal data may be collected and stored
                        by Us for internal record.
                        <br />
                        <br />
                        We use Your tracking information such as IP addresses,
                        and or Device ID to help identify you and to gather
                        broad demographic information. We will not sell, license
                        or trade Your personal information. We will not share
                        your personal information with others unless they are
                        acting under our instructions or we are required to do
                        so by law.
                        <br />
                        <br />
                        Information collected via Our server logs includes
                        users' IP addresses and the pages visited; this will be
                        used to manage the web system and troubleshoot problems.
                        We also use third-party analytics, tracking,
                        optimization and targeting tools to understand how users
                        engage with our Website so that we can improve it and
                        cater personalized content/ads according to their
                        preferences.
                      </p>

                      <br />
                      <h5>4. HOW INFORMATION IS COLLECTED</h5>
                      <p style={{ textAlign: "justify" }}>
                        Before or at the time of collecting personal
                        information, we will identify the purposes for which
                        information is being collected. If the same is not
                        identified to you, you have the right to request the
                        Company to elucidate the purpose of collection of said
                        personal information, pending fulfilment of which you
                        shall not be mandated to disclose any information
                        whatsoever.
                        <br />
                        <br />
                        We will collect and use your personal information solely
                        with the objective of fulfilling those purposes
                        specified by us, within the scope of consent of the
                        individual concerned or as required by law. We will only
                        retain personal information as long as necessary for the
                        fulfilment of those purposes. We will collect personal
                        information by lawful and fair means and with the
                        knowledge and consent of the individual concerned.
                        <br />
                        <br />
                        Personal data should be relevant to the purposes for
                        which it is to be used, and, to the extent necessary for
                        those purposes, should be accurate, complete, and
                        up-to-date.
                      </p>

                      <br />
                      <h5>5. COOKIES</h5>
                      <p style={{ textAlign: "justify" }}>
                        We use data collection devices such as “cookies” on
                        certain pages of our Websites. “Cookies” are small files
                        sited on your hard drive that assist us in providing
                        customized services. We also offer certain features that
                        are only available through the use of a “cookie”.
                        Cookies can also help us provide information, which is
                        targeted to your interests. Cookies may be used to
                        identify logged in or registered users. Our Website uses
                        session cookies to ensure that you have a good
                        experience. These cookies contain a unique number, your
                        'session ID', which allows our server to recognise your
                        computer and 'remember' what you've done on the site.
                        The benefits of this are:
                        <br />
                        <br />
                        a) You only need to log in once if you're navigating
                        secure areas of the site
                        <br />
                        <br />
                        b) Our server can distinguish between your computer and
                        other users, so you can see the information that you
                        have requested.
                        <br />
                        <br />
                        You can choose to accept or decline cookies by modifying
                        your browser settings if you prefer. This may prevent
                        you from taking full advantage of the Website. We also
                        use various third-party cookies for usage, behavioral,
                        analytics and preferences data. The following are the
                        different types of Cookies used on the Website:
                        <br />
                        <br />
                        a) Authentication cookies: To identify the user and
                        share the content that he or she requested for.
                        <br />
                        <br />
                        b) Functionality cookies: For customized user experience
                        and resuming past course progress.
                        <br />
                        <br />
                        c) Tracking, optimization and targeting cookies: To
                        capture usage metric on device, operating system,
                        browser etc. To capture behavioral metrics for better
                        content delivery. To cater and suggest most suited
                        products and services.
                      </p>

                      <br />
                      <h5>6. EXTERNAL LINKS ON THE WEBSITE</h5>
                      <p style={{ textAlign: "justify" }}>
                        The Website may include advertisements, hyperlinks to
                        other websites, applications, content or resources. We
                        have no control over any websites or resources, which
                        are provided by companies or persons other than Us. You
                        acknowledge and agree that We are not responsible for
                        the availability of any such external sites or
                        resources, and do not endorse any advertising,
                        services/products or other materials on or available
                        from such websites or resources. You acknowledge and
                        agree that We are not liable for any loss or damage
                        which may be incurred by you as a result of the
                        availability of those external sites or resources, or as
                        a result of any reliance placed by you on the
                        completeness, accuracy or existence of any advertising,
                        products or other materials on, or available from, such
                        websites or resources. These external websites and
                        resource providers may have their own privacy policies
                        governing the collection, storage, retention and
                        disclosure of Your Personal Information that You may be
                        subject to.
                        <br />
                        <br />
                        We reiterate that we are not responsible for the
                        contents of any externally linked website, and are not
                        responsible for any services that they may provide. We
                        recommend that you review the privacy policy and terms
                        of use at the linked site so you know how your data will
                        be collected and used on those sites. We may provide
                        links only as a courtesy, but make no representation or
                        warranty as to their operation.
                        <br />
                        <br />
                        We recommend that You enter the external Website and
                        review their privacy policy.
                      </p>

                      <br />
                      <h5>7. YOUR RIGHTS</h5>
                      <p style={{ textAlign: "justify" }}>
                        Unless subject to an exemption, you have the following
                        rights with respect to your personal data:
                        <br />
                        <br />
                        a) The right to request a copy of your personal data
                        which we hold about you;
                        <br />
                        <br />
                        b) The right to request for any correction to any
                        personal data if it is found to be inaccurate or out of
                        date;
                        <br />
                        <br />
                        c) The right to withdraw Your consent to the processing
                        at any time;
                        <br />
                        <br />
                        d) The right to object to the processing of personal
                        data;
                        <br />
                        <br />
                        e) The right to lodge a complaint with a supervisory
                        authority
                        <br />
                        <br />
                        f) The right to obtain information as to whether
                        personal data are transferred to a third country or to
                        an international organization.
                        <br />
                        <br />
                        You have the right to opt out of receiving any
                        promotional or marketing communications.
                      </p>

                      <br />
                      <h5>8. CONFIDENTIALITY</h5>
                      <p style={{ textAlign: "justify" }}>
                        Your information is regarded as confidential and
                        therefore shall not be divulged to any third party,
                        unless if legally required to do so to the appropriate
                        authorities, or if necessary to ensure Users may fully
                        avail of the services of the Website.
                        <br />
                        <br />
                        We shall not sell, share, or rent your personal
                        information to any marketing agencies or any other such
                        companies that indulge in unsolicited communications.
                        Any communication by Us to You shall be undertaken in
                        accordance with Our Terms of Service and Privacy Policy.
                      </p>

                      <br />
                      <h5>
                        9. DISCLOSURE OF YOUR INFORMATION TO THIRD PARTIES
                      </h5>
                      <p style={{ textAlign: "justify" }}>
                        Due to the existing regulatory environment, We cannot
                        ensure that all of your Personal Information shall never
                        be disclosed in ways other than those described in this
                        Privacy Policy. Although we use industry standard
                        practices to protect your privacy, we do not promise,
                        and you should not expect, that your personally
                        identifiable information or private communications would
                        always remain private.
                        <br />
                        <br />
                        i) External Service Providers such as third-party
                        payment gateways: There may be a number of services
                        offered by external service providers that help You use
                        our Website, and there are third-party payment gateways
                        which are mandatorily to be used if You wish to make
                        purchases. If You choose to use these optional and/or
                        mandatory services, and in the course of doing so,
                        disclose information to the external service providers,
                        and/ or grant them permission to collect information
                        about You, then their use of Your information is
                        governed by their privacy policy.
                        <br />
                        <br />
                        ii) Law and Order: We cooperate with law enforcement
                        inquiries, as well as other third parties to enforce
                        laws, such as: intellectual property rights, fraud and
                        other rights. We can, and You so authorise Us, disclose
                        Your Personal Information to law enforcement and other
                        government officials as We, in Our sole discretion,
                        believe necessary or appropriate, in connection with an
                        investigation of fraud, intellectual property
                        infringements, or other activity that is illegal or may
                        expose Us/ Us or You to any legal liability.
                        <br />
                        <br />
                        Any information that you make publicly available on the
                        site may be potentially viewed by any party, and by
                        posting such material it is deemed that you consent to
                        share such information with such parties.
                      </p>

                      <br />
                      <h5>
                        10. ACCESSING, REVIEWING AND CHANGING YOUR PROFILE
                      </h5>
                      <p style={{ textAlign: "justify" }}>
                        Following registration, You can review and change the
                        information You submitted at the stage of registration.
                        An option for facilitating such change shall be present
                        on the Website as explained in the table below and such
                        change can be facilitated by emailing us at
                        care@wallpap.in. If You change any information, We may
                        keep track of Your old information.
                        <br />
                        <br />
                        If you believe that any information we are holding on
                        you is incorrect or incomplete, or to remove Your
                        profile, please write to or email us as soon as
                        possible, at care@wallpap.in. We shall promptly correct
                        any information found to be incorrect. All User profiles
                        are kept confidential and are not viewable by any other
                        members/users.
                        <br />
                        <br />
                        We shall retain in Our files, information You have
                        requested to remove for certain circumstances, such as
                        to resolve disputes, troubleshoot problems and enforce
                        Our terms and conditions. Further, such prior
                        information is never completely removed from Our
                        databases due to technical and legal constraints,
                        including stored 'back up' systems. Therefore, You
                        should not expect that all of Your personally
                        identifiable information shall be completely removed
                        from our databases in response to Your requests.
                      </p>

                      <table
                        className="table"
                        width="100%"
                        style={{ border: "1px solid #ddd" }}
                      >
                        <tbody>
                          <tr style={{ background: "#ddd", color: "#000" }}>
                            <td
                              style={{
                                borderRight: "1px solid #ddd",
                                width: "30%",
                              }}
                            >
                              <b>Opt-out options</b>
                            </td>
                            <td>
                              <b>What you need to do</b>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ borderRight: "1px solid #ddd" }}>
                              Updating your Personal Information in relation to
                              your Account
                            </td>
                            <td>
                              You can update or correct your name, e-mail
                              address, security questions, and other preferences
                              by logging into your account and clicking on the
                              &quot;My Account&quot; page, calling Wallpap
                              customer care executive
                            </td>
                          </tr>
                          <tr>
                            <td style={{ borderRight: "1px solid #ddd" }}>
                              Receiving newsletters and promotional e-mails
                            </td>
                            <td>
                              You can click on the &quot;unsubscribe&quot; link
                              at the bottom of any promotional e-mail that you
                              receive. Alternatively, you may edit your e-mail
                              preferences on your &quot;My Account&quot; page or
                              call Wallpap customer care executive to
                              discontinue receiving any promotional materials by
                              mail/ e-mail.
                            </td>
                          </tr>
                          <tr>
                            <td style={{ borderRight: "1px solid #ddd" }}>
                              Behavioural Based Advertising
                            </td>
                            <td>
                              You can opt-out of some, but not all, online
                              behavioural based advertising by clicking here or
                              by using your browser settings to block cookies or
                              notify you when a cookie is set.
                            </td>
                          </tr>
                          <tr>
                            <td style={{ borderRight: "1px solid #ddd" }}>
                              Remove content from user review
                            </td>
                            <td>
                              Please note that we will endeavour and try to
                              ensure to honour your request to remove
                              information, however, our removal of your
                              information does not completely erase that
                              information from the internet viz. historical
                              copies, or &quot;caches,&quot; may remain.
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <br />
                      <h5>11. CONTROL OF YOUR PASSWORD</h5>
                      <p style={{ textAlign: "justify" }}>
                        When you sign up to become a User, you may also be asked
                        to choose a password. You are entirely responsible for
                        maintaining the confidentiality of your password. It is
                        important that you protect it against unauthorized
                        access of your account and information by choosing your
                        password carefully, and keeping your password and
                        computer secure by signing out after using our services.
                        <br />
                        <br />
                        You agree not to use the account, username, email
                        address or password of another User at any time or to
                        disclose your password to any third party. You are
                        responsible for all actions taken with your login
                        information and password, including fees. If you lose
                        control of your password, you may lose substantial
                        control over your personally identifiable information
                        and may be subject to legally binding actions taken on
                        your behalf. Therefore, if your password has been
                        compromised for any reason, you should immediately
                        change your password. You agree to notify us immediately
                        if you suspect any consistent unauthorized use of your
                        account or access to your password even after changing
                        it.
                      </p>

                      <br />
                      <h5>12. SECURITY</h5>
                      <p style={{ textAlign: "justify" }}>
                        We treat data as an asset that must be protected against
                        loss and unauthorised access. We employ many different
                        security techniques to protect such data from
                        unauthorised access. We follow generally accepted
                        industry standards to protect the Personal Information
                        submitted to Us and information that we have accessed.
                        However, “perfect security” does not exist on the
                        Internet. You therefore agree that any security breaches
                        beyond the control of Our standard security procedures
                        are at Your sole risk and discretion.
                      </p>

                      <br />
                      <h5>13. INDEMNITY</h5>
                      <p style={{ textAlign: "justify" }}>
                        You agree and undertake to indemnify us in any suit or
                        dispute by any Third Party arising out of disclosure of
                        Personal Information by You to Third Parties either
                        through Our Website or otherwise and Your use and access
                        of Apps/Websites and resources of Third Parties. We
                        assume no liability for any actions of Third Parties
                        with regard to Your Personal Information, which You may
                        have disclosed to such Third Parties.
                      </p>

                      <br />
                      <h5>14. SEVERABILITY</h5>
                      <p style={{ textAlign: "justify" }}>
                        Each paragraph of this privacy policy shall be and
                        remain separate from and independent of and severable
                        from all and any other paragraphs herein except where
                        otherwise expressly indicated or indicated by the
                        context of the agreement. The decision or declaration
                        that one or more of the paragraphs are null and void
                        shall have no effect on the remaining paragraphs of this
                        privacy policy.
                      </p>

                      <br />
                      <h5>15. AMENDMENT</h5>
                      <p style={{ textAlign: "justify" }}>
                        Our Privacy Policy may change from time to time.
                      </p>

                      <br />
                      <h5>16. AUTOMATED DECISION MAKING</h5>
                      <p style={{ textAlign: "justify" }}>
                        As a responsible company, we do not use automatic
                        decision-making or profiling.
                      </p>

                      <br />
                      <h5>
                        17. CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL
                        REQUESTS
                      </h5>
                      <p style={{ textAlign: "justify" }}>
                        To withdraw your consent, or to request the download or
                        delete your data with us for any or all our products &
                        services at any time, please email to care@wallpap.in
                        from your registered email address.
                      </p>

                      <br />
                      <h5>18. CONTACT US</h5>
                      <p style={{ textAlign: "justify" }}>
                        If you have any questions or concerns regarding this
                        privacy policy, you should contact us by sending an
                        e-mail to care@wallpap.in
                      </p>

                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

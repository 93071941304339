import { Api } from "./lib/Api";
import React, { useRef, useState, useEffect } from "react";

export const Profile = () => {
  const [profile, setProfile] = useState({});

  const email = (localStorage.getItem("email"));

  const defaultAddress = profile?.addresses?.find((address) => address._id === profile?.defaultAddress)

  const getProfile = async () => {
    let response = await Api.get_all("users", `email=${email}`);
    console.log("getProfile:resp", response);
    await setProfile(response.data[0]);
  };

  const update = async () => {
    // let response = await Api.update("users",profile);
    const response = await Api.updateUser(profile._id, profile);
    console.log("updateProfile:resp", response);
  };

  const addUserAddress = async (address) => {
    try {
      const response = await Api.addUserAddress(profile._id, address);
      console.log("addUserAddress:resp", response);
    } catch (e) {
      console.error(`Error adding user address. Details: ${e}`);
    }
  };

  //Note: when updating an existing address, sent the entire address-doc i.e. even if just updating city field in an existing address, in PUT call
  //send entire address doc i.e. { name,mobile,emailAddress,city: newValue} ( i.e. along with other property existing values)
  //as on server-side we are doing a db query to do an atomic update by replacing the entire address-doc with the new one.
  const updateUserAddress = async (addressId, addressUpdate) => {
    try {
      const response = await Api.updateUserAddress(profile._id, addressId, addressUpdate);
      console.log("updateUserAddress:resp", response);
    } catch (e) {
      console.error(`Error updating user address. Details: ${e}`);
    }
  };

  useEffect(async () => {
    console.log("useEffect");
    // effect
    getProfile();
    Api.verifyToken();

    return () => {
      // cleanup
    };
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="category3">
                    <span>My Profile</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="customer-login-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="loginwbg">
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="customer-register my-account">
                      <div className="register">
                        <div className="profwin">
                          <h4 style={{ color: "black" }}>
                            Personal Information
                          </h4>
                          <input
                            id="Id"
                            name="Id"
                            type="hidden"
                            value="20035"
                          />
                          <table className="table">
                            <tr>
                              <td width="200">
                                <label htmlFor="reg_email">Name</label>
                              </td>
                              <td>{profile.name}</td>
                            </tr>
                            <tr>
                              <td>
                                <label htmlFor="reg_email">Mobile Number</label>
                              </td>
                              <td>{profile.mobile}</td>
                            </tr>
                            <tr>
                              <td>
                                <label htmlFor="reg_email">Email address</label>
                              </td>
                              <td>{profile.email}</td>
                            </tr>

                            <tr style={{ display: "none" }}>
                              <td>
                                <label htmlFor="reg_email">Address</label>
                              </td>
                              <td></td>
                            </tr>
                            <tr style={{ display: "none" }}>
                              <td>
                                <label htmlFor="reg_email">City</label>
                              </td>
                              <td></td>
                            </tr>
                            <tr style={{ display: "none" }}>
                              <td>
                                <label htmlFor="reg_email">State</label>
                              </td>
                              <td></td>
                            </tr>
                            <tr style={{ display: "none" }}>
                              <td>
                                <label htmlFor="reg_email">Country</label>
                              </td>
                              <td></td>
                            </tr>
                            <tr style={{ display: "none" }}>
                              <td>
                                <label htmlFor="reg_email">Postal Code</label>
                              </td>
                              <td></td>
                            </tr>
                          </table>
                        </div>

                        <br />
                        <br />
                        <div align="left">
                          <a
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            href="#"
                            className="crbtn"
                          >
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>{" "}
                            Edit Profile
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="customer-register my-account">
                      <div className="register">
                        <div className="profwin">
                          <h4 style={{ color: "black" }}>Address</h4>
                          <input
                            id="Id"
                            name="Id"
                            type="hidden"
                            value="20035"
                          />
                          <table className="table">
                            {/* <tr>
                              <td width="200">
                                <label htmlFor="reg_email">Name</label>
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>
                                <label htmlFor="reg_email">Mobile Number</label>
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>
                                <label htmlFor="reg_email">Email address</label>
                              </td>
                              <td></td>
                            </tr> */}
                            <tr>
                              <td>
                                <label htmlFor="reg_email">Address</label>
                              </td>
                              <td>{defaultAddress?.full_address}</td>
                            </tr>
                            <tr>
                              <td>
                                <label htmlFor="reg_email">City</label>
                              </td>
                              <td>{defaultAddress?.city}</td>
                            </tr>
                            <tr>
                              <td>
                                <label htmlFor="reg_email">State</label>
                              </td>
                              <td>{defaultAddress?.state}</td>
                            </tr>
                            <tr>
                              <td>
                                <label htmlFor="reg_email">Postal Code</label>
                              </td>
                              <td>{defaultAddress?.post_code}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="clearfix"></div>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Update Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={getProfile}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body wallpapwin">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <input id="Id" name="Id" type="hidden" value="20035" />
                    <div className="form-group col-md-6">
                      <label>Name :</label>
                      <span style={{ color: "red" }}>
                        <span
                          className="field-validation-valid"
                          data-valmsg-for="Name"
                          data-valmsg-replace="true"
                        ></span>
                      </span>
                      <input
                        className="form-control"
                        data-val="true"
                        data-val-required="The Name field is required."
                        id="name"
                        name="Name"
                        type="text"
                        value={profile.name}
                        onChange={(e) => {
                          if (e.target.value.length < 32)
                            setProfile({ ...profile, name: e.target.value });
                        }
                        }
                      />
                    </div>

                    <div className="form-group col-md-4">
                      <label>Mobile Number :</label>
                      <span style={{ color: "red" }}>
                        <span
                          className="field-validation-valid"
                          data-valmsg-for="MobileNumber"
                          data-valmsg-replace="true"
                        ></span>
                      </span>
                      <input
                        className="form-control"
                        data-val="true"
                        data-val-required="The Mobile Number field is required."
                        id="mobilenumber"
                        name="MobileNumber"
                        type="text"
                        value={profile.mobile}
                        onChange={(e) => {
                          if (e.target.value.length > 10) return;
                          if (isNaN(e.target.value)) return;
                          setProfile({ ...profile, mobile: e.target.value })
                        }
                        }
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label>Email :</label>
                      <span style={{ color: "red" }}>
                        <span
                          className="field-validation-valid"
                          data-valmsg-for="Email"
                          data-valmsg-replace="true"
                        ></span>
                      </span>
                      <input
                        className="form-control"
                        data-val="true"
                        data-val-regex="E-mail is not valid"
                        data-val-regex-pattern="^[a-zA-Z0-9_\.-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$"
                        data-val-required="The Email field is required."
                        id="email"
                        name="Email"
                        readonly="True"
                        type="text"
                        value={profile.email}
                      />
                    </div>

                    <div className="form-group col-md-4">
                      <label>Password :</label>
                      <span style={{ color: "red" }}>
                        <span
                          className="field-validation-valid"
                          data-valmsg-for="Password"
                          data-valmsg-replace="true"
                        ></span>
                      </span>
                      <input
                        className="form-control"
                        data-val="true"
                        data-val-required="The Password field is required."
                        id="passwprd"
                        name="Password"
                        type="password"
                        value={profile.password}
                        onChange={(e) => setProfile({ ...profile, password: e.target.value })}
                      />
                    </div>
                    <div style={{ display: "none" }}>
                      <div className="form-group col-md-6">
                        <label>Address :</label>
                        <span style={{ color: "red" }}>
                          <span
                            className="field-validation-valid"
                            data-valmsg-for="Address"
                            data-valmsg-replace="true"
                          ></span>
                        </span>
                        <input
                          className="form-control"
                          data-val="false"
                          data-val-required="The Address field is required."
                          id="address1"
                          name="Address"
                          type="text"
                          value={profile.address1}
                          onChange={(e) => setProfile({ ...profile, address1: e.target.value })}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>City :</label>
                        <span style={{ color: "red" }}>
                          <span
                            className="field-validation-valid"
                            data-valmsg-for="City"
                            data-valmsg-replace="true"
                          ></span>
                        </span>
                        <input
                          className="form-control"
                          data-val="false"
                          data-val-required="The City field is required."
                          id="city"
                          name="City"
                          type="text"
                          value={profile.city}
                          onChange={(e) => setProfile({ ...profile, city: e.target.value })}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>State :</label>
                        <span style={{ color: "red" }}>
                          <span
                            className="field-validation-valid"
                            data-valmsg-for="State"
                            data-valmsg-replace="true"
                          ></span>
                        </span>
                        <input
                          className="form-control"
                          data-val="false"
                          data-val-required="The State field is required."
                          id="state"
                          name="State"
                          type="text"
                          value={profile.state}
                          onChange={(e) => setProfile({ ...profile, state: e.target.value })}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>Country :</label>
                        <span style={{ color: "red" }}>
                          <span
                            className="field-validation-valid"
                            data-valmsg-for="Country"
                            data-valmsg-replace="true"
                          ></span>
                        </span>
                        <input
                          className="form-control"
                          data-val="false"
                          data-val-required="The Country field is required."
                          id="country"
                          name="Country"
                          type="text"
                          value="INDIA"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>Postal Code :</label>
                        <span style={{ color: "red" }}>
                          <span
                            className="field-validation-valid"
                            data-valmsg-for="PinCode"
                            data-valmsg-replace="true"
                          ></span>
                        </span>
                        <input
                          className="form-control"
                          data-val="false"
                          data-val-required="The PinCode field is required."
                          id="pincode"
                          name="PinCode"
                          type="number"
                          value={profile.pin}
                          onChange={(e) => setProfile({ ...profile, pin: e.target.value })}
                        />
                      </div>
                    </div>

                    <div
                      className="form-group col-md-6"
                      style={{ marginTop: 25 }}
                    >
                      <input
                        checked="checked"
                        data-val="true"
                        data-val-checkboxtrue="This field is required."
                        data-val-required="The isMembership field is required."
                        id="ismembership"
                        name="isMembership"
                        type="checkbox"
                        value="true"
                      />
                      <input
                        name="isMembership"
                        type="hidden"
                        value="false"
                      />{" "}
                      Membership Card
                    </div>

                    <div
                      className="form-group col-md-12"
                      style={{ marginTop: 25, textAlign: "center" }}
                    >
                      <input
                        type="submit"
                        value="Update"
                        data-dismiss="modal"
                        className="btn btn-primary"
                        onClick={update}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useRef, useState, useEffect } from "react";
import { Api } from "./lib/Api";
import { Config } from "./Config";
import { Calculator } from "./user-components/Calculator";
import { SocialIconList } from "./user-components/SocialIconList";
import ReactImageMagnify from 'react-image-magnify';
import './index.css';
import {Modal} from "antd";

export const Details = React.memo(({setNCartItems,nCartItems,props}) => {
  const [displayedIimage_url, setDisplayedIimage_url] = useState();
  const [imageEle,setImageEle] = useState(void 0);
  const [onError,setOnError] = useState({});
  const [showModal,setShowModal] = useState(false)

  const setDisplayImage = (url)=>{
    setDisplayedIimage_url(url);
    const image = new Image();
    image.onload = ()=>{
      setImageEle(image);
      console.log(image.width,image.height)
    }
    image.onerror = ()=>{
      setImageEle(null);
    }
    image.src = url;
    setImageEle(null);
  }

  const [selectedProduct, setSelectedProduct] = useState({productName:"",productDescription:""});

  const [selectedModalImage,setSelectedModalImage] = useState(()=>{
    if(selectedProduct && selectedProduct.image_url){
      setSelectedModalImage(selectedProduct.image_url);
    }else if(selectedProduct && selectedProduct.gallery){
      for(let i=0;i<selectedProduct.gallery.length;i++){
         if( !onError[selectedProduct.gallery[i]]) {
           return selectedProduct.gallery[i]
         }
      }
    }
    return null;
  })

  useEffect(()=>{
    if(selectedProduct && selectedProduct.image_url){
      setSelectedModalImage(selectedProduct.image_url);
    }else if(selectedProduct && selectedProduct.gallery){
      for(let i=0;i<selectedProduct.gallery.length;i++){
        if( !onError[selectedProduct.gallery[i]]) {
          setSelectedModalImage(selectedProduct.gallery[i]);
          break;
        }
      }
    }
  },[selectedProduct,showModal])

/*  const getProductIdFromLocationURL = ()=>{
    const query = window.location.href.split("?")[1]||null;
    let id = null, _;
    if (query) {
      [_, id] = query.split("=");
      return id;
    }
    return null;
  }*/

  const getSelectedProduct = async() => {
    const productId = props.match?.params?.productId;
    /* const query = window.location.href.split("?")[1]||null;
    let id = null, _;
     if (query){
       [ _ , id] = query.split("=");

       console.log("id Selected",id);*/

    const response = await Api.getProductByProductId(productId);//await Api.get_all("products", `productId=${productId}`);

    console.log("Product retrieved from DB", response.data[0]);
    response.data[0].image_url = `${Config.IMAGES_BASE}/products/${response.data[0].image}`;
    setSelectedProduct(response.data[0]);
    setDisplayImage(`${Config.IMAGES_BASE}/products/${response.data[0].image}`)
  }
  
  // selectedProduct.gallery.map((image)=>`${Config.IMAGES_BASE}/gallery/${image}`);

  const [variantProducts, setVariantProducts] = useState([]);
  const [addingToCart, setAddingToCart] = useState(false);

  const getVariantProducts = async () => {
    let response = await Api.get_all(
      "products",
      `designCode=${selectedProduct.designCode}`
    );
    console.log("getVariantProducts:resp", response);
    response.data.map(
      (el) => (el.image_url = `${Config.IMAGES_BASE}/products/${el.image}`)
    );
    await setVariantProducts(response.data);
    console.log("variantProducts:", variantProducts);
  };

  useEffect(async () => {
    console.log("useEffect Details");
    getSelectedProduct();
    return () => {
      // cleanup
    };
  }, []);

  useEffect(async () => {
    if(!selectedProduct._id)return;
    console.log("useEffect Details getVariantProducts",selectedProduct);
    getVariantProducts();

    return () => {
      // cleanup
    };
  }, [selectedProduct]);


  const [quantity, setQuantity] = useState(1);

  let cartProducts = JSON.parse(localStorage.getItem('cartProducts')) || [];
  console.log('cartProducts',cartProducts);

  const addToCart = (total) =>{
    setAddingToCart(true);
    setTimeout(()=>delayedAddToCart(total), 1500);
  }
  const delayedAddToCart = (total) => {
    let _quantity = 0
    if (total) _quantity = total;
    else _quantity = quantity;
    if (_quantity === 0 ) setQuantity(1);
    let _selectedProduct = JSON.parse(JSON.stringify(selectedProduct))
    _selectedProduct.quantity = Number(_quantity || 1);
    let idx = lookUpList(cartProducts, _selectedProduct);
    if (idx < 0 ){
      setNCartItems((nCartItems + 1));
      cartProducts.push(_selectedProduct);
    }else {
      cartProducts[idx].quantity += _selectedProduct.quantity;
    }

    localStorage.setItem('cartProducts',JSON.stringify(cartProducts));
    localStorage.removeItem('calculations');
    setAddingToCart(false);
  }


  const [showPopup, setShowPopup] = useState(false);
  const addToWishlist = () =>{
    let _selectedProduct = JSON.parse(JSON.stringify(selectedProduct));
    let wishList = JSON.parse(localStorage.getItem('wishlist')) || [];
    if (lookUpList(wishList, _selectedProduct) < 0){
      wishList.push(_selectedProduct);
      localStorage.setItem('wishlist',JSON.stringify(wishList));
    }
    setShowPopup(true);
    setTimeout(()=>setShowPopup(false),2000);
  }
  
  const lookUpList = (cartProducts, selectedProduct) =>{
    for ( let i = 0 ; i < cartProducts.length ; i++){
      let prod = cartProducts[i];
      if (prod._id == selectedProduct._id){
        return i;
      }
    }
    return -1;
  }
  //CALCULATOR

  const [hoverImage, setHoverImage] = useState(false);
  const handleHoverOn = () =>{

    setHoverImage(true);
  }
  const handleHoverOff = () =>{
    setHoverImage(false);
  }

  var myImg = document.getElementById("zoom1");

  function findPosition(oElement)
  {
    if(typeof( oElement.offsetParent ) != "undefined")
    {
      for(var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent)
      {
        posX += oElement.offsetLeft;
        posY += oElement.offsetTop;
      }
        return [ posX, posY ];
      }
      else
      {
        return [ oElement.x, oElement.y ];
      }
  }

  function getCoordinates(e)
{
  var PosX = 0;
  var PosY = 0;
  var ImgPos;
  ImgPos = findPosition(myImg);
  if (!e) var e = window.event;
  if (e.pageX || e.pageY)
  {
    PosX = e.pageX;
    PosY = e.pageY;
  }
  else if (e.clientX || e.clientY)
    {
      PosX = e.clientX + document.body.scrollLeft
        + document.documentElement.scrollLeft;
      PosY = e.clientY + document.body.scrollTop
        + document.documentElement.scrollTop;
    }
  PosX = PosX - ImgPos[0];
  PosY = PosY - ImgPos[1];
  console.log('PosX',PosX);
  console.log('PosY',PosY);
  }
  
  // myImg.onmousedown = GetCoordinates;

  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>

                  <li className="category3">
                    <span id="pname">{selectedProduct.productName} {selectedProduct.designCode}{selectedProduct.designVariant}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <input id="price" name="CustomerPrice" type="hidden" value={selectedProduct.mrp - (selectedProduct.discount / 100) * selectedProduct.mrp} />
      <div className="product-details-area">
        <div className="container">
          <div className="row">

            <div className="col-md-5 col-sm-5 col-xs-12">
              <div className="zoomWrapper">
                <div id="img-1" className="zoomWrapper single-zoom" onMouseEnter={handleHoverOn}
                     onMouseLeave={handleHoverOff} onClick={()=>setShowModal(true)}>
                  {
                      imageEle && <ReactImageMagnify {...{
                        smallImage: {
                          src: displayedIimage_url,
                          isFluidWidth: true,
                          sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                        },
                        largeImage: {
                          src: displayedIimage_url,
                          width: imageEle.width,
                          height: imageEle.height
                        },
                        isHintEnabled: true,
                        enlargedImageClassName:'enlargedImage',
                        shouldHideHintAfterFirstActivation: false,
                        enlargedImagePortalId:"enlargedImageWrapper",
                        enlargedImageContainerDimensions : {width: '200%',height:'100%' },
                        enlargedImageContainerStyle :{
                          border: '1px solid rgb(214, 214, 214)',
                          borderRadius:'4px'
                        }
                      }} />
                  }
                </div>
                <div className="single-zoom-thumb">
                  <ul
                    className="bxslider"
                    id="gallery_01"
                  >
                    <li className={"gallery-item"}>
                      <a
                        href="#"
                        className="elevatezoom-gallery active"
                        data-update=""
                        // data-image="~/Content/ProductImages/BRICKS AND STONE WALLPAPER BLUE GREY WPVOL7-18B.jpg"
                        // data-zoom-image="~/Content/ProductImages/BRICKS AND STONE WALLPAPER BLUE GREY WPVOL7-18B.jpg"
                        onClick={() => {
                          setDisplayImage(selectedProduct.image_url);
                        }}
                      >
                        <img
                          src={selectedProduct.image_url}
                          alt=""
                          width="80px"
                        />
                      </a>
                    </li>

                    {(selectedProduct.gallery || []).map((image, idx) => (
                      <li
                        key={idx}
                        className={"gallery-item"}
                        style={{display: onError[image] ? 'none':''}} //hide the not loaded image
                      >
                        <a
                          href="#"
                          className="elevatezoom-gallery active"
                          onClick={() => {
                            setDisplayImage(
                              `${Config.IMAGES_BASE}/gallery/${image}`
                            );
                          }}
                        >
                          <img
                            src={`${Config.IMAGES_BASE}/gallery/${image}`}
                            alt=""
                            width="80px"
                            onError={()=>{
                              //hide the not loaded image
                              const newItems = {...onError};
                              newItems[image] = true;
                              setOnError(newItems);
                            }}
                          />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-7 col-sm-7 col-xs-12" style={{display: hoverImage?'none':''}}>
              <div className="product-list-wrapper">
                <div className="single-product">
                  <div className="product-content">
                    <h2 className="product-name">
                      <a href="#">{selectedProduct.productName}  {selectedProduct.designCode}{selectedProduct.designVariant}</a>
                    </h2>
                    <div className="rating-price">
                      <div className="pro-rating">
                        <img
                          alt=""
                          src="../../../../Content/RatingImgs/norating.png"
                        />
                      </div>

                      <div className="price-boxes">
                        <p>This product is also available in...</p>
                        {variantProducts.map((variant, idx) => (
                          <a
                            key={idx}
                            className="img-thumbnail"
                            href={`/details/${variant.productId}`/*`/details?productid=${variant._id}`*/}
                            title={variant.productName}
                            onClick={() => {
                              localStorage.setItem(
                                "selectedProduct",
                                JSON.stringify(variant)
                              );
                            }}
                          >
                            <img src={variant.image_url} alt="" width="50" />
                          </a>
                        ))}
                      </div>

                      <div className="price-boxes">
                        <span className="new-price" id="custprice">
                          ₹{" "}
                          {selectedProduct.customerPrice || ""}{" "}
                          &nbsp; <del>₹ {selectedProduct.mrp || ""}</del>
                        </span>
                      </div>
                    </div>

                    <div className="product-desc">
                      <p className="availability in-stock">
                        Availability: <span>In Stock</span>
                      </p>
                    </div>

                    <div className="actions-e">
                      <div className="action-buttons-single">
                        <div className="inputx-content">
                          <label>Quantity:</label>
                          <button
                            onClick={() => {
                              if (quantity >= 1) setQuantity(quantity - 1);
                            }}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            name="qtyx"
                            id="qtyx"
                            value={quantity}
                            title="Qty"
                            className="input-text qty"
                            min="1"
                            onChange ={((e)=>setQuantity(e.target.value))}
                          />
                          <button onClick={() => setQuantity(quantity + 1)}>
                            +
                          </button>
                        </div>

                        <div className="add-to-cart">
                          <a
                            style={{ cursor: "pointer" }}
                            className="addtocart"
                            onClick={() => addToCart(quantity)}
                          >
                            <i className="fa fa-shopping-cart"></i> {addingToCart?"Added!":"Add to cart"}
                          </a>
                          <div className="popup">
                            <a
                              className="wish_icon"
                              style={{ cursor: "pointer" }}
                              onClick={addToWishlist}
                            >
                              <i className="fa fa-heart"></i>
                            </a>
                            <span
                              className={showPopup?"popuptext show":"popuptext show hide"}
                              id="myPopup"
                            >
                              {" "}
                              Added to your wishlist!
                            </span>
                          </div>
                        </div>
                        <div className="clear"></div>
                        <br />
                        <div className="add-to-cart">
                          <a
                            className="pro_calbtn"
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            href="#"
                            // onClick="BindLocalstorage"
                          >
                            <i className="fa fa-calculator"></i> Wallpaper
                            Calculator
                          </a>
                        </div>

                        <div
                          className="social-footer"
                          style={{ marginTop: 20 }}
                        >
                          <SocialIconList />
                        </div>

                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-sm-7 col-xs-12"
             id={"enlargedImageWrapper"} style={{display: hoverImage?'':'none'}}
            >
            </div>

            <div className="clearfix"></div>

            <div className="col-md-12">
              <div className="single-product-tab">
                <ul className="details-tab">
                  <li className="active">
                    <a href="#home" data-toggle="tab">
                      Description
                    </a>
                  </li>
                  <li className="">
                    <a href="#messages" data-toggle="tab">
                      Review (0)
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" id="home">
                    <div className="product-tab-content">
                    <p>{selectedProduct.productName}</p>
                      
                      <p>{selectedProduct.productSummary}</p>
                      
                      <ul>
                        {selectedProduct.productDescription.split(";").map((ele,idx)=>(
                        <li key={idx}>
                          {ele}
                        </li>
                        ))}
                        
                      </ul>
                      
                      <p style={{marginTop:16}}>Disclaimer : Wallpap™ deals in premium quality imported wallpapers. Please beware of dealers selling the same designs in cheap quality at lower prices</p>

                      <div className="clearfix"></div>
                      <br />
                      <br />
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane" id="messages">
                    <div className="single-post-comments col-md-6 col-md-offset-3">
                      <div className="comments-area">
                        <span style={{ textAlign: "center" }}>NO REVIEWS </span>
                        <br />

                        <div className="comments-list">
                          <ul></ul>
                        </div>
                      </div>
                      <div className="comment-respond">
                        <h4 className="comment-reply-title">Add a review</h4>
                        <span className="email-notes">
                          Your email address will not be published. Required
                          fields are marked *
                        </span>

                        <div className="row">
                          <div className="col-md-12">
                            <p>Name *</p>
                            <input type="text" id="reviewname" name="name" />
                          </div>
                          <div className="col-md-12">
                            <p>Email *</p>
                            <input type="email" id="reviewemail" name="email" />
                          </div>
                          <div className="col-md-12">
                            <p>Your Rating</p>

                            <div
                              className="pro-rating"
                              style={{ margin: "10px 0px" }}
                            >
                              <a
                                href="#"
                                // onmouseover="return Decide1(6);"
                                id="Rating6"
                                //onclick="return Decide(6);"
                                className="Empty"
                              ></a>
                              <button
                                type="button"
                                style={{
                                  borderStyle: "none",
                                  height: 20,
                                  width: 20,
                                }}
                                id="Rating1"
                                // onmouseover="return Decide1(1);"
                                //onclick="return Decide(1);"
                                className="Filled"
                              ></button>
                              <button
                                type="button"
                                style={{
                                  borderStyle: "none",
                                  height: 20,
                                  width: 20,
                                }}
                                id="Rating2"
                                // onmouseover="return Decide1(2);"
                                //onclick="return Decide(2);"
                                className="Filled"
                              ></button>
                              <button
                                type="button"
                                style={{
                                  borderStyle: "none",
                                  height: 20,
                                  width: 20,
                                }}
                                id="Rating3"
                                // onmouseover="return Decide1(3);"
                                //onclick="return Decide(3);"
                                className="Filled"
                              ></button>
                              <button
                                type="button"
                                style={{
                                  borderStyle: "none",
                                  height: 20,
                                  width: 20,
                                }}
                                id="Rating4"
                                // onmouseover="return Decide1(4);"
                                //onclick="return Decide(4);"
                                className="Empty"
                              ></button>
                              <button
                                type="button"
                                style={{
                                  borderStyle: "none",
                                  height: 20,
                                  width: 20,
                                }}
                                id="Rating5"
                                // onmouseover="return Decide1(5);"
                                // onclick="return Decide(5);"
                                className="Empty"
                              ></button>
                              <span
                                id="lblRate"
                                style={{ color: "green", display: "none" }}
                              >
                                Fair
                              </span>
                              <input
                                type="hidden"
                                id="HiddenField1"
                                value="3"
                              />
                              <label
                                id="lblratevalue"
                                style={{ color: "green" }}
                              ></label>
                            </div>
                            <span
                              id="reviewresult"
                              style={{ color: "green" }}
                            ></span>
                            <span
                              id="reviewvalidate"
                              style={{ color: "red" }}
                            ></span>
                          </div>
                          <div className="col-md-12 comment-form-comment">
                            <p>Your Review</p>
                            <textarea
                              id="message"
                              cols="30"
                              rows="10"
                              name="message"
                            ></textarea>
                            <input
                              type="submit"
                              value="Submit"
                              //onclick="SendReview();"
                            />
                          </div>

                          <div className="col-md-12">
                            <div className="container-inner">
                              <div
                                className="alert alert-success"
                                role="alert"
                                id="divsuccess"
                                style={{ display: "none" }}
                              >
                                Successfully Submited!.
                              </div>
                              <div
                                className="alert alert-danger"
                                role="alert"
                                id="diverror"
                                style={{ display: "none" }}
                              >
                                Review Not Submited!. due to server Error.
                                Please Try Again.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Calculator addToCart={addToCart} setQuantity={setQuantity}/>
      {
        showModal &&
          <Modal
            visible={true}
            title={null}
            footer={null}
            destroyOnClose={true}
            closable={true}
            mask={true}
            keyboard={true}
            onCancel={()=>setShowModal(false)}
            width={'calc(100vw - 200px)'}
            centered={true}
            className={"wpp-modal-dialog"}
          >
            <div className={"modal-wrapper"}>
              <div className={"modal-left-content"}>
                {
                  selectedModalImage &&
                    <img
                        src={`${selectedModalImage === selectedProduct.image_url ? selectedProduct.image_url: `${Config.IMAGES_BASE}/gallery/${selectedModalImage}`}`} alt={""} className={"modal-img-full-view"}
                    />
                }
              </div>
              <div className={"modal-right-content"}>
                <div className={"modal-product-title"}>
                  {selectedProduct.productName}  {selectedProduct.designCode}{selectedProduct.designVariant}
                </div>
                <div className={"modal-product-list"}>
                  {
                    selectedProduct.image_url && <div className={`modal-product-item ${selectedProduct.image_url === selectedModalImage ? 'active':''}`}
                                                    onClick={()=> setSelectedModalImage(selectedProduct.image_url)}
                      >
                        <img
                            src={selectedProduct.image_url}
                            alt=""
                            width="80px"
                        />
                      </div>
                  }
                  {
                    ([...selectedProduct.gallery] || []).map((image,index)=>{
                      return <div className={`modal-product-item ${image === selectedModalImage ? 'active':''}`} key={index} style={{display: onError[image] ? 'none':''}}
                            onClick={()=> setSelectedModalImage(image)}
                      >
                        <img
                            src={`${Config.IMAGES_BASE}/gallery/${image}`}
                            alt=""
                            width="80px"
                        />
                      </div>
                    })
                  }
                </div>
              </div>
            </div>
          </Modal>
      }
    </div>
  );
});

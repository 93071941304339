import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Api } from './lib/Api'


export const Cart = ({ nCartItems, setNCartItems }) => {
  const _cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
  const history = useHistory()

  const [cartProducts, setCartProducts] = useState(_cartProducts);
  const [total, setTotal] = useState(_cartProducts.reduce((total, cart) =>
    (Number(total) + Number(cart.quantity) * Number(cart.customerPrice)).toFixed(2), 0));

  const removeRow = (idx) => {
    const _cartProducts = JSON.parse(localStorage.getItem("cartProducts"));
    _cartProducts.splice(idx, 1);
    localStorage.setItem("cartProducts", JSON.stringify(_cartProducts));
    setCartProducts(_cartProducts);
    setTotal(_cartProducts.reduce((total, cart) =>
      (Number(total) + Number(cart.quantity) * Number(cart.customerPrice)).toFixed(2), 0));
    setNCartItems(nCartItems - 1);
  };

  const emptyCoupon = { name: "", code: "", discount: 0, from: 0, to: 0 }
  const [couponCode, setCouponCode] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(emptyCoupon);

  const addCoupon = async () => {
    if (couponCode.trim() === "") return;
    const response = await Api.get_all('coupons', `code=${couponCode}`);
    console.log('addCoupon:resp', response);
    if (response.data.length > 0) {
      setAppliedCoupon(response.data[0]);
      localStorage.setItem("coupon", couponCode);
    } else {
      setAppliedCoupon(emptyCoupon);
    }
  }

  const [payment, setPayment] = useState({
    amount: { total: 0, coupon: couponCode, membershipDiscount: 0, couponDiscount: 0, grandTotal: 0, saved: 0 },
  });

  const calculatePayment = async () => {
    // slip = { 
    //   products : [{code, variant, price, quantity, subTotal}],
    //   amount : {total, coupon, membershipDiscount, couponDiscount, grandTotal, saved}
    // }
    let slip = {
      products: cartProducts,
      amount: { total: 0, coupon: couponCode, membershipDiscount: 0, couponDiscount: 0, grandTotal: 0, saved: 0 }
    }
    let response = await Api.payment(slip);
    console.log("payment response", response);
    setPayment(response.data);
  }


  const [profile, setProfile] = useState({});
  const email = localStorage.getItem("email");

  const getProfile = async () => {
    let response = await Api.get_all("users", `email=${email}`);
    console.log("getProfile:resp", response);

    if (response.data[0].points == undefined) {
      response.data[0].points = 0;
    }
    if (response.data[0].points > 40 * 200) {  //40 points per Rup and min 200 points
      response.data[0].pointsDiscount = 200;  //max 200 Rs can be claimed in 1 purchase
    } else {
      response.data[0].pointsDiscount = 0
    }
    await setProfile(response.data[0]);
  };

  useEffect(async () => {
    console.log("useEffect cart 1");
    Api.verifyToken();

    // effect
    getProfile();

    return () => {
      // cleanup
    };
  }, []);
  useEffect(async () => {
    console.log("useEffect cart 2");
    // effect
    calculatePayment();

    return () => {
      // cleanup
    };
  }, [cartProducts, couponCode]);

  useEffect(() => {
    localStorage.setItem("cartProducts", JSON.stringify(cartProducts));
  }, [cartProducts])

  const handleChangeQuantity = (productId, qty, idx) => {
    if (qty === 0) {
      removeRow(idx)
      return
    }
    setCartProducts(cartProducts.map((product) => {
      if (product.id === productId) {
        return {
          ...product,
          quantity: qty
        }
      } else {
        return product
      }
    }))
  }

  const gotoProduct = (product) => {
    history.push(`/details/${product.productId}`)
  }

  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="category3">
                    <span>Shopping Cart</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cart-area-start">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                {cartProducts.map((cart, idx) => {
                  return (
                    <div className="col-md-12 cart-product-card">
                      <div className="row">
                        <div className="col-xs-4 col-md-2">
                          <img
                            src={cart.image_url}
                            className="img-responsive cart-img"
                            alt=""
                            onClick={() => gotoProduct(cart)}
                          />
                        </div>
                        <div className="col-xs-8 col-md-8">
                          <h5>{cart.productName}, {cart.designCode}{cart.designVariant}</h5>
                          <div className="cart-actions">
                            <div className="product-quantity-action">
                              <label>Qty : </label>
                              <button onClick={() => { if (cart.quantity >= 1) handleChangeQuantity(cart._id, cart.quantity - 1, idx) }}>
                                -
                              </button>
                              <input
                                type="number"
                                name="qtyx"
                                id="qtyx"
                                value={cart.quantity}
                                title="Qty"
                                className="input-text qty"
                                min="1"
                                onChange={((e) => handleChangeQuantity(cart._id, e.target.value, idx))}
                              />
                              <button onClick={() => handleChangeQuantity(cart._id, cart.quantity + 1, idx)}>
                                +
                              </button>
                            </div>
                            <div>| &nbsp;</div>
                            <div className="delete-button" onClick={() => removeRow(idx)}>Delete</div>
                          </div>
                          <div className="price-container-mobile">
                            <h5 className="price-mobile">₹{cart.customerPrice}</h5>
                          </div>
                        </div>
                        <div className="col-xs-1 col-md-2 price-container">
                          <h5 className="price">₹{cart.customerPrice}</h5>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="row">
                {cartProducts.length > 0 ? (
                  <div className="col-md-12 w-100 warning-box">
                    <div align="center">
                      <a className="crbtn" href="products">Continue Shopping</a>
                    </div>
                  </div>
                ) : (
                  <div className="">
                    <div className="col-md-12 col-sm-4 col-xs-4 align-left">
                      <h4>Your Cart is Empty!</h4>
                      <h6>Add items to it now</h6>
                      <br />
                      <a className="crbtn" href="/products">
                        Shop Now
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="shipping coupon cart-totals">
                <ul>
                  <li className="cartSubT">
                    {" "}
                    {/* Total: <span id="total">₹{total}</span> */}
                    Total: <span id="total">₹{payment.amount.total}</span>
                  </li>
                  <li className="cartSubT">
                    {" "}
                    <div style={{ display: "inline" }}>
                      <input
                        type="text"
                        className="coupon-input"
                        placeholder="discount coupon code"
                        id="dcoupon"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                      />
                    </div>
                    <div style={{ display: "inline", float: "right", marginTop: -45 }}>
                      <a
                        className="crbtn"
                        id="AddCoupon"
                        style={{ cursor: "pointer" }}
                        onClick={addCoupon}
                      >
                        APPLY
                      </a>
                    </div>
                  </li>
                  {appliedCoupon.discount > 0 ?
                    <li className="cartSubT" style={{ color: "green" }}>
                      {/* Coupon Discount({appliedCoupon.discount}%): */}
                      Coupon Discount({payment.amount.couponDiscount}%):
                      {/* <span id="mempercent">(-) ₹{(appliedCoupon.discount/100*total).toFixed(2)}</span> */}
                      <span id="mempercent">(-) ₹{payment.amount.couponDiscountAmount}</span>
                    </li>
                    : ""}
                  {payment.amount.membershipDiscount > 0 ?
                    <li className="cartSubT" style={{ color: "green" }}>
                      Membership Discount 1({payment.amount.membershipDiscount}%):{" "}
                      {/* <span id="mempercent">(-) ₹{(0.1*total).toFixed(2)}</span> */}
                      <span id="mempercent">(-) ₹{payment.amount.membershipDiscountAmount}</span>
                    </li> : ""
                  }
                  {profile.pointsDiscount > 0 ?
                    <li className="cartSubT" style={{ color: "green" }}>
                      Points Redeem(8000 points):
                      <span id="mempercent">(-) ₹{profile.pointsDiscount}</span>
                    </li>
                    : ""}
                  <li className="cartSubT">
                    {" "}
                    {/* Grand Total: <span id="grandtotal">₹{((0.9*total).toFixed(2) - (appliedCoupon.discount/100*total).toFixed(2) - (profile.pointsDiscount || 0).toFixed(2)).toFixed(2)}</span> */}
                    Grand Total: <span id="grandtotal">₹{payment.amount.grandTotal}</span>
                  </li>
                </ul>

                <span
                  className="cartSubT"
                  id="licoupon"
                  style={{ color: "green" }}
                >
                  You have Saved<span id="saveamount"> ₹{payment.amount.saved}</span>
                </span>
                <input type="hidden" id="hdncustmmem" value="1" />

                <Link className="proceedbtn" id="btncheckout" to="/checkout">
                  {" "}
                  PROCEED TO CHECK OUT
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const Wishlist = ({ setNCartItems, nCartItems }) => {
  const history = useHistory()
  const _wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  const cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];

  const [wishlist, setWishlist] = useState(_wishlist);
  const [addingToBasket, setAddingToBasket] = useState('')

  const removeRow = (idx) => {
    const _wishlist = JSON.parse(localStorage.getItem("wishlist"));
    _wishlist.splice(idx, 1);
    localStorage.setItem("wishlist", JSON.stringify(_wishlist));
    setWishlist(_wishlist);
  };

  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  }, [wishlist])

  const gotoProduct = (product) => {
    history.push(`/details/${product.productId}`)
  }

  const lookUpList = (cartProducts, selectedProduct) => {
    for (let i = 0; i < cartProducts.length; i++) {
      let prod = cartProducts[i];
      if (prod._id == selectedProduct._id) {
        return i;
      }
    }
    return -1;
  }

  const addToBasket = (wish, wishId) => {
    setAddingToBasket(wish._id)
    let _quantity = 1
    let _selectedProduct = JSON.parse(JSON.stringify(wish))
    _selectedProduct.quantity = Number(_quantity || 1);
    let idx = lookUpList(cartProducts, _selectedProduct);
    if (idx < 0) {
      setNCartItems((nCartItems + 1));
      cartProducts.push(_selectedProduct);
    } else {
      cartProducts[idx].quantity += _selectedProduct.quantity;
    }

    localStorage.setItem('cartProducts', JSON.stringify(cartProducts));
    setTimeout(() => removeRow(wishId), 2000)
  }

  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="category3">
                    <span>My Wish List</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cart-area-start">
        <div className="container">
          <div className="row">
            <div className="col-md-12">

              <div className="row">
                {wishlist.map((wish, idx) => {
                  return (
                    <div className="col-md-12 cart-product-card">
                      <div className="row">
                        <div className="col-xs-4 col-md-2">
                          <img
                            src={wish.image_url}
                            className="img-responsive cart-img"
                            alt=""
                            onClick={() => gotoProduct(wish)}
                          />
                        </div>
                        <div className="col-xs-10 col-md-10">
                          <h5>{wish.productName}, {wish.designCode}{wish.designVariant}</h5>
                          <h5>₹{wish.customerPrice}</h5>
                          <div className="wishlist-actions">
                            <div className="add-basket-button" onClick={() => addToBasket(wish, idx)}>
                              {addingToBasket === wish._id ? 'Added to Basket' : 'Add to Basket'}
                            </div>
                            <div>&nbsp; | &nbsp;</div>
                            <div className="delete-button" onClick={() => removeRow(idx)}>Delete</div>
                          </div>
                          <div className="price-container-mobile">
                            <h5 className="price-mobile">₹{wish.customerPrice}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>

              {/* <div className="table-responsive">
                <table className="cart-table" id="cart">
                  <thead>
                    <tr>
                      <th width="100">Product</th>
                      <th>Product name</th>
                      <th>Unit Price</th>
                      <th>Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wishlist.map((wish, idx) => (
                      <tr key={idx} data-cid="342">
                        <td>
                          <a href="#">
                            <img
                              src={wish.image_url}
                              className="img-responsive"
                              alt=""
                            />
                          </a>
                        </td>
                        <td>
                          <h6>
                            {wish.productName} {wish.designCode}
                            {wish.designVariant}
                          </h6>
                        </td>
                        <td>
                          <div className="cart-price">
                            ₹{wish.customerPrice}
                          </div>
                        </td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => removeRow(idx)}
                          >
                            <i className="fa fa-times"></i>
                          </a>
                        </td>
                      </tr>
                    ))}

                    {wishlist.length > 0 ? (
                      <tr id="lastrow">
                        <td className="actions-crt" colspan="7">
                          <div align="center">
                            <a
                              className="crbtn"
                              href="products"
                            >
                              Continue Shopping
                            </a>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr id="cart-is-empty">
                        <td className="actions-crt" colspan="7">
                          <div className="">
                            <div className="col-md-12 col-sm-4 col-xs-4 align-left">
                              <h4>Your List is Empty!</h4>
                              <h6>Add items to it now</h6>
                              <br />
                              <a className="cbtn" href="/products">
                                Shop Now
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

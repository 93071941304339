import React, { useState, useEffect } from "react";
import { Api } from "../lib/Api";
import { Config } from "../Config";

export const Parallax = () => {
  
  const [docs, setDocs] = useState([
  ]);
  const populateTable = async () => {
    const response = await Api.get_all("parallax_imgs");
    await setDocs(response.data);
    console.log("docs", docs);
  };

  useEffect(() => {
    populateTable();
  }, []);

  return (
    <div className="parallax-container parallax-wrapper parallax-container--id">
      <div
        className="parallax parallax--image"
        style={ docs.length>0? {
          background:
            `url(${Config.IMAGES_BASE}/parallax_imgs/${docs[0].image}) 80% 50% no-repeat fixed`,
        }:{}}
      >
        <div className="overlay overlay-color parallax-overlay--id"></div>
        <div className="parallax-content">
          <div className="container">
            <div className="content">
              <em>wallpap.in</em>
              <div className="clearer"></div>
              <h2>BRAND BY INCOCIAR</h2>
              <div className="clearer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

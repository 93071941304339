import React from "react";

function HeaderMenu1() {
  const email = localStorage.getItem("email");

  return (
    <div className="header-top downwin">
      <div className="container">
        <div className="row">
          <div className="col-md-12 header-right" align="right">
            <span>WELCOME TO Wallpap</span>
            { email?
            <span></span>
            :
            <>
              <span>
                <a href="/login">SIGN IN</a>
              </span>
              <span>
                <a href="/create-account">CREATE AN ACCOUNT</a>
              </span>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderMenu1;

import React, { useState, useEffect } from "react";
import { Api } from "../lib/Api";
import { Config } from "../Config";
import { Link } from "react-router-dom";

function Banners() {
  const [docs, setDocs] = useState([
  ]);
  const populateTable = async () => {
    const response = await Api.get_all("banner_imgs");
    setDocs(response.data);
    console.log("docs", docs);
    initNivoSlider();
  };

  const initNivoSlider = () => {
    window.jQuery("#ensign-nivoslider").nivoSlider({
      effect: "random",
      slices: 15,
      boxCols: 8,
      boxRows: 4,
      animSpeed: 500,
      pauseTime: 5000,
      startSlide: 0,
      directionNav: false,
      controlNavThumbs: false,
      pauseOnHover: false,
      manualAdvance: false,
    });
  };

  console.log("Banners", window.jQuery);

  useEffect(() => {
    populateTable();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="slider-area an-1 hm-1 clr">
            <div className="bend niceties preview-2">
              <div id="ensign-nivoslider" className="slides">
                {docs.map((doc, idx) => (
                    <img key={idx}
                    src={`${Config.IMAGES_BASE}/banner_imgs/${doc.image}`}
                    alt=""
                    title={`#slider-direction-${idx}`}
                    />
                ))}
              </div>
              {docs.map((doc, idx) => (
                  <div
                    key={idx}
                    id={`slider-direction-${idx}`}
                    className="t-cn slider-direction"
                  >
                   <a href={`${doc.url}`} style={{height:1000, width:1500, display:"block",position:"absolute"}}></a>

                    <div className="slider-progress"></div>
                    <div className="slider-content t-lfl lft-pr slider-1">
                      <div className="title-container title-compress">
                        <h2 className="title1">{`${doc.title}`}</h2>
                        <h4 className="title2"> {`${doc.subtitle}`}</h4>
                      </div>
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <script src="/custom-slider/home.js" type="text/javascript"></script>
    </div>
  );
}

export default Banners;

import React from "react";

export const SocialIconList = () => {
  return (
    <ul>
      <li>
        <a href="https://www.facebook.com/wallpapwallcovering/" target="_blank">
          <i className="fa fa-google"></i>
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/wallpapwallcovering/" target="_blank">
          <i className="fa fa-facebook"></i>
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/wallpapwallcoverings/"
          target="_blank"
        >
          <i className="fa fa-instagram"></i>
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/wallpapwallcoverings/"
          target="_blank"
        >
          <i className="fa fa-youtube"></i>
        </a>
      </li>
      {/* <li>
                        <a
                          href="https://twitter.com/wallpaphyd/"
                          target="_blank"
                        >
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li> */}
      {/* <li>
                        <a
                          href="https://in.pinterest.com/wallpap_wallcovering/"
                          target="_blank"
                        >
                          <i className="fa fa-pinterest"></i>
                        </a>
                      </li> */}
      {/* <li>
                        <a
                          href="https://www.linkedin.com/company/wallpap"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </li> */}
    </ul>
  );
};

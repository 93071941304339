import { Api } from "./Api";


const welcomeEmail = (email, password, calculations_html) => `<html>
<head></head>   
<body>
    
<p><img src="https://wallpap.in/img/wallpap_email.jpg" style="width: 150px;"></p>
<p><b><br>Hello ${email},</b></p>

<div id="content">
<p>
	<br>Thank you for registering with India’s first online wallpaper store that offers you -
	<br>
	<br>1. Completely Transparent Prices
	<br>2. Expert Design Consultant At ZERO Extra Cost
	<br>3. Free Installation At Your Convenience
  <br>
	<br>Plus, As our valued customer, we offer you an Additional 10% OFF on all our products and
	<br>services through our membership program.
</p>
<p>That's not all! You are also entitled to receive Reward Points every time you show with us
	<br>that can be redeemed to get MORE DISCOUNTS on your next order.</p>
<p>Contact us to learn more about our products and services.</p>
<p><a href="https://wallpap.in/login">Login Now</a> To Start Shopping For Wallpapers Online.</p>
<p>We wish you a delightful shopping experience.</p>
<br>
<br>
${calculations_html}

</div>

<p><b>Team Wallpap</b>
	<br><a href="https://wallpap.in">www.wallpap.in </a></p>
<p>Disclaimer: You are receiving this mail because you transacted on 
<a href="https://wallpap.in">https://wallpap.in</a>
</p>
 
</body>
</html>`

// const forgotPasswordEmail = (email,password) => `Just Testing`;

const forgotPasswordEmail = (email,password) => `<html>
<head></head>   
<body>
    
<p><img src="https://wallpap.in/img/wallpap_email.jpg" style="width: 150px;"></p>
<p><b><br>Hello ${email}</b></p>

<div>
<p>
<br/>Your requested recovery password is
<br/>
<b>${password}</b>
<br/>
<br/>We wish you a delightful shopping experience.
</p>
</div>

<p><b>Team Wallpap</b>
	<br><a href="https://wallpap.in">www.wallpap.in </a></p>
<p>Disclaimer: You are receiving this mail because you transacted on 
<a href="https://wallpap.in">https://wallpap.in</a>
</p>
 
</body>
</html>`

const calculatorEmail = (email, calculations_html) => `<html>
<head></head>   
<body>
    
<p><img src="https://wallpap.in/img/wallpap_email.jpg" style="width: 150px;"></p>
<p><b><br>Hello ${email},</b></p>

<div id="content">
<p>
	<br>We hope you found our online wallpaper calculator useful!
	<br>
	<br>Here’s your free wallpaper requirement estimate, calculated as per information given by you.
	<br>
  ${calculations_html}
	<br>We wish you a delightful shopping experience.
</p>
</div>

<p><b>Team Wallpap</b>
	<br><a href="https://wallpap.in">www.wallpap.in </a></p>
<p>Disclaimer: You are receiving this mail because you transacted on 
<a href="https://wallpap.in">https://wallpap.in</a>
</p>
 
</body>
</html>`

function getCalculationsHtml() {
  const _calculations = JSON.parse(localStorage.getItem("calculations"));
  if (!_calculations) {
    return "";
  }
  const _selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
  const { productName, designCode, designVariant } = _selectedProduct;
  const product = `${productName} ${designCode}${designVariant}`;

  const rows = _calculations.map(
    (wall, idx) =>
      `
        <tr>
          <td>
            <span>${wall.wall}</span>
          </td>
          <td>
            <span>${wall.height} ft * ${wall.width} ft = ${wall.height * wall.width} sqft (${wall.nRolls} Rolls)</span>
          </td>
          <td align="right">
            ₹${wall.subtotal}
          </td>
        </tr>
        `
  );

  const html = `
        Your Estimates For Product: ${product} <br>

        <table border='1' style='border-collapse:collapse'>
          <thead>
            <tr>
              <td>Wall</td>
              <td>Rolls</td>
              <td>Subtotal</td>
            </tr>
          </thead>
          <tbody>
            ${rows.join("")}
          </tbody>
        </table>
        <table>
          <tbody>
            <tr id="0010">
              <td></td>
              <td></td>
              <td></td>
              <td align="right">
                <b>
                  Total : ₹
                  ${_calculations.reduce(
                    (total, wall) => total + Number(wall.subtotal),
                    0
                  )}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
        <br/>`;

  localStorage.removeItem("calculations");
  return html;
}

export async function sendWelcomeEmail(email, password) {

  const calculations_html = getCalculationsHtml();

  let html = welcomeEmail(email, password, calculations_html);  
 
  //send email
  const msg = {
    to: email,
    from: "info@incociar.com", // Use the email address or domain you verified above
    subject: "Welcome to Wallpap!",
    // text: "and easy to do anywhere, even with Node.js",
    html: html,
  };

  const response = await Api.email({ email: msg });
  console.log("sendEstimation:resp", response);
}

export async function sendForgotPassordEmail(email,password) {
    const html = forgotPasswordEmail(email,password);

    //send email
    const msg = {
      to: email,
      from: "info@incociar.com", // Use the email address or domain you verified above
      subject: "Password Recovery",
      html : html,
    }
    const response = await Api.email({email:msg});
    console.log("sendForgotPassordEmail:resp", response);
}

export const sendAccountNotFoundEmail = async (email) => {
  const html =`
  Hi there;
  <br/>Welcome to Wallpap.in. 
  <br/>You requested a password recovery. You are not registered with us.
  <br/>login : ${email}
  <br/> You can create a new account at <a href="https://wallpap.in/create-account">https://www.wallpap.in</a>
  <br/>Regards
  <br/>The Wallpap Team`;

  //send email
  const msg = {
    to: email,
    from: "info@incociar.com", // Use the email address or domain you verified above
    subject: "Password Recovery",
    // text: "and easy to do anywhere, even with Node.js",
    html : html,
  }

  const response = await Api.email({email:msg});
  console.log("sendEstimation:resp", response);
}

export const sendCalculatorEmail = async (email) => {
  const calculations_html = getCalculationsHtml();

  const html = calculatorEmail(email,calculations_html)
  console.log("html", html);

  const msg = {
    to: email,
    // to: "m.amjad.beg@gmail.com",
    from: "info@incociar.com", // Use the email address or domain you verified above
    subject: "Wallpap Wallpaper Requirement Estimate!",
    // text: "and easy to do anywhere, even with Node.js",
    html: html,
  };

  let response = await Api.email({ email: msg });
  console.log("sendEstimation:resp", response);
};

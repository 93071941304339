import React from "react";

export const Aboutus = () => {
  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/UI/Index">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="home">
                    <a href="/UI/Aboutus">About Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-details-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="product-list-wrapper">
                <div className="single-product">
                  <div className="product-content">
                    <h2 className="product-name">
                      <a href="#">About Us</a>
                    </h2>

                    <div className="product-desc">
                      <p style={{ textAlign: "justify" }}>
                        <b>Thank you for visiting wallpap.in</b>
                        <br />
                        Wallpap.in owned by NIMAL MAULA INCOCIAR PROJECTS LLP.
                        Located in the heart of City of Pearls, Hyderabad. We
                        have served customers for over 3+ years. We specialize
                        in residential & commercial projects offering world
                        Class Solutions with expertise in Interior designing,
                        Constructions, Civil works, Architecture & an exclusive
                        e-commerce platform for wallpaper Installations
                        (wallpap.in) & Home transformations.
                        <br />
                        <br />
                        WALLPAP is a one-stop-shop for your wallcoverings with
                        experts that assist you throughout your project. Get
                        every project on-time and on-budget without compromising
                        on quality and excellent craftsmanship! Smooth,
                        stress-free and hassle-free wallpaper consultation –
                        just a call away.
                        <br />
                        <br />
                        <b>Quality Guaranteed</b> <br />
                        <br />
                        ● Quality is key at Wallpap, we take a very serious
                        approach when it comes to the quality of the wallpaper,
                        ensuring that our customers get their money’s worth.
                        <br />
                        <br />
                        ● We’ve cut out all unnecessary costs which enable us to
                        offer Luxury wallpapers & Eco-friendly wallcoverings to
                        all our customers at the best value for their money.
                        <br />
                        <br />
                        ● We provide transparent pricing for online shopping of
                        wallpapers serving Hyderabad and its surroundings with
                        fast delivery & hassle-free wallpaper Installation.
                        <br />
                        <br />
                        So, when shopping at wallpap you can be sure to get
                        high-quality wallpapers, at affordable prices.
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// import './Landing.css';
import Banners from './user-components/Banners';
import { Footer } from './user-components/Footer';
import HeaderLogoArea from './user-components/HeaderLogoArea';
import HeaderMenu1 from './user-components/HeaderMenu1';
import HeaderTop from './user-components/HeaderTop';
import { InstagramArea } from './user-components/InstagramArea';
import { LandingModal } from './user-components/LandingModal';
import MainMenuTop from './user-components/MainMenuTop';
import { MobileNav } from './user-components/MobileNav';
import { NewProducts } from './user-components/NewProducts';
import { OurProductArea } from './user-components/OurProductArea';
import { Parallax } from './user-components/Parallax';
import { UnitBannerArea } from './user-components/UnitBannerArea';

export const Landing = () =>  {
  window.scrollTo(0, 0);
 
  
  return (
    <>
      {/* <MobileNav/>
      <HeaderTop/>
      <HeaderMenu1/>
      <HeaderLogoArea/>
      <MainMenuTop/> */}
      <Banners/>
      <UnitBannerArea/>
      <OurProductArea/>
      <Parallax/>
      <NewProducts/>
      <InstagramArea/>
      {/*<LandingModal/>*/}
      <a 
        href="https://api.whatsapp.com/send?phone=919100446425&text=Hi,%20I%20want%20to%20place%20an%20Order" 
        className="whatsapp-float" target="_blank">
        <i className="fa fa-whatsapp my-float"></i>
      </a>
      {/* <Footer/> */}
    </>
  );
}
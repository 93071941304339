import { Api } from "../lib/Api";
import React, { useRef, useState, useEffect } from "react";
import { Config } from "../Config";

export const NewProducts = () => {
  const [newProducts, setNewProducts] = useState([]);

  /* Randomize array in-place using Durstenfeld shuffle algorithm */
  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
  }
  const getNewProducts = async ()=>{
    let response = await Api.get_all("products","categories=NEW PRODUCTS");
    console.log("getNewProducts:resp", response);
    response.data.map((el)=>el.image_url = `${Config.IMAGES_BASE}/products/${el.image}`);
    
    let _newProducts = [...response.data];
    shuffleArray(_newProducts);
    if (_newProducts.length > 9 ){
      _newProducts.length = 9;
    }
    setNewProducts(_newProducts);
    console.log('newProducts:',);
  };

  useEffect(async () => {
    await getNewProducts();

    return () => {
      // cleanup
    };

  },[]);
    
  
  
  return (
        
    <div className="our-product-area new-product">
    <div className="container">
        <div className="area-title">
            <h2>New products</h2>
            <br/>
        </div>
        <div className="row zero-margin">

            {
              newProducts.map((el, idx)=>(
              <div key = {idx} className={idx==0?"col-md-4 new_probox pad-0_5_0_0":"col-md-2 new_probox pad-0_5_0_0"}>
                  <div className="single-product first-sale  zero-margin" >
                      <span className="sale-text-new">New</span>
                      <div className="product-img">
                          <a href="/products?categories=NEW PRODUCTS">
                              <img className="primary-image" src={el.image_url} alt="" />
                          </a>
                      </div>
                  </div>
              </div>
              ))
            }

            {/* <div className="col-md-2 new_probox pad-0_5_0_0">
                <div className="single-product first-sale  zero-margin">
                    <span className="sale-text">New</span>
                    <div className="product-img">
                        <a href="/products">
                            <img className="primary-image" src="/product-imgs/STRIPES AND TEXTURE WALLPAPER GREEN METALLIC WPVOL6-28D.jpg" alt="" />
                        </a>
                    </div>
                </div>
            </div>
             */}
            

        </div>
    </div>
</div>


    )
}

function HeaderTop() {
    return (
        <div className="header-top mob_hdtop">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 header-left">
                        <span>care@wallpap.in</span>
                    </div>
                    <div className="col-md-8 header-right" align="right">
                        <span>CALL US : 9100 44 64 25</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTop
import { Api } from "../lib/Api";
import React, { useRef, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function MainMenuTop({ nCartItems, setNCartItems }) {
  const [categories, setCategories] = useState([]);
  const [themes, setThemes] = useState([]);
  const [colors, setColors] = useState([]);
  
  const token = (localStorage.getItem("token"));
  const email = (localStorage.getItem("email"));
  

  const getSelectLists = async () => {
    let response = await Api.get_all("categories");
    console.log("getSelectLists:resp", response);
    await setCategories(response.data.map((el) => el.name));
    // await setSelectedCategories(categories.map((el) => false));

    response = await Api.get_all("themes");
    console.log("getSelectLists:resp", response);
    await setThemes(response.data.map((el) => el.name));
    // await setSelectedThemes(themes.map((el) => false));

    response = await Api.get_all("colors");
    console.log("getSelectLists:resp", response);
    await setColors(response.data.map((el) => el.name));
    // await setSelectedColors(colors.map((el) => false));
  };

  const logout = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("email");
    localStorage.clear();
    window.location.href = "/";
  }

  useEffect(async () => {
    console.log("useEffect MainMenuTop");
    console.log("token, email MainMenuTop",token,email);
    // effect
    getSelectLists();

    return () => {
      // cleanup
    };
  }, []);

  return (
    <>
      <hr />

      <div className="container main-menu--top">
        <div className="row">
          <div className="col-md-9 text-left">
            <div className="mainmenu">
              <nav>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="expand">
                    <a href="#">
                      COLOUR{" "}
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </a>
                    <ul
                      className="restrain mega-menu megamenu1"
                      id="color-megamenu"
                    >
                      {colors.map((color, idx) => (
                        <li key={idx}>
                          <Link
                            to={`/products?colors=${color}`}
                            onClick={() => {
                              console.log("selected color", color);
                              localStorage.setItem(
                                "selectedMainMenu",
                                JSON.stringify({ colors: color })
                              );
                              document.getElementById(
                                "color-megamenu"
                              ).style.display = "none";
                              setTimeout(() => {
                                document.getElementById(
                                  "color-megamenu"
                                ).style.display = "block";
                              }, 1000);
                            }}
                          >
                            {" "}
                            {color}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className="expand">
                    <a href="#">
                      THEME{" "}
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </a>
                    <ul
                      className="restrain mega-menu megamenu1"
                      id="theme-megamenu"
                    >
                      {themes.map((theme, idx) => (
                        // <li key={idx}>
                        //   <a href="/products" onClick={()=>{
                        //       console.log('selected theme',theme);
                        //       localStorage.setItem("selectedMainMenu",JSON.stringify({themes:theme}));
                        //       }
                        //     }
                        //   >{theme}</a>
                        // </li>
                        <li key={idx}>
                          <Link
                            to={`/products?themes=${theme}`}
                            onClick={() => {
                              console.log("selected theme", theme);
                              localStorage.setItem(
                                "selectedMainMenu",
                                JSON.stringify({ themes: theme })
                              );

                              document.getElementById(
                                "theme-megamenu"
                              ).style.display = "none";
                              setTimeout(() => {
                                document.getElementById(
                                  "theme-megamenu"
                                ).style.display = "block";
                              }, 1000);
                            }}
                          >
                            {" "}
                            {theme}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <Link
                      to={`/products?categories=NEW PRODUCTS`}
                      onClick={() => {
                        console.log("selected NEW PRODUCTS");
                        localStorage.setItem(
                          "selectedMainMenu",
                          JSON.stringify({ categories: "NEW PRODUCTS" })
                        );
                      }}
                    >
                      {" "}
                      NEW PRODUCTS
                    </Link>
                  </li>
                  <li>
                    <Link
                     to={`/products?categories=BEST SELLERS`}
                      onClick={() => {
                        console.log("selected BEST SELLERS");
                        localStorage.setItem(
                          "selectedMainMenu",
                          JSON.stringify({ categories: "BEST SELLERS" })
                        );
                      }}
                    >
                      {" "}
                      BEST SELLERS
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/products"
                      onClick={() => {
                        console.log("selected CATALOGUE");
                        localStorage.setItem(
                          "selectedMainMenu",
                          JSON.stringify({ categories: null })
                        );
                      }}
                    >
                      {" "}
                      CATALOGUE
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div className="col-md-3 text-right">
            <div className="top-detail">
              <div className="disflow crt-edt">
                <div className="circle-shopping expand">
                  <div className="shopping-carts text-right">
                    <div className="cart-toggler">
                      <a href={email?"/cart":"/login?redirect=cart"}>
                        <i className="icon-bag"></i>
                      </a>
                      <a href={email?"/cart":"/login?redirect=cart"}>
                        <span className="cart-quantity">{nCartItems}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="disflow">
                {email?
                <div className="expand dropps-menu">
                  <a href="#">
                    <i className="fa fa-user"></i>My Account
                  </a>
                  <ul className="restrain language">
                    <li><Link to="/profile">Profile</Link></li>
                   {/* <li><Link to="/membership">Membership</Link></li>*/}
                    <li><Link to="/orders-cashfree">Orders</Link></li>
                    <li><Link to="/wishlist">Wishlist</Link></li>
                    <li><a href="/" onClick={logout}>Log Out</a></li>
                  </ul>
                </div>
                :
                <div></div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainMenuTop;

import React from "react";

export const TandC = () => {
  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/UI/Index">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="home">
                    <a href="/UI/TermsandConditions">Terms & Conditions</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-details-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="product-list-wrapper">
                <div className="single-product">
                  <div className="product-content">
                    <h2 className="product-name" align="center">
                      <a href="#">TERMS OF SERVICES AND CONDITIONS</a>
                    </h2>
                    <br />

                    <div className="product-desc">
                      <h5>1. GENERAL</h5>
                      <p style={{ textAlign: "justify" }}>
                        a) This document is an electronic record in terms of
                        Information Technology Act, 2000 and rules there under
                        as applicable and the amended provisions pertaining to
                        electronic records in various statutes as amended by the
                        Information Technology Act, 2000. This electronic record
                        is generated by a computer system and does not require
                        any physical or digital signatures. This document is
                        published in accordance with the provisions of Rule 3
                        (1) of the Information Technology (Intermediaries
                        guidelines) Rules, 2011 that require publishing the
                        rules and regulations, privacy policy and Terms of Use
                        for access or usage of the Website ‘www.wallpap.in’
                        (hereinafter referred to as the “Website”) which is
                        owned and operated by Nimal Maula Incociar LLP
                        (hereinafter referred to as the “Company”).
                        <br />
                        <br />
                        b) Where such expression shall, unless repugnant to the
                        context thereof, be deemed to include its respective
                        representatives, administrators, employees, directors,
                        officers, agents and their successors and assigns
                        <br />
                        <br />
                        c) For the purpose of these Terms of Use (“Terms”),
                        wherever the context so requires,
                        <br />
                        <br />
                        i) The term ‘You’ &‘User’, shall mean any legal person
                        or entity accessing or using the services provided on
                        this Website, who is competent to enter into binding
                        contracts, as per the provisions of the Indian Contract
                        Act, 1872.
                        <br />
                        <br />
                        ii) The terms ‘We’, ‘Us’, ‘Our’ shall mean the Website
                        and/or the Company, as the context so requires.
                        <br />
                        <br />
                        iii) The term ‘Services’ shall mean the business of
                        being an e-commerce Website that provides Users with
                        curated, well-priced and genuine foreign wallpapers.
                        <br />
                        <br />
                        iv) The terms ‘Party’ &‘Parties’ shall respectively be
                        used to refer to the User and the Company individually
                        and collectively, as the context so requires.
                        <br />
                        <br />
                        d) The headings of each section in these Terms are only
                        for the purpose of organizing the various provisions
                        under these Terms in an orderly manner, and shall not be
                        used by either Party to interpret the provisions
                        contained herein in any manner. Further, it is
                        specifically agreed to by the Parties that the headings
                        shall have no legal or contractual value.
                        <br />
                        <br />
                        e) The use of this Website by the User is solely
                        governed by these Terms as well as the Privacy Policy
                        and any modifications or amendments made thereto by the
                        Company, from time to time, at its sole discretion.If
                        You continue to access and use this Website, You are
                        agreeing to comply with and be bound by the following
                        terms and conditions of use and Our Privacy Policy. The
                        User expressly agrees and acknowledges that these Terms
                        and Policy is co-terminus, and that expiry/termination
                        of either one will lead to the termination of the other.
                        <br />
                        <br />
                        f) The User unequivocally agrees that these Terms and
                        the aforementioned Policy constitute a legally binding
                        agreement between the User and the Company, and that the
                        User shall be subject to the rules, guidelines,
                        policies, terms, and conditions applicable to any
                        service that is provided by the Website, and that the
                        same shall be deemed to be incorporated into these
                        Terms, and shall be treated as part and parcel of the
                        same. The User acknowledges and agrees that no signature
                        or express act is required to make these Terms and the
                        Policy binding on the User, and that the User’s act of
                        visiting the any part of the Website constitutes the
                        User’s full and final acceptance of these Terms and the
                        aforementioned Policy.
                        <br />
                        <br />
                        g) The Company reserves the sole and exclusive right to
                        amend or modify these Terms without any prior permission
                        or intimation to the User, and the User expressly agrees
                        that any such amendments or modifications shall come
                        into effect immediately. The User has a duty to
                        periodically check the terms and stay updated on its
                        requirements. If the User continues to use the Website
                        following such a change, the User will be deemed to have
                        consented to any and all amendments/modifications made
                        to the Terms. In so far as the User complies with these
                        Terms, it is granted a personal, non-exclusive,
                        non-transferable, revocable, limited privilege to access
                        and use the Website.If the User does not adhere to the
                        changes, You must stop using the Services at once. Your
                        continuous use of the Services will signify your
                        acceptance of the changed terms.
                      </p>

                      <br />
                      <h5>2. REGISTRATION</h5>
                      <p style={{ textAlign: "justify" }}>
                        To fully avail the use of the Services, a one-time User
                        registration is a mandatory requirement and social media
                        accounts may be linked. You may access the Services by
                        signing in as User by providing the following
                        information which shall include but not be limited to
                        name, contact details and details for product delivery.
                        <br />
                        <br />
                        Registration for this Website is only for those of 18
                        years of age or over, barring those “Incompetent to
                        Contract” which inter alia include minors and
                        insolvents. The Company reserves the right to terminate
                        Your account on knowledge of You being incompetent to
                        contract and having registered on the Website or
                        availing any of its Services.
                        <br />
                        <br />
                        Further, at any time during Your use of this Website,
                        including but not limited to the time of registration,
                        You are solely responsible for protecting the
                        confidentiality of Your Username and password, and any
                        activity under the account shall be deemed to have been
                        done by You. In the case that You provide Us with false
                        and/or inaccurate details or We have reason to believe
                        You have done so, We hold the right to permanently
                        suspend Your account. You are responsible for
                        safeguarding your password. You agree that you will not
                        disclose your password to any third party and that you
                        will take sole responsibility for any activities or
                        actions under your account, whether or not you have
                        authorized such activities or actions. You will
                        immediately notify us of any unauthorized use of your
                        account.
                      </p>

                      <br />
                      <h5>3. SERVICE OVERVIEW</h5>
                      <p style={{ textAlign: "justify" }}>
                        The Website is India's first platform to sell foreign
                        brands and provide advice, updates, expert tips, and
                        videos on how to look and feel gorgeous always! With
                        many curated, well-priced and 100% genuine foreign
                        wallpapers, the Website prides itself for offering a
                        comprehensive selection of Floral, Geometrical, Stripes
                        & Texture, Classical 3D wallpapers
                      </p>

                      <br />
                      <h5>4. ELIGIBILITY</h5>
                      <p style={{ textAlign: "justify" }}>
                        The User represents and warrants that they are competent
                        and eligible to enter into legally binding agreements
                        and that they have the requisite authority to bind
                        themselves to these Terms, as determined solely by the
                        provisions of the Indian Contract Act, 1872 and the laws
                        of their own jurisdictions. The User may not use the
                        Website if they are not competent to contract under the
                        Indian Contract Act, 1872, or are disqualified from
                        doing so by any other applicable law, rule or regulation
                        currently in force in their jurisdictions or in India.
                      </p>

                      <br />
                      <h5>5. CONTENT</h5>
                      <p style={{ textAlign: "justify" }}>
                        All text, graphics, user interfaces, visual interfaces,
                        photographs, trademarks, logos, sounds, music and
                        artwork displayed on the Website (collectively,
                        "Content"), is generated/provided by the Website and We
                        have control and make guarantees regarding the quality,
                        the accuracy, integrity or genuineness of such Content.
                        The Company is solely responsible for the integrity,
                        authenticity, quality and genuineness of the products
                        and details that are displayed on the Website but the
                        Website and Company bear no liability whatsoever for any
                        incorrect information fed by any User to the Website.
                        The Website also bears no responsibility for any minor
                        variation between the images that are shared by the User
                        and the actual appearance of the products, once
                        delivered to the User. Users are encouraged to read the
                        product descriptions and use their own discretion before
                        availing of the Services and purchasing any products
                        from the Website. Users are also encouraged to note that
                        all advice and information provided by the Website in
                        respect of beauty, self-care and other topics are for
                        general information purposes only and are not intended
                        to be of a diagnostic nature. Users shall use their own
                        discretion before deciding to act on advice that is
                        posted on the Website. The Users have a personal,
                        non-exclusive, non-transferable, revocable, limited
                        privilege to access the content on the Website. Users
                        shall not copy, adapt, and modify any content without
                        written permission of the Company.
                      </p>

                      <br />
                      <h5>6. TERM</h5>
                      <p style={{ textAlign: "justify" }}>
                        These Terms shall continue to form a valid and binding
                        contract between the Parties, and shall continue to be
                        in full force and effect until the User continues to
                        access and use the Website. A User may terminate their
                        use of the Website at any time. The Company may
                        terminate these Terms and close a User account at any
                        time without notice and/or suspend or terminate a User’s
                        access to the Website at any time and for any reason, in
                        its sole discretion. Such suspension or termination
                        shall not limit our right to take any other action
                        against you that we consider appropriate. It is also
                        hereby declared that the Company may discontinue the
                        Website without any prior notice.
                      </p>

                      <br />
                      <h5>7. TERMINATION</h5>
                      <p style={{ textAlign: "justify" }}>
                        The Company reserves the right, in its sole discretion,
                        to unilaterally terminate the User’s access to the
                        Services, or any portion thereof, at any time, without
                        notice and cause. The Website also reserves the
                        universal right to deny access to particular Users, to
                        any/all of its Services without prior notice/explanation
                        in order to protect the interests of the Website and/or
                        other visitors to the Website. The Website reserves the
                        right to limit, deny or create different access to the
                        Website and its features with respect to different
                        User(s), or to change any of the features or introduce
                        new features without prior notice. The User shall
                        continue to be bound by these Terms, and it is expressly
                        agreed to by the Parties that the User shall not have
                        the right to terminate these Terms till the expiry of
                        the same.
                      </p>

                      <br />
                      <h5>8. COMMUNICATION</h5>
                      <p style={{ textAlign: "justify" }}>
                        using this Website, and providing his/her contact
                        information to the Company through the Website, the User
                        hereby agrees and consents to receiving calls,
                        autodialed and/or pre-recorded message calls, e-mails,
                        and SMSs from the Company and/or any of its affiliates
                        or partners at any time, subject to the Policy. In the
                        event that the User wishes to stop receiving any such
                        marketing, special offers or promotional calls/email
                        messages/text messages, the User may unsubscribe via
                        email at[.]. The User agrees and acknowledges that it
                        may take up to fifteen (15) business days for the
                        Company to give effect to such a request by the User.
                        <br />
                        <br />
                        The User expressly agrees that notwithstanding anything
                        contained hereinabove, it may be contacted by the
                        Company or any of its affiliates/partners relating to
                        any service availed of by the User on the Website or
                        anything pursuant thereto and the User agrees to
                        indemnify the Company from any and all harassment
                        claims. It is expressly agreed to by the Parties that
                        any information shared by the User with the Company
                        shall be governed by the Policy.
                      </p>

                      <br />
                      <h5>9. CHARGES</h5>
                      <p style={{ textAlign: "justify" }}>
                        The use of the Website is free but the products
                        available on the Website shall be charged in accordance
                        with prices that are mentioned in the individual
                        descriptions of products. The User shall be responsible
                        for payment of all applicable taxes, surcharges and
                        delivery fees as may arise for successful purchase and
                        delivery of the products and the Website reserves the
                        right to alter the prices and non-statutory charges at
                        any time. The mode of payment on the Website can be
                        through third-party gateways and the Website shall not
                        be liable for any discrepancies or failures of the
                        respective gateways that are governed by the gateways’
                        terms of service and privacy policies.
                      </p>

                      <br />
                      <h5>10. USER OBLIGATIONS</h5>
                      <p style={{ textAlign: "justify" }}>
                        The User agrees and acknowledges that he/she is a
                        restricted User of this Website, and that he/she:
                        <br />
                        <br />
                        a) Agrees to provide genuine credentials during the
                        process of registration/purchase in the Website. You
                        shall not use a fictitious identity to register/purchase
                        products.We are not liable if the User has provided
                        incorrect information. Agrees to ensure the contact
                        details provided during account Registration/purchase
                        are valid at all times and shall keep your information
                        accurate and up-to-date. The User can update their
                        details anytime.
                        <br />
                        <br />
                        b) Agrees that you are solely responsible for
                        maintaining the confidentiality of your aaccount
                        password. You agree to notify us immediately of any
                        unauthorized use of your account. We reserve the right
                        to close your account at any time for any or no reason.
                        <br />
                        <br />
                        c) Understands and agrees that, to the fullest extent
                        permissible by law, the Website/Company and their
                        successors and assigns, or any of their affiliates or
                        their respective officers, directors, employees, agents,
                        licensors, representatives, operational service
                        providers, advertisers or suppliers shall not be liable
                        for any loss or damage, of any kind, direct or indirect,
                        in connection with or arising from use of the Website or
                        from this terms of use, including, but not limited to,
                        compensatory, consequential, incidental, indirect,
                        special or punitive damages.
                        <br />
                        <br />
                        d) Is bound not to cut, copy, modify, recreate, reverse
                        engineer, distribute, disseminate, post, publish or
                        create derivative works from, transfer, or sell any
                        information or obtained from the Website. Any such
                        use/limited use of the Website will only be allowed with
                        the prior express written permission of the Company. For
                        the removal of doubt, it is clarified that unlimited or
                        wholesale reproduction, copying of the content for
                        commercial or non-commercial purposes and unwarranted
                        modification of data and information contained on the
                        Websites expressly prohibited.
                        <br />
                        <br />
                        e) Agrees not to access (or attempt to access) the
                        Website and/or the materials or services by any means
                        other than through the interface provided by the
                        Website. The use of deep-link, robot, spider or other
                        automatic device, program, algorithm or methodology, or
                        any similar or equivalent manual process, to access,
                        acquire, copy or monitor any portion of the Website or
                        its content, or in any way reproduce or circumvent the
                        navigational structure or presentation of the Website,
                        materials or any content, or to obtain or attempt to
                        obtain any materials, documents or information through
                        any means not specifically made available through the
                        Website will lead to suspension or termination of the
                        User’s access to the Website.
                        <br />
                        <br />
                        f) The User expressly agrees and acknowledges that the
                        Websites created by the Company.
                        <br />
                        <br />
                        The User further undertakes not to:
                        <br />
                        <br />
                        i) Abuse, harass, threaten, defame, disillusion, erode,
                        abrogate, demean or otherwise violate the legal rights
                        of any other person or entity;
                        <br />
                        <br />
                        ii) Engage in any activity that interferes with or
                        disrupts access to the Website or the services provided
                        therein (or the servers and networks which are connected
                        to the Website);
                        <br />
                        <br />
                        iii) Impersonate any person or entity, or falsely state
                        or otherwise misrepresent his/her affiliation with a
                        person or entity;
                        <br />
                        <br />
                        iv) Use any products for any purpose other than for
                        business consumption;
                        <br />
                        <br />
                        v) Publish, post, disseminate, any information which is
                        grossly harmful, harassing, blasphemous, defamatory,
                        obscene, pornographic, pedophilic, libelous, invasive of
                        another's privacy, hateful, or racially, ethnically
                        objectionable, disparaging, relating or encouraging
                        money laundering or gambling, or otherwise unlawful in
                        any manner whatever under any law, rule or regulation
                        currently in force; or unlawfully threatening or
                        unlawfully harassing including but not limited to
                        "indecent representation of women" within the meaning of
                        the Indecent Representation of Women (Prohibition) Act,
                        1986;
                        <br />
                        <br />
                        vi) Post or share any image/file/data with the Company
                        that infringes the copyright, patent or trademark of
                        another person or legal entity;
                        <br />
                        <br />
                        vii) Upload or distribute files that contain viruses,
                        corrupted files, or any other similar software or
                        programs that may damage the operation of the Website;
                        <br />
                        <br />
                        viii) Probe, scan or test the vulnerability of the
                        Website or any network connected to the Website, nor
                        breach the security or authentication measures on the
                        Website or any network connected to the Website. The
                        User may not reverse look-up, trace or seek to trace any
                        information relating to any other User of, or visitor
                        to, the Website, including any User account maintained
                        on the Website not operated/managed by the User, or
                        exploit the Website or information made available or
                        offered by or through the Website, in any manner;
                        <br />
                        <br />
                        ix) Disrupt or interfere with the security of, or
                        otherwise cause harm to, the Website, systems resources,
                        accounts, passwords, servers or networks connected to or
                        accessible through the Website or any affiliated or
                        linked platforms;
                        <br />
                        <br />
                        x) Collect or store data about other Users of the
                        Website.
                        <br />
                        <br />
                        xi) Use the Website or any material or content therein
                        for any purpose that is unlawful or prohibited by these
                        Terms, or to solicit the performance of any illegal
                        activity or other activity which infringes the rights of
                        this Website or any other third party (ies);
                        <br />
                        <br />
                        xii) Violate any code of conduct or guideline which may
                        be applicable for or to any particular service offered
                        on the Website;
                        <br />
                        <br />
                        xiii) Violate any applicable laws, rules or regulations
                        currently in force within or outside India;
                        <br />
                        <br />
                        xiv) Violate any portion of these Terms or the Policy,
                        including but not limited to any applicable additional
                        terms of the Website contained herein or elsewhere,
                        whether made by amendment, modification, or otherwise;
                        <br />
                        <br />
                        xv) Threaten the unity, integrity, defense, security or
                        sovereignty of India, friendly relations with foreign
                        states, or public Order, or cause incitement to the
                        commission of any cognizable offence, or prevent the
                        investigation of any offence, or insult any other
                        nation.
                        <br />
                        <br />
                        xvi) Publish, post, or disseminate information that is
                        false, inaccurate or misleading;
                        <br />
                        <br />
                        xvii) Directly or indirectly offer, attempt to offer,
                        trade, or attempt to trade, any item the dealing of
                        which is prohibited or restricted in any manner under
                        the provisions of any applicable law, rule, regulation
                        or guideline for the time being in force.
                        <br />
                        <br />
                        xviii) Commit any act that causes the Company to lose
                        (in whole or in part) the services of its Internet
                        Establishment ("ISP") or in any manner disrupts the
                        services of any other supplier/service provider of the
                        Company;
                        <br />
                        <br />
                        xix) Engage in advertising to, or solicitation of, other
                        Users of the Website to buy or sell any products or
                        services not currently displayed on the Website. The
                        User may not transmit any chain letters or unsolicited
                        commercial or junk email/messages to other Users via the
                        Website. It shall be a violation of these Terms to use
                        any information obtained from the Website to harass,
                        abuse, or harm another person, or to contact, advertise
                        to, solicit, or sell to another User of the Website
                        without the express prior written consent of the
                        Company.
                        <br />
                        <br />
                        The User hereby expressly authorizes the Company/Website
                        to disclose any and all information relating to the User
                        in the possession of the Company/Website to law
                        enforcement or other government officials, as the
                        Company may in its sole discretion, believe necessary or
                        appropriate in connection with the investigation and/or
                        resolution of possible crimes, especially those involve
                        personal injury and theft/infringement of intellectual
                        property. The User further understands that the
                        Company/Website might be directed to disclose any
                        information (including the identity of persons providing
                        information or materials on the Website) as necessary to
                        satisfy any judicial Order, law, regulation or valid
                        governmental request.
                      </p>

                      <br />
                      <h5>11. SUSPENSION OF USER ACCESS AND ACTIVITY</h5>
                      <p style={{ textAlign: "justify" }}>
                        Notwithstanding other legal remedies that may be
                        available to it, the Company may in its sole discretion
                        limit the User’s access and/or activity by immediately
                        removing the User’s access credentials either
                        temporarily or indefinitely, or suspend/terminate the
                        User’s association with the Website, and/or refuse to
                        usage of the Website to the User, without being required
                        to provide the User with notice or cause:
                        <br />
                        <br />
                        a) If the User is in breach any of these Terms or the
                        Policy;
                        <br />
                        <br />
                        b) If the User has provided wrong, inaccurate,
                        incomplete or incorrect information;
                        <br />
                        <br />
                        c) If the User’s actions may cause any harm, damage or
                        loss to the other Users or to the Website/Company, at
                        the sole discretion of the Company.
                      </p>

                      <br />
                      <h5>12. INDEMNITY</h5>
                      <p style={{ textAlign: "justify" }}>
                        a) You agree to indemnify, defend and hold harmless the
                        Company/Website, its independent service providers,
                        third party Suppliers, and consultants, and their
                        respective directors, officers, employees and agents
                        (collectively, "Parties"), from and against any and all
                        losses, liabilities, claims, damages, demands, costs and
                        expenses (including legal fees and disbursements in
                        connection therewith and interest chargeable thereon)
                        asserted against or incurred by us that arise out of,
                        result from, or may be payable by virtue of, any breach
                        or non-performance of any representation, warranty,
                        covenant or agreement made or obligation to be performed
                        by you pursuant to these terms of use. further, you
                        agree to hold us harmless against any claims made by any
                        third party due to, or arising out of, or in connection
                        with:
                        <br />
                        <br />
                        i) Your use of the Website;
                        <br />
                        <br />
                        ii) Your violation of these Terms and Conditions;
                        <br />
                        <br />
                        iii) Your violation of any rights of another;
                        <br />
                        <br />
                        iv) Your conduct in connection with the Website;
                        <br />
                        <br />
                        v) Your internal disputes amongst other Users; or
                        <br />
                        <br />
                        b) You agree to fully cooperate in indemnifying Us at
                        Your expense. You also agree not to reach a settlement
                        with any party without Our consent.
                        <br />
                        <br />
                        In no event shall the Company/Website be liable to
                        compensate the User or any third party for any special,
                        incidental, indirect, consequential or punitive damages
                        whatsoever, including those resulting from loss of use,
                        data or profits, whether or not foreseeable, and whether
                        or not the Company/ Website had been advised of the
                        possibility of such damages, or based on any theory of
                        liability, including breach of contract or warranty,
                        negligence or other tortuous action, or any other claim
                        arising out of or in connection with the User’s use of
                        or access to the Website and/or the services or
                        materials contained therein.
                      </p>

                      <br />
                      <h5>13. LIMITATION OF LIABILITY</h5>
                      <p style={{ textAlign: "justify" }}>
                        a) The Founder/ Associated people of the Website are not
                        responsible for any consequences arising out of the
                        following events:
                        <br />
                        <br />
                        i) If the Website is inoperative/non-responsive due to
                        any connectivity errors associated with the internet
                        connection such as but not limited to slow connectivity,
                        no connectivity, server failure.
                        <br />
                        <br />
                        ii) if the User has fed incorrect figures or data or for
                        any deletion of data
                        <br />
                        <br />
                        iii) if there is undue delay or inability to communicate
                        through email
                        <br />
                        <br />
                        iv) if there is a failure in the functioning of any
                        other service provided by the Website.
                        <br />
                        <br />
                        b) The Website accepts no liability for any errors or
                        omissions, whether on behalf of itself or third parties,
                        or for any damage caused to the User, the User’s
                        belongings, or any third party, resulting from the use
                        or misuse of the Website. The service is provided
                        without any guarantees, conditions or warranties as to
                        its accuracy, suitability, completeness or reliability.
                        The Websitewill not be liable to you for the
                        unavailability or failure of the Website.
                        <br />
                        <br />
                        c) Users may be held legally responsible for damages
                        suffered by other Users, the Website or any third party
                        as a result of legally actionable or defamatory
                        comments, remarks, or other information or content
                        posted to the Website.
                        <br />
                        <br />
                        d) Users are to comply with all laws applicable to them
                        or to their activities, and with all Policies, which are
                        hereby incorporated into this Agreement by reference.
                        <br />
                        <br />
                        e) The Websiteexpressly excludes any liability for any
                        loss or damage that was not reasonably foreseeable by
                        the Websiteand which is incurred by you in connection
                        with the Website, including loss of profits; and any
                        loss or damage incurred by you as a result of your
                        breach of these terms.
                        <br />
                        <br />
                        f) To the fullest extent permitted by law, the
                        Websiteshall not be liable to You or any other party for
                        any loss or damage, regardless of the form of action or
                        basis of any claim. You acknowledge and agree that your
                        sole and exclusive remedy for any dispute with us is to
                        terminate your use of the Website.
                      </p>

                      <br />
                      <h5>14. INTELLECTUAL PROPERTY RIGHTS</h5>
                      <p style={{ textAlign: "justify" }}>
                        Unless expressly agreed to in writing, nothing contained
                        herein shall give the User a right to use any of the
                        Website’s trade names, trademarks, service marks, logos,
                        domain names, information, questions, answers,
                        solutions, reports and other distinctive brand features,
                        save according to the provisions of these Terms. All
                        logos, trademarks, brand names, service marks, domain
                        names, including material, designs, and graphics created
                        by and developed by the Website and other distinctive
                        brand features of the Website are the property of the
                        Company. Furthermore, the Company shall be the exclusive
                        owner of all the designs, graphics and the like, related
                        to the Website.
                        <br />
                        <br />
                        The User may not use any of the intellectual property
                        displayed on the Website in any manner that is likely to
                        cause confusion among existing or prospective Users of
                        the Website or third-parties, or that in any manner
                        disparages or discredits the Company/Website, to be
                        determined in the sole discretion of the Company.
                        <br />
                        <br />
                        The User is further aware that any reproduction or
                        infringement of the intellectual property of the Company
                        by the User will result in legal action being initiated
                        against the User by the Company. It is agreed to by the
                        Parties that the contents of this Section shall survive
                        even after the termination or expiry of the Terms and/or
                        Policy.
                      </p>

                      <br />
                      <h5>15. DISCLAIMER </h5>
                      <p style={{ textAlign: "justify" }}>
                        a) The User agrees and undertakes that they are
                        accessing the Website and transacting at his/her sole
                        risk and are that it is using his/her best and prudent
                        judgment before buying the Product listed on the
                        Website.
                        <br />
                        <br />
                        b) The User agrees that any kind of information,
                        resources, recommendations obtained/availed from
                        Website, whether written or oral, will not create any
                        warranty and the Website disclaims all liabilities
                        resulting from these.
                        <br />
                        <br />
                        c) The Company/Website does not guarantee that the
                        functioning of the Website will be uninterrupted or
                        error-free, or that the Website or its server will be
                        free of viruses or other harmful components, and the
                        User hereby expressly accepts any and all associated
                        risks involved with the User’s use of the Website.
                        <br />
                        <br />
                        d) The Website and Company bear no liability whatsoever
                        for any incorrect information fed by any User to the
                        Website. The Website also bears no responsibility for
                        any minor variation between the images that are shared
                        by the User and the actual appearance of the products,
                        once delivered to the User. Users are encouraged to read
                        the product descriptions and use their own discretion
                        before availing of the Services and purchasing any
                        products from the Website.
                        <br />
                        <br />
                        e) Users are encouraged to use their own discretion and
                        carry out their own independent research before acting
                        on the advice and expert tips that are displayed on the
                        Website. The advice, videos and tips are not intended to
                        be of a diagnostic nature and are for general
                        information purposes only.
                        <br />
                        <br />
                        f) The Website may avail services from third party
                        entities to serve you better and these services will be
                        provided on “as is” basis and the Website disclaims any
                        liabilities resulting from these third party services.
                        The Website will not be responsible for any internet
                        delays and damages caused by such problems and shall not
                        be responsible for failure of any third-party payment
                        gateways.
                        <br />
                        <br />
                        g) It is further agreed to by the Parties that the
                        contents of this Section shall survive even after the
                        termination or expiry of the Terms and/or Policy.
                      </p>

                      <br />
                      <h5>16. FORCE MAJEURE</h5>
                      <p style={{ textAlign: "justify" }}>
                        Neither the Company nor the Website shall be liable for
                        damages for any delay or failure to perform its
                        obligations hereunder if such delay or failure is due to
                        cause beyond its control or without its fault or
                        negligence, due to Force Majeure events including but
                        not limited to acts of war, acts of God, earthquake,
                        riot, fire, festive activities sabotage, labor shortage
                        or dispute, internet interruption, technical failure,
                        breakage of sea cable, hacking, piracy, cheating,
                        illegal or unauthorized.
                      </p>

                      <br />
                      <h5>17. DISPUTE RESOLUTION AND JURISDICTION</h5>
                      <p style={{ textAlign: "justify" }}>
                        It is expressly agreed to by the Parties hereto that the
                        formation, interpretation and performance of these Terms
                        and any disputes arising there from will be resolved
                        through a two-step Alternate Dispute Resolution (“ADR”)
                        mechanism. It is further agreed to by the Parties that
                        the contents of this Section shall survive even after
                        the termination or expiry of the Terms and/or Policy.
                        <br />
                        <br />
                        a) <b>Mediation</b>: In case of any dispute between the
                        parties, the Parties will attempt to resolve the same
                        amicably amongst themselves, to the mutual satisfaction
                        of both Parties. In the event that the Parties are
                        unable to reach such an amicable solution within thirty
                        (30) days of one Party communicating the existence of a
                        dispute to the other Party, the dispute will be resolved
                        by arbitration, as detailed herein below;
                        <br />
                        <br />
                        b) <b>Arbitration</b>: In the event that the Parties are
                        unable to amicably resolve a dispute by mediation, said
                        dispute will be referred to arbitration by a sole
                        arbitrator to be appointed by the Company, and the award
                        passed by such sole arbitrator will be valid and binding
                        on both Parties. The Parties shall bear their own costs
                        for the proceedings, although the sole arbitrator may,
                        in his/her sole discretion, direct either Party to bear
                        the entire cost of the proceedings. The arbitration
                        shall be conducted in English, and the seat of
                        Arbitration shall be the city of Hyderabad.
                        <br />
                        <br />
                        The Parties expressly agree that the Terms, Policy and
                        any other agreements entered into between the Parties
                        are governed by the laws, rules and regulations of
                        India.
                      </p>

                      <br />
                      <h5>18. NOTICES</h5>
                      <p style={{ textAlign: "justify" }}>
                        Any and all communication relating to any dispute or
                        grievance experienced by the User may be communicated to
                        the Company by the User writing an email to
                        care@wallpap.in.
                      </p>

                      <br />
                      <h5>19. MISCELLANEOUS PROVISIONS</h5>
                      <p style={{ textAlign: "justify" }}>
                        a) Entire Agreement: These Terms, read with the Policy
                        form the complete and final contract between the User
                        and the Company with respect to the subject matter
                        hereof and supersedes all other communications,
                        representations and agreements (whether oral, written or
                        otherwise) relating thereto.
                        <br />
                        <br />
                        b) Waiver: The failure of either Party at any time to
                        require performance of any provision of these Terms
                        shall in no manner affect such Party's right at a later
                        time to enforce the same. No waiver by either Party of
                        any breach of these Terms, whether by conduct or
                        otherwise, in any one or more instances, shall be deemed
                        to be or construed as a further or continuing waiver of
                        any such breach, or a waiver of any other breach of
                        these Terms.
                        <br />
                        <br />
                        c) Severability: If any provision/clause of these Terms
                        is held to be invalid, illegal or unenforceable by any
                        court or authority of competent jurisdiction, the
                        validity, legality and enforceability of the remaining
                        provisions/clauses of these Terms shall in no way be
                        affected or impaired thereby, and each such
                        provision/clause of these Terms shall be valid and
                        enforceable to the fullest extent permitted by law. In
                        such case, these Terms shall be reformed to the minimum
                        extent necessary to correct any invalidity, illegality
                        or unenforceability, while preserving to the maximum
                        extent the original rights, intentions and commercial
                        expectations of the Parties hereto, as expressed herein.
                        <br />
                        <br />
                        d) Contact Us: If you have any questions about this
                        Agreement, the practices of the Website, you can e-mail
                        us at care@wallpap.in.
                      </p>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

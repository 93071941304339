import { Api, loadScript } from "./lib/Api";
import React, { useRef, useState, useEffect } from "react";
import { PlaceOrderCCav } from "./user-components/PlaceOrderCCav";
import { PlaceOrderRazor } from "./user-components/PlaceOrderRazor";
import { PlaceOrderCashfree } from "./user-components/PlaceOrderCashfree";
import { Skeleton } from "antd";

export const Checkout = () => {

  const [profile, setProfile] = useState({});
  const [address, setAddress] = useState({})
  const [openChangeAddress, setOpenChangeAddress] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [openAddAddress, setAddOpenAddress] = useState(false)

  const email = localStorage.getItem("email");
  const couponCode = localStorage.getItem("coupon");
  const defaultAddress = profile?.addresses?.find((address) => address._id === profile?.defaultAddress)

  const getProfile = async () => {
    setLoading(true)
    let response = await Api.get_all("users", `email=${email}`);
    console.log("getProfile:resp", response);
    await setProfile(response.data[0]);
    setLoading(false)
  };

  const updateProfile = async () => {
    let response = await Api.update("users", profile);
    console.log("getProfile:resp", response);
  };

  const [payment, setPayment] = useState({
    amount: { total: 0, coupon: couponCode, membershipDiscount: 0, couponDiscount: 0, grandTotal: 0, saved: 0 },
  });

  const calculatePayment = async () => {
    // slip = { 
    //   products : [{code, variant, price, quantity, subTotal}],
    //   amount : {total, coupon, membershipDiscount, couponDiscount, grandTotal, saved}
    // }
    let slip = {
      products: cartProducts,
      amount: { total: 0, coupon: couponCode, membershipDiscount: 0, couponDiscount: 0, grandTotal: 0, saved: 0 }
    }
    let response = await Api.payment(slip);
    console.log("payment response", response);
    setPayment(response.data);
  }

  useEffect(async () => {
    console.log("useEffect");
    Api.verifyToken();

    // effect
    getProfile();
    calculatePayment();

    const _orderid = Date.now().toString();
    setOrderid(_orderid);
    localStorage.setItem("orderid", _orderid);

    return () => {
      // cleanup
    };
  }, []);

  const _cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];

  const [cartProducts, setCartProducts] = useState(_cartProducts);
  const [total, setTotal] = useState(
    _cartProducts.reduce(
      (total, cart) =>
        (
          Number(total) +
          Number(cart.quantity) * Number(cart.customerPrice)
        ).toFixed(2),
      0
    )
  );

  const [orderid, setOrderid] = useState();

  const placeOrder = async (ev) => {
    //store the profile first
    ev.preventDefault();

    Api.verifyToken();

    let response = await Api.update("users", profile);
    console.log("getProfile:resp", response);
    // if (!isAddressOK()){
    //   alert("Please correct the shipping address");
    // }
    // console.log("address1 is ", profile);

    response = await Api.upsert("orders", `email=${email}&orderid=${orderid}`, { payment: payment });
    console.log("upsert orders:resp", response);
    // alert("Initiating transaction");
    document.getElementById('ccavForm').submit();
  }

  const [error, setError] = useState({ name: false, mobile: false, full_address: false, city: false, state: false, post_code: false });
  const isAddressOK = () => {
    let _error = { name: false, mobile: false, full_address: false, city: false, state: false, post_code: false };
    for (let which in _error) {
      _error[which] = (address[which] == null) || (address[which] == "");
      if (_error[which]) {
        setError(_error);
        return false;
      }
    }
    setError(_error);
    return true;
  }

  const saveAddress = async () => {
    try {
      const response = await Api.addUserAddress(profile._id, { ...address, email: profile?.email });
      console.log("addUserAddress:resp", response);
      //data._id
      if (response?.data?._id) {
        const makeDefaultAddressResponse = await Api.updateUser(profile._id, { defaultAddress: response?.data?._id });
        getProfile()
        setAddOpenAddress(false)
        setOpenChangeAddress(false)
      }
    } catch (e) {
      console.error(`Error adding user address. Details: ${e}`);
    }
  }


  //borrowed
  const $ = window.jQuery;

  function NextTab(val) {
    // alert(val);
    //alert(val2);

    if (val == "headingOne") {
      $("#headingOne a").addClass("collapsed");
      $("#headingOne a").attr("aria-expanded", "false");
      $("#checkoutMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#checkoutMethod").attr("aria-expanded", "false");
      $("#h1").css("display", "block");

      $("#headingTwo a").removeClass();
      $("#headingTwo a").attr("aria-expanded", "true");
      $("#billingInformation").removeClass();
      $("#billingInformation").addClass("panel-collapse collapse in");
      $("#billingInformation").attr("aria-expanded", "true");
      $("#h2").css("display", "none");

      $("#headingThree a").addClass("collapsed");
      $("#headingThree a").attr("aria-expanded", "false");
      $("#shippingMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#shippingMethod").attr("aria-expanded", "false");
      $("#h3").css("display", "block");

      $("#headingFour a").addClass("collapsed");
      $("#headingFour a").attr("aria-expanded", "false");
      $("#paymentInformation")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#paymentInformation").attr("aria-expanded", "false");
      $("#h4").css("display", "block");
    } else if (val == "headingTwo") {
      saveAddress()
      $("#headingOne a").addClass("collapsed");
      $("#headingOne a").attr("aria-expanded", "false");
      $("#checkoutMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#checkoutMethod").attr("aria-expanded", "false");
      $("#h1").css("display", "block");

      $("#headingTwo a").addClass("collapsed");
      $("#headingTwo a").attr("aria-expanded", "false");
      $("#billingInformation")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#billingInformation").attr("aria-expanded", "false");
      $("#h2").css("display", "block");

      $("#headingThree a").removeClass();
      $("#headingThree a").attr("aria-expanded", "true");
      $("#shippingMethod").removeClass();
      $("#shippingMethod").addClass("panel-collapse collapse in");
      $("#shippingMethod").attr("aria-expanded", "false");
      $("#h3").css("display", "none");

      $("#headingFour a").addClass("collapsed");
      $("#headingFour a").attr("aria-expanded", "false");
      $("#paymentInformation")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#paymentInformation").attr("aria-expanded", "false");
      $("#h4").css("display", "block");
    } else if (val == "headingThree") {
      $("#headingOne a").addClass("collapsed");
      $("#headingOne a").attr("aria-expanded", "false");
      $("#checkoutMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#checkoutMethod").attr("aria-expanded", "false");
      $("#h1").css("display", "block");

      $("#headingTwo a").addClass("collapsed");
      $("#headingTwo a").attr("aria-expanded", "false");
      $("#billingInformation")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#billingInformation").attr("aria-expanded", "false");
      $("#h2").css("display", "block");

      $("#headingThree a").addClass("collapsed");
      $("#headingThree a").attr("aria-expanded", "false");
      $("#shippingMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#shippingMethod").attr("aria-expanded", "false");
      $("#h3").css("display", "block");

      $("#headingFour a").removeClass();
      $("#headingFour a").attr("aria-expanded", "true");
      $("#paymentInformation")
        .removeClass("panel-collapse collapse")
        .addClass("panel-collapse collapse in");
      $("#paymentInformation").attr("aria-expanded", "true");
      $("#h4").css("display", "none");
    }
  }

  function EditTab(val) {
    // alert(val);
    //alert(val2);

    if (val == "headingOne") {
      $("#headingOne a").removeClass();
      $("#headingOne a").attr("aria-expanded", "true");
      $("#checkoutMethod").removeClass();
      $("#checkoutMethod").addClass("panel-collapse collapse in");
      $("#checkoutMethod").attr("aria-expanded", "true");
      $("#h1").css("display", "none");

      $("#headingTwo a").addClass("collapsed");
      $("#headingTwo a").attr("aria-expanded", "false");
      $("#billingInformation")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#billingInformation").attr("aria-expanded", "false");
      $("#h2").css("display", "block");

      $("#headingThree a").addClass("collapsed");
      $("#headingThree a").attr("aria-expanded", "false");
      $("#shippingMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#shippingMethod").attr("aria-expanded", "false");
      $("#h3").css("display", "block");

      $("#headingFour a").addClass("collapsed");
      $("#headingFour a").attr("aria-expanded", "false");
      $("#paymentInformation")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#paymentInformation").attr("aria-expanded", "false");
      $("#h4").css("display", "block");
    } else if (val == "headingTwo") {
      $("#headingOne a").addClass("collapsed");
      $("#headingOne a").attr("aria-expanded", "false");
      $("#checkoutMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#checkoutMethod").attr("aria-expanded", "false");
      $("#h1").css("display", "block");

      $("#headingTwo a").removeClass();
      $("#headingTwo a").attr("aria-expanded", "true");
      $("#billingInformation").removeClass();
      $("#billingInformation").addClass("panel-collapse collapse in");
      $("#billingInformation").attr("aria-expanded", "true");
      $("#h2").css("display", "none");

      $("#headingThree a").addClass("collapsed");
      $("#headingThree a").attr("aria-expanded", "false");
      $("#shippingMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#shippingMethod").attr("aria-expanded", "false");
      $("#h3").css("display", "block");

      $("#headingFour a").addClass("collapsed");
      $("#headingFour a").attr("aria-expanded", "false");
      $("#paymentInformation")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#paymentInformation").attr("aria-expanded", "false");
      $("#h4").css("display", "block");
    } else if (val == "headingThree") {
      $("#headingOne a").addClass("collapsed");
      $("#headingOne a").attr("aria-expanded", "false");
      $("#checkoutMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#checkoutMethod").attr("aria-expanded", "false");
      $("#h1").css("display", "block");

      $("#headingTwo a").addClass("collapsed");
      $("#headingTwo a").attr("aria-expanded", "false");
      $("#billingInformation")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#billingInformation").attr("aria-expanded", "false");
      $("#h2").css("display", "block");

      $("#headingThree a").removeClass();
      $("#headingThree a").attr("aria-expanded", "true");
      $("#shippingMethod").removeClass();
      $("#shippingMethod").addClass("panel-collapse collapse in");
      $("#shippingMethod").attr("aria-expanded", "true");
      $("#h3").css("display", "none");

      $("#headingFour a").addClass("collapsed");
      $("#headingFour a").attr("aria-expanded", "false");
      $("#paymentInformation")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#paymentInformation").attr("aria-expanded", "false");
      $("#h4").css("display", "block");
    } else if (val == "headingFour") {
      $("#headingOne a").addClass("collapsed");
      $("#headingOne a").attr("aria-expanded", "false");
      $("#checkoutMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#checkoutMethod").attr("aria-expanded", "false");
      $("#h1").css("display", "block");

      $("#headingTwo a").addClass("collapsed");
      $("#headingTwo a").attr("aria-expanded", "false");
      $("#billingInformation")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#billingInformation").attr("aria-expanded", "false");
      $("#h2").css("display", "block");

      $("#headingThree a").addClass("collapsed");
      $("#headingThree a").attr("aria-expanded", "false");
      $("#shippingMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#shippingMethod").attr("aria-expanded", "false");
      $("#h3").css("display", "block");

      $("#headingFour a").removeClass();
      $("#headingFour a").attr("aria-expanded", "true");
      $("#paymentInformation").removeClass();
      $("#paymentInformation").addClass("panel-collapse collapse in");
      $("#paymentInformation").attr("aria-expanded", "true");
      $("#h4").css("display", "none");
    }
  }

  function validate() {
    var name = $("#name").val();
    var mobilenumber = $("#mobilenumber").val();
    var email = $("#email").val();
    var address = $("#address").val();
    var city = $("#city").val();
    var state = $("#state").val();
    //var country = $("#country").val();
    var pincode = $("#pincode").val();

    if (
      name == "" ||
      mobilenumber == "" ||
      email == "" ||
      address == "" ||
      city == "" ||
      state == "" ||
      pincode == ""
    ) {
      $("#headingOne a").addClass("collapsed");
      $("#headingOne a").attr("aria-expanded", "false");
      $("#checkoutMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#checkoutMethod").attr("aria-expanded", "false");
      $("#h1").css("display", "block");

      $("#headingTwo a").removeClass();
      $("#headingTwo a").attr("aria-expanded", "true");
      $("#billingInformation").removeClass();
      $("#billingInformation").addClass("panel-collapse collapse in");
      $("#billingInformation").attr("aria-expanded", "true");

      $("#headingThree a").addClass("collapsed");
      $("#headingThree a").attr("aria-expanded", "false");
      $("#shippingMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#shippingMethod").attr("aria-expanded", "false");

      $("#headingFour a").addClass("collapsed");
      $("#headingFour a").attr("aria-expanded", "false");
      $("#paymentInformation")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#paymentInformation").attr("aria-expanded", "false");

      return false;
    } else if ($('input[name="paymentradio"]:checked').length == 0) {
      $("#headingOne a").addClass("collapsed");
      $("#headingOne a").attr("aria-expanded", "false");
      $("#checkoutMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#checkoutMethod").attr("aria-expanded", "false");

      $("#headingTwo a").addClass("collapsed");
      $("#headingTwo a").attr("aria-expanded", "false");
      $("#billingInformation")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#billingInformation").attr("aria-expanded", "false");

      $("#headingThree a").addClass("collapsed");
      $("#headingThree a").attr("aria-expanded", "false");
      $("#shippingMethod")
        .removeClass("panel-collapse collapse in")
        .addClass("panel-collapse collapse");
      $("#shippingMethod").attr("aria-expanded", "false");

      $("#headingFour a").removeClass();
      $("#headingFour a").attr("aria-expanded", "true");
      $("#paymentInformation")
        .removeClass("panel-collapse collapse")
        .addClass("panel-collapse collapse in");
      $("#paymentInformation").attr("aria-expanded", "true");

      return false;
    }

    return true;
  }

  $(document).ready(function () {
    $("#chkreward").click(function () {
      if ($("#chkreward").is(":checked")) {
        //alert($("#spnrewardpoints").text());
        //alert($("#hdngrandtotal").val());
        var rewardamt = $("#spnrewardpoints").text();
        var grandtotal = $("#hdngrandtotal").val();

        var total = parseFloat(grandtotal) - parseFloat(rewardamt);
        $("#spangrandtotal").text("₹" + total.toFixed(2));
        //alert($("#totsaved").val());
        var totsaved = parseFloat($("#totsaved").val()) + parseFloat(rewardamt);
        $("#saveamount").text(" ₹" + totsaved.toFixed(2) + " Amount");
      } else {
        var grandtotal = $("#hdngrandtotal").val();

        $("#spangrandtotal").text("₹" + grandtotal);
        var totsaved = parseFloat($("#totsaved").val());
        $("#saveamount").text(" ₹" + totsaved.toFixed(2) + " Amount");
      }
    });
  });

  const makeDefaultAddress = async (id) => {
    try {
      const response = await Api.updateUser(profile._id, { defaultAddress: id });
      getProfile()
      setOpenChangeAddress(false)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="category3">
                    <span>Checkout</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {" "}
      <div className="main-container">
        <div className="product-cart">
          <div className="container">
            <div className="row">
              <div className="checkout-content">
                <div className="col-md-9 check-out-blok">
                  <div
                    className="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div className="panel checkout-accordion">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingTwo"
                      >
                        <h4 className="panel-title">
                          <span>
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#billingInformation1"
                              aria-expanded="false"
                              aria-controls="billingInformation"
                            >
                              <span style={{ width: "50%", float: "left" }}>
                                1 &nbsp; &nbsp; Delivery Address
                              </span>
                              <span
                                style={{
                                  width: "40%",
                                  float: "right",
                                  textAlign: "right",
                                }}
                                id="h2"
                                onClick={() => {
                                  EditTab("headingTwo");
                                }}
                              >
                                Edit
                              </span>
                              <div className="clearfix"></div>
                            </a>
                          </span>
                        </h4>
                      </div>
                      <div
                        id="billingInformation"
                        className="panel-collapse collapse in"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div className="content-info">
                          <div className="col-md-12">
                            {isLoading ? (
                              <div className="check-out-address-card">
                                <Skeleton paragraph={{ rows: 2 }} title={false} />
                              </div>
                            ) : (
                              (!defaultAddress || openAddAddress) ? (
                                <div className="form-fields">
                                  <div className="row">
                                    <p className="col-md-4 form-row form-row-wide">
                                      <label htmlFor="reg_email">
                                        Name <span className="required">*</span>
                                      </label>
                                      <input
                                        className="input-text"
                                        data-val="true"
                                        data-val-required="The Name field is required."
                                        id="name"
                                        name="Name"
                                        type="text"
                                        value={address.name}
                                        onChange={(e) =>
                                          setAddress({
                                            ...address,
                                            name: e.target.value,
                                          })
                                        }
                                        style={error.name ? { border: "1px solid red" } : {}}
                                      />
                                      <span style={{ color: "red" }}>
                                        <span
                                          className="field-validation-valid"
                                          data-valmsg-for="Name"
                                          data-valmsg-replace="true"
                                        ></span>
                                      </span>
                                    </p>

                                    <p className="col-md-4 form-row form-row-wide">
                                      <label htmlFor="reg_email">
                                        Mobile Number{" "}
                                        <span className="required">*</span>
                                      </label>
                                      <input
                                        className="input-text"
                                        data-val="true"
                                        data-val-required="The Mobile Number field is required."
                                        id="mobilenumber"
                                        name="MobileNumber"
                                        onkeypress="return isNumberKey(event);"
                                        type="text"
                                        value={address.mobile}
                                        onChange={(e) =>
                                          setAddress({
                                            ...address,
                                            mobile: e.target.value,
                                          })
                                        }
                                        style={error.mobile ? { border: "1px solid red" } : {}}
                                      />
                                      <span style={{ color: "red" }}>
                                        <span
                                          className="field-validation-valid"
                                          data-valmsg-for="MobileNumber"
                                          data-valmsg-replace="true"
                                        ></span>
                                      </span>
                                    </p>
                                    <p className="col-md-4 form-row form-row-wide">
                                      <label htmlFor="reg_email">
                                        Email address{" "}
                                        <span className="required">*</span>
                                      </label>
                                      <input
                                        className="input-text"
                                        data-val="true"
                                        data-val-regex="E-mail is not valid"
                                        data-val-regex-pattern="^[a-zA-Z0-9_\.-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$"
                                        data-val-required="The Email field is required."
                                        id="email"
                                        name="Email"
                                        type="text"
                                        readOnly={true}
                                        value={profile.email}
                                      />
                                      <span style={{ color: "red" }}>
                                        <span
                                          className="field-validation-valid"
                                          data-valmsg-for="Email"
                                          data-valmsg-replace="true"
                                        ></span>
                                      </span>
                                    </p>

                                    <p className="col-md-4 form-row form-row-wide">
                                      <label htmlFor="reg_email">
                                        Address{" "}
                                        <span className="required">*</span>
                                      </label>
                                      <input
                                        className="input-text"
                                        data-val="true"
                                        data-val-required="The Address field is required."
                                        id="address"
                                        name="Address"
                                        type="text"
                                        value={address.full_address}
                                        onChange={(e) =>
                                          setAddress({
                                            ...address,
                                            full_address: e.target.value,
                                          })
                                        }
                                        style={error.full_address ? { border: "1px solid red" } : {}}
                                      />
                                      <span style={{ color: "red" }}>
                                        <span
                                          className="field-validation-valid"
                                          data-valmsg-for="Address"
                                          data-valmsg-replace="true"
                                        ></span>
                                      </span>
                                    </p>

                                    <p
                                      className="col-md-4 form-row form-row-wide"
                                      style={{ marginTop: 8 }}
                                    >
                                      <label htmlFor="reg_email">Landmark</label>
                                      <input
                                        className="input-text"
                                        id="LandMark"
                                        name="LandMark"
                                        type="text"
                                        value={address.landmark}
                                        onChange={(e) =>
                                          setAddress({
                                            ...address,
                                            landmark: e.target.value,
                                          })
                                        }
                                      />
                                    </p>
                                    <p className="col-md-4 form-row form-row-wide">
                                      <label htmlFor="reg_email">
                                        City <span className="required">*</span>
                                      </label>
                                      <input
                                        className="input-text"
                                        data-val="true"
                                        data-val-required="The City field is required."
                                        id="city"
                                        name="City"
                                        type="text"
                                        value={address.city}
                                        onChange={(e) =>
                                          setAddress({
                                            ...address,
                                            city: e.target.value,
                                          })
                                        }
                                        style={error.city ? { border: "1px solid red" } : {}}
                                      />
                                      <span style={{ color: "red" }}>
                                        <span
                                          className="field-validation-valid"
                                          data-valmsg-for="City"
                                          data-valmsg-replace="true"
                                        ></span>
                                      </span>
                                    </p>
                                    <p className="col-md-4 form-row form-row-wide">
                                      <label htmlFor="reg_email">
                                        State <span className="required">*</span>
                                      </label>
                                      <input
                                        className="input-text"
                                        data-val="true"
                                        data-val-required="The State field is required."
                                        id="state"
                                        name="State"
                                        type="text"
                                        value={address.state}
                                        onChange={(e) =>
                                          setAddress({
                                            ...address,
                                            state: e.target.value,
                                          })
                                        }
                                        style={error.state ? { border: "1px solid red" } : {}}
                                      />
                                      <span style={{ color: "red" }}>
                                        <span
                                          className="field-validation-valid"
                                          data-valmsg-for="State"
                                          data-valmsg-replace="true"
                                        ></span>
                                      </span>
                                    </p>
                                    <p className="col-md-4 form-row form-row-wide">
                                      <label htmlFor="reg_email">
                                        Postal Code{" "}
                                        <span className="required">*</span>
                                      </label>
                                      <input
                                        className="input-text"
                                        data-val="true"
                                        data-val-required="The PinCode field is required."
                                        id="pincode"
                                        name="PinCode"
                                        onkeypress="return isNumberKey(event)"
                                        type="number"
                                        value={address.post_code}
                                        onChange={(e) =>
                                          setAddress({
                                            ...address,
                                            post_code: e.target.value,
                                          })
                                        }
                                        style={error.post_code ? { border: "1px solid red" } : {}}
                                      />
                                      <span style={{ color: "red" }}>
                                        <span
                                          className="field-validation-valid"
                                          data-valmsg-for="PinCode"
                                          data-valmsg-replace="true"
                                        ></span>
                                      </span>
                                    </p>
                                    <p className="col-md-4 form-row form-row-wide" style={{ marginTop: 8 }}>
                                      <label htmlFor="reg_email">
                                        Type{" "}
                                      </label>
                                      <input
                                        className="input-text"
                                        data-val="false"
                                        data-val-required="The Country field is required."
                                        id="type"
                                        name="Type"
                                        type="text"
                                        value={address.type}
                                        placeholder="Home, Office"
                                        onChange={(e) =>
                                          setAddress({
                                            ...address,
                                            type: e.target.value,
                                          })
                                        }
                                      />
                                      <span style={{ color: "red" }}>
                                        <span
                                          className="field-validation-valid"
                                          data-valmsg-for="Country"
                                          data-valmsg-replace="true"
                                        ></span>
                                      </span>
                                    </p>
                                    <p
                                      className="col-md-12 form-row form-row-wide p-0"
                                      style={{ margin: '10px 0 20px 0', display: 'flex', justifyContent: 'end', alignItems: 'center' }}
                                    >
                                      <div style={{ cursor: "pointer", paddingRight: '15px', fontWeight: 700 }} onClick={() => setAddOpenAddress(false)}>Back</div>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        className="crbtn"
                                        onClick={() => {
                                          if (!isAddressOK()) return;
                                          saveAddress();
                                        }}
                                      >
                                        Save & Next
                                      </a>
                                    </p>
                                    <div className="clearfix"></div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="check-out-address-card">
                                    <p className="fw-bold" style={{ textTransform: 'capitalize' }}><bold>{defaultAddress?.name}</bold>, {defaultAddress?.full_address}, {defaultAddress?.landmark}, {defaultAddress?.city}, {defaultAddress?.state}, {defaultAddress?.post_code}</p>
                                    <p className="fw-bold" style={{ textTransform: 'capitalize' }}>{defaultAddress?.mobile}</p>
                                  </div>
                                  {openChangeAddress && <hr />}
                                  {openChangeAddress && profile.addresses && profile.addresses.map((address) => {
                                    if (defaultAddress._id === address._id) return
                                    return (
                                      <div className="check-out-address-card">
                                        <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                          <div className="col-xs-9 col-md-10">
                                            <p className="fw-bold" style={{ textTransform: 'capitalize' }}><bold>{address?.name}</bold>, {address?.full_address}, {address?.landmark}, {defaultAddress?.city}, {address?.state}, {address?.post_code}</p>
                                            <p className="fw-bold" style={{ textTransform: 'capitalize' }}>{address?.mobile}</p>
                                          </div>
                                          <div className="col-xs-2 col-md-2">
                                            <div className="text-center" style={{ cursor: 'pointer' }} onClick={() => makeDefaultAddress(address._id)}>Select</div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                                  {openChangeAddress && (
                                    <div className="check-out-address-card">
                                      <div
                                        className="fw-bold"
                                        onClick={() => setAddOpenAddress(true)}
                                        style={{ marginBottom: 0, fontWeight: 700, cursor: 'pointer' }}
                                      >
                                        <i class="fa fa-plus" aria-hidden="true"></i> Add a new address</div>
                                    </div>
                                  )}
                                  <p
                                    className="col-md-12 form-row form-row-wide p-0"
                                    style={{ margin: '10px 0 20px 0', display: 'flex', justifyContent: 'end', alignItems: 'center', padding: 0 }}
                                  >
                                    {!openChangeAddress && <div style={{ cursor: "pointer", paddingRight: '15px', fontWeight: 700 }} onClick={() => setOpenChangeAddress(true)}>Change</div>}
                                    <a
                                      style={{ cursor: "pointer" }}
                                      className="crbtn"
                                      onClick={() => {
                                        NextTab('headingTwo');
                                      }}
                                    >
                                      Next
                                    </a>
                                  </p>
                                </>
                              ))}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel checkout-accordion">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingThree"
                      >
                        <h4 className="panel-title">
                          <span>
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#shippingMethod1"
                              aria-expanded="false"
                              aria-controls="shippingMethod"
                            >
                              <span style={{ width: "50%", float: "left" }}>
                                2 &nbsp; &nbsp; Order Summary
                              </span>
                              <span
                                style={{
                                  width: "40%",
                                  float: "right",
                                  textAlign: "right",
                                }}
                                id="h3"
                                onClick={() => {
                                  EditTab("headingThree");
                                }}
                              >
                                Edit
                              </span>
                              <div className="clearfix"></div>
                            </a>
                          </span>
                        </h4>
                      </div>
                      <div
                        id="shippingMethod"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingThree"
                      >
                        <div className="content-info">
                          <div className="col-md-12">
                            <div className="table-responsive">
                              <table className="cart-table" id="cart">
                                <thead>
                                  <tr>
                                    <th width="100">Product</th>
                                    <th>Product name</th>
                                    <th>Unit Price</th>
                                    <th>Qty</th>
                                    <th>Subtotal</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {cartProducts.map((cart, idx) => (
                                    <tr key={idx} data-cid="342">
                                      <td>
                                        <a href="#">
                                          <img
                                            src={cart.image_url}
                                            className="img-responsive"
                                            alt=""
                                          />
                                        </a>
                                      </td>
                                      <td>
                                        <h6>
                                          {cart.productName} {cart.designCode}
                                          {cart.designVariant}
                                        </h6>
                                      </td>
                                      <td>
                                        <div className="cart-price">
                                          ₹{cart.customerPrice}
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={cart.quantity}
                                          placeholder="1"
                                          readOnly={true}
                                        // onChange={(e)=>setCartProducts(...cartProducts)}
                                        />
                                      </td>
                                      <td>
                                        <div
                                          className="cart-subtotal"
                                          id="clientsubtotal"
                                        >
                                          ₹
                                          {(
                                            cart.quantity * cart.customerPrice
                                          ).toFixed(2)}
                                        </div>
                                      </td>

                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                              <p
                                className="col-md-12 form-row form-row-wide text-right"
                                style={{ margin: '10px 0 20px 0' }}
                              >
                                <a
                                  style={{ cursor: "pointer", textAlign: "right", }}
                                  className="crbtn"
                                  onClick={() => { NextTab("headingThree"); }}
                                >
                                  Next
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel checkout-accordion">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingFour"
                      >
                        <h4 className="panel-title">
                          <span>
                            <a
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#paymentInformation1"
                              aria-expanded="false"
                              aria-controls="paymentInformation"
                            >
                              <span style={{ width: "50%", float: "left" }}>
                                3 &nbsp; &nbsp; Payment Options
                              </span>

                              <div className="clearfix"></div>
                            </a>
                          </span>
                        </h4>
                      </div>

                      <input
                        id="deliverytype"
                        name="DeliveryType"
                        type="hidden"
                        value=""
                      />

                      <div
                        id="paymentInformation"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingFour"
                      >
                        <div className="content-info">
                          <div
                            className="col-md-12"
                            style={{ display: "none" }}
                          ></div>

                          {/* <PlaceOrderCCav 
                              profile={profile} 
                              placeOrder={placeOrder} 
                              payment={payment} 
                              cartProducts={cartProducts}
                              orderid ={orderid}
                              /> */}

                          {/* <PlaceOrderRazor 
                              profile={profile} 
                              placeOrder={placeOrder} 
                              payment={payment} 
                              cartProducts={cartProducts}
                              orderid ={orderid}
                              /> */}

                          <PlaceOrderCashfree
                            profile={profile}
                            placeOrder={placeOrder}
                            payment={payment}
                            cartProducts={cartProducts}
                            orderid={orderid}
                          />
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 category-checkout">
                <h5>Amount Summary</h5>
                <ul>
                  <li>
                    <span>Price ({cartProducts.length} {cartProducts == 1 ? "item" : "items"})</span>{" "}
                    <span style={{ float: "right" }}>₹{payment.amount.total}</span>
                  </li>

                  <li>
                    <b>
                      <span>Total </span>
                      <span style={{ float: "right" }}>₹{payment.amount.total}</span>
                    </b>
                  </li>
                  {payment.amount.membershipDiscount > 0 ?
                    <li>
                      <span style={{ color: "green" }}>
                        Membership Dis({payment.amount.membershipDiscount}%):{" "}
                      </span>
                      <span style={{ float: "right" }} id="mempercent">
                        (-) ₹{payment.amount.membershipDiscountAmount}
                      </span>
                    </li> : ""}
                  {payment.amount.couponDiscount > 0 ?
                    <li className="cartSubT" style={{ color: "green" }}>
                      Coupon Discount({payment.amount.couponDiscount}%):
                      <span style={{ float: "right" }} id="mempercent">(-) ₹{payment.amount.couponDiscountAmount}</span>
                    </li>
                    : ""}
                  <li>
                    <b>
                      <span>Grand Total </span>
                      <span style={{ float: "right" }} id="spangrandtotal">
                        ₹{payment.amount.grandTotal}
                      </span>{" "}
                    </b>
                  </li>
                </ul>
                <span
                  className="cartSubT"
                  id="licoupon"
                  style={{ color: "green" }}
                >
                  You have saved<span id="saveamount"> ₹{payment.amount.saved}</span>{" "}
                </span>

                <div className="clearfix"></div>
                <br />
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="clearfix"></div>
    </div>
  );
};
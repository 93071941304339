import React, { useRef, useState, useEffect } from "react";
import { Api } from "../lib/Api";
import { Config } from "../Config";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export const ProductsPg = () => {
  const [categories, setCategories] = useState([]);
  const [themes, setThemes] = useState([]);
  const [colors, setColors] = useState([]);
  const [counts, setCounts] = useState({ colors: [], themes: [] });

  // const [selectedCategories, setSelectedCategories] = useState([]);
  // const [selectedThemes, setSelectedThemes] = useState([]);
  // const [selectedColors, setSelectedColors] = useState([]);

  const _selected = JSON.parse(localStorage.getItem("selectedMainMenu")) || [];
  // const selected = _selected['colors'] || _selected['themes'];
  // const menuCat = _selected['colors'] ? "colors":"themes";
  // const menuCat = (Object.keys(_selected))[0];
  // const selected = _selected[menuCat];
  // console.log('selected',selected, menuCat);

  const query = window.location.href.split("?")[1] || null;
  let menuCat = null, selected = null;
  if (query) {
    [menuCat, selected] = query.split("=");
  }

  console.log("menuCat Selected", menuCat, selected);


  // const selected = "BEIGE";

  const getSelectLists = async () => {
    let response = await Api.get_all("categories");
    console.log("getSelectLists:resp", response);
    await setCategories(response.data.map((el) => el.name));
    // await setSelectedCategories(categories.map((el) => false));

    response = await Api.get_all("themes");
    console.log("getSelectLists:themes", response);
    await setThemes(response.data.map((el) => el.name));
    // await setSelectedThemes(themes.map((el) => false));

    response = await Api.get_all("colors");
    console.log("getSelectLists:colors", response);
    await setColors(response.data.map((el) => el.name));
    // await setSelectedColors(colors.map((el) => false));

    response = await Api.get_count_colorsthemes("products");
    console.log("getSelectLists:counts", response);
    let _counts = { colors: [], themes: [] };
    response.data.colors.forEach((ele, idx) => {
      _counts.colors[ele.name] = ele.count;
    })
    response.data.themes.forEach((ele, idx) => {
      _counts.themes[ele.name] = ele.count;
    })
    await setCounts(_counts);
    console.log("_counts", _counts);
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  const getSelectedProducts = async () => {
    let response = await Api.get_all("products", selected ? `${menuCat}=${selected}` : null);
    console.log("getSelectedProducts:resp", response);
    response.data.map((el) => el.image_url = `${Config.IMAGES_BASE}/products/${el.image}`);
    await setSelectedProducts(response.data);
    console.log('selectedProducts:', selectedProducts);
  };

  function getProductURL(product) {
    return `/details/${product.productId}`;
    // return `/details/${product.productName.replaceAll(" ","-")}-${product.designCode}${product.designVariant}?productid=${product._id}`;
    //return details?productid=${product._id}
  }

  useEffect(async () => {
    console.log("useEffect");
    // effect
    // populateTable();
    await getSelectLists();

    await getSelectedProducts();

    return () => {
      // cleanup
    };
  }, [selected]);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="category3">
                    <Link to="/products">{selected ? selected.replace("%20", " ").replace("%20", " ") : "CATALOGUE"}</Link>
                  </li>

                  <input
                    id="hdncateid"
                    name="CategoryId"
                    type="hidden"
                    value="104"
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shop-with-sidebar">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-12 col-xs-12 text-left">
              <div className="topbar-left">
                <aside className="sidebar-content">
                  <div className="sidebar-title">
                    <h6>COLOUR</h6>
                  </div>
                  <ul>
                    {colors.map((color, idx) => (
                      <li key={idx}>
                        <Link
                          to={`/products?colors=${color}`}
                          onClick={() => {
                            console.log("selected side menu color", color);
                            localStorage.setItem(
                              "selectedMainMenu",
                              JSON.stringify({ colors: color })
                            );
                          }}
                        >
                          {color}
                        </Link>
                        <span> ({counts.colors[color]})</span>
                      </li>
                    ))}
                  </ul>
                </aside>
                <aside className="sidebar-content">
                  <div className="sidebar-title">
                    <h6>THEME</h6>
                  </div>
                  <ul>
                    {themes.map((theme, idx) => (
                      <li key={idx}>
                        <Link
                          to={`/products?themes=${theme}`}
                          onClick={() => {
                            console.log("selected side menu v", theme);
                            localStorage.setItem(
                              "selectedMainMenu",
                              JSON.stringify({ themes: theme })
                            );
                          }}
                        >
                          {theme}
                        </Link>
                        <span> ({counts.themes[theme]})</span>
                      </li>
                    ))}
                  </ul>
                </aside>


                {/* <aside className="sidebar-content">
                  <div className="sidebar-title">
                    <h6>Price</h6>
                  </div>
                  <div className="tpbr-menu">
                    <div className="price-bar">
                      <div className="info_widget">
                        <div className="price_filter">
                          <div id="slider-range1"></div>
                          <div className="price_slider_amount">
                            <div className="filter-ranger">
                              <input
                                type="text"
                                id="amount"
                                name="price"
                                placeholder="Add Your Price"
                              />
                              <a
                                className="btn btn-danger"
                                // onclick="filterprice();"
                                // style="display:none;"
                              >
                                Go
                              </a>
                              <input type="hidden" id="minvalue" />
                              <input type="hidden" id="maxvalue" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>

                </aside> */}

                <div className="clearfix"></div>
              </div>
            </div>

            <div className="col-md-9 col-sm-12 col-xs-12">
              <div className="tab-content">
                <div className="tab-pane fade in active" id="shop-grid-tab">
                  <div className="row">
                    <div className="shop-product-tab first-sale">
                      <div id="divdynamic">

                        {
                          selectedProducts.map((product, idx) => (
                            <div key={idx} className="propagebox col-lg-3 col-md-3 col-sm-3">
                              <div className="two-product">
                                <div className="single-product">
                                  <span className="sale-text">{product.discount}%</span>
                                  <div className="product-img">
                                    <a href={getProductURL(product)}
                                      onClick={() => { localStorage.setItem('selectedProduct', JSON.stringify(product)) }}
                                    >
                                      <img
                                        className="primary-image"
                                        src={product.image_url}
                                        alt=""

                                      />
                                    </a>
                                  </div>
                                  <div className="product-content">
                                    <h2 className="product-name">
                                      <a href={getProductURL(product)}
                                        onClick={() => { localStorage.setItem('selectedProduct', JSON.stringify(product)) }}
                                      >
                                        {product.productName} {product.designCode}{product.designVariant}
                                      </a>
                                    </h2>
                                    <p style={{ fontWeight: "bold" }}>
                                      ₹{product.customerPrice} &nbsp; <del>₹{product.mrp}</del>
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </div>
                          ))
                        }


                      </div>
                      <div
                        id="divshownoprods"
                        style={{ display: "none", textAlign: "center" }}
                      >
                        <h6>Products Not Available!.</h6>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="overlay">
        <div className="cv-spinner">
          <span className="spinner"></span>
        </div>
      </div>
    </div>
  );
};

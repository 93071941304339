import React, { useRef, useState, useEffect } from "react";
import { Api } from "./lib/Api";
import { sendWelcomeEmail } from "./lib/email";

export const CreateAccount = () => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState(''); 
  const [nameError, setNameError] = useState(''); 
  const [mobileError, setMobileError] = useState(''); 
  const [emailError, setEmailError] = useState(''); 
  const [passwordError, setPasswordError] = useState(''); 

  let referedBy = (window.location.href.split("refered-by=")[1]) || "";
  referedBy = referedBy.trim();
  console.log("referedBy",referedBy);

  const [profileReferedBy, setProfileReferedBy] = useState({email:null});

  const getProfileOfReferedBy = async () => {
    if ( referedBy === "" ) {
      console.log("Not refered");
      return;
    }
    let response = await Api.get_all("users",`_id=${referedBy}`);
    console.log("getProfile Refered By:resp", response);
    await setProfileReferedBy(response.data[0]);
  };

  const createAnAccount = async ()=> {

    setNameError('');
    setMobileError('');
    setPasswordError('');
    setEmailError('');
    let _name = name.trim()
    let _password = password.trim()
    let _email = email.trim();
    let _mobile = mobile.trim();

    const params = {email:_email, password:_password, name: _name, mobile:_mobile,referedBy:profileReferedBy.email};
    console.log('create account params',params);
    if ( _name ==''){
      setNameError('Name is Mandatory');
      return;
    }
    if ( _mobile.length < 10 ){
      setMobileError('Mobile Number must be 10 digits');
      return;
    }
    if ( (_email.length < 4) || (!_email.includes('@')) || (!_email.includes('.')) ){
      setEmailError('Email is not proper');
      return;
    }
    if ( _password.length < 3){
      setPasswordError('Password is too small');
      return;
    }
    
    try {
      const response = await Api.createAccount(params);

      console.log('response', response);
      if ( response.data.error) {
        setEmailError(response.data.error);
        return;
      }else {
        //update referal points and referedCustomers 
        let profile = JSON.parse(JSON.stringify(profileReferedBy));
        profile.referalPoints += 5000;
        if (!profile.referedCustomers){
          profile.referedCustomers = [];
        }
        profile.referedCustomers.push(_email);
        let response = await Api.update("users",profile);
        console.log(" update Profile:resp", response);

        sendWelcomeEmail(_email, _password)
        window.location.href = '/login';
      }

    }catch(e){
      console.log('create account error',e);
    }


  }

  useEffect(async () => {
    console.log("useEffect....");
    // effect
    getProfileOfReferedBy();

    return () => {
      // cleanup
    };
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="category3">
                    <span>Create New Customer Account</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="customer-login-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="loginwbg">
                <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="customer-register my-account">
                        <div className="register">
                          <div className="form-fields">
                            <h2>SIGN-IN INFORMATION</h2>

                            <input id="Id" name="Id" type="hidden" value="" />
                            <p className="form-row form-row-wide">
                              <label>
                                Name <span className="required">*</span>
                              </label>
                              <input
                                className="input-text"
                                data-val="true"
                                data-val-required="The Name field is required."
                                id="name"
                                name="Name"
                                type="text"
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                              />
                              <span style={{color:"red"}}>
                                <span
                                  className="field-validation-valid"
                                  data-valmsg-for="Name"
                                  data-valmsg-replace="true"
                                >{nameError}</span>
                              </span>
                            </p>

                            <p className="form-row form-row-wide">
                              <label >
                                Mobile Number <span className="required">*</span>
                              </label>
                              <input
                                className="input-text"
                                data-val="true"
                                data-val-required="The Mobile Number field is required."
                                id="mobilenumber"
                                name="MobileNumber"
                                type="text"
                                value={mobile}
                                onChange={(e)=>setMobile(e.target.value)}
                              />
                              <span style={{color:"red"}}>
                                <span
                                  className="field-validation-valid"
                                  data-valmsg-for="MobileNumber"
                                  data-valmsg-replace="true"
                                >{mobileError}</span>
                              </span>
                            </p>

                            <p className="form-row form-row-wide">
                              <label >
                                Email address <span className="required">*</span>
                              </label>
                              <input
                                className="input-text"
                                data-val="true"
                                data-val-regex="E-mail is not valid"
                                data-val-regex-pattern="^[a-zA-Z0-9_\.-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$"
                                data-val-required="The Email field is required."
                                id="email"
                                name="Email"
                                type="text"
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                              />
                              <span style={{color:"red"}}>
                                <span
                                  className="field-validation-valid"
                                  data-valmsg-for="Email"
                                  data-valmsg-replace="true"
                                >{emailError}</span>
                              </span>
                            </p>

                            <p className="form-row form-row-wide">
                              <label>
                                Password <span className="required">*</span>
                              </label>
                              <input
                                className="input-text"
                                data-val="true"
                                data-val-required="The Password field is required."
                                id="email"
                                name="Password"
                                type="Password"
                                value={password}
                                onChange={(e)=>setPassword(e.target.value)}
                              />
                              <span style={{color:"red"}}>
                                <span
                                  className="field-validation-valid"
                                  data-valmsg-for="Password"
                                  data-valmsg-replace="true"
                                >{passwordError}</span>
                              </span>
                            </p>
                            {/*<table className="mem_checkbok">
                              <tbody>
                              <tr>
                                <td width="30">
                                  <input
                                    data-val="true"
                                    data-val-checkboxtrue="This field is required."
                                    data-val-required="The isMembership field is required."
                                    id="ismembership"
                                    name="isMembership"
                                    type="checkbox"
                                    value="true"
                                    checked="checked"
                                    readOnly={true}
                                    // onChange={()=>{}}
                                  />
                                  <input
                                    name="isMembership"
                                    type="hidden"
                                    value="false"
                                  />
                                </td>
                                <td>
                                  * FREE Membership | 10% Instant discount for
                                  every purchase you make. Only for Members.{" "}
                                  <span style={{color:"red"}}>
                                    <span
                                      className="field-validation-valid"
                                      data-valmsg-for="isMembership"
                                      data-valmsg-replace="true"
                                    ></span>
                                  </span>
                                </td>
                              </tr>
                              </tbody>
                            </table>*/}
                          </div>
                          <div className="form-action">
                            <div className="actions-log">
                              <input
                                type="submit"
                                className="button"
                                name="CREATE AN ACCOUNT"
                                value="CREATE AN ACCOUNT"
                                onClick={()=>{createAnAccount()}}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12" style={{display:"none"}}>
                      <div className="customer-register my-account">
                        <div className="register">
                          <div className="form-fields">
                            <h2>PERSONAL INFORMATION</h2>

                            <p className="form-row form-row-wide">
                              <label>
                                Address <span className="required">*</span>
                              </label>
                              <input
                                className="input-text"
                                data-val="false"
                                data-val-required="The Address field is required."
                                id="address"
                                name="Address"
                                type="text"
                                value=""
                              />
                            </p>

                            <p className="form-row form-row-wide">
                              <label>
                                City <span className="required">*</span>
                              </label>
                              <input
                                className="input-text"
                                data-val="false"
                                data-val-required="The City field is required."
                                id="city"
                                name="City"
                                type="text"
                                value=""
                              />
                            </p>

                            <p className="form-row form-row-wide">
                              <label>
                                State <span className="required">*</span>
                              </label>
                              <input
                                className="input-text"
                                data-val="false"
                                data-val-required="The State field is required."
                                id="state"
                                name="State"
                                type="text"
                                value=""
                              />
                            </p>

                            <p className="form-row form-row-wide">
                              <label >
                                Country <span className="required">*</span>
                              </label>
                              <input
                                className="input-text"
                                data-val="false"
                                data-val-required="The Country field is required."
                                id="country"
                                name="Country"
                                type="text"
                                value=""
                              />
                            </p>

                            <p className="form-row form-row-wide">
                              <label >
                                Postal Code <span className="required">*</span>
                              </label>
                              <input
                                className="input-text"
                                data-val="false"
                                data-val-required="The PinCode field is required."
                                id="pincode"
                                name="PinCode"
                                type="text"
                                value=""
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

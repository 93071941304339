import { useEffect, useState } from "react";
import { Api } from "../lib/Api";
import { sendCalculatorEmail, sendForgotPassordEmail, sendAccountNotFoundEmail } from "../lib/email";

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [invalidCreds, setInvalidCreds] = useState({display: "none"});
  const [passwordRecoveryEmail, setPasswordRecoveryEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  
  let redirect = (window.location.href.split("redirect=")[1]) || "";
  // redirect = redirect.split("=")[1] || "";
  redirect = redirect.trim();
  // console.log("slug",window.location.href);
  console.log("slug",redirect);

  const authenticate = async() => {
    try {
      setInvalidCreds({display: "none"})
      const result = await Api.authenticate({email:email,password:password});
      console.log("Auth",result);
      localStorage.setItem('token', result.data.token);
      localStorage.setItem('email', email);
      if (localStorage.getItem("calculations")){
        await sendCalculatorEmail(email);
      }
      window.location.href = "/"+redirect;
    }
    catch(e){
      console.log("Auth Error",e);
      setInvalidCreds({color: "red"})
    }
  }

  const sendPasswordRecoveryEmail = async() => {
      let response = await Api.get_all("users", `email=${passwordRecoveryEmail}`);
      console.log("recovery:resp", response);
      if (response.data.length > 0){
        await sendForgotPassordEmail(passwordRecoveryEmail, response.data[0].password);
      }else{
        await sendAccountNotFoundEmail(passwordRecoveryEmail);
      }
      
      setEmailSent(true);
      setTimeout(()=>{
        setEmailSent(false);
      },2000)
  }  
  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="category3">
                    <span>Customer Login</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-12" id="divdanger" style={{display: "none"}}>
              <div className="container-inner">
                <div
                  className="alert alert-danger"
                  role="alert"
                  id="divmsg"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="customer-login-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="loginwbg">
                <div className="row">
                  <div className="col-md-4 col-xs-12">
                    <div className="customer-login my-account">
                        <div className="form-fields">
                          <h2>Registered Customers</h2>
                          <p>
                            If you have an account, sign in with your email
                            address.
                          </p>

                          <p className="form-row form-row-wide">
                            <label htmlFor="username">
                              Email <span className="required">*</span>
                            </label>
                            <input
                              className="input-text"
                              data-val="true"
                              data-val-regex="E-mail is not valid"
                              data-val-regex-pattern="^[a-zA-Z0-9_\.-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$"
                              data-val-required="The Email field is required."
                              id="email"
                              name="Email"
                              type="text"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <span style={{color: "red"}} id="emailerr">
                              <span
                                className="field-validation-valid"
                                data-valmsg-for="Email"
                                data-valmsg-replace="true"
                              ></span>
                            </span>
                          </p>
                          <p className="form-row form-row-wide">
                            <label htmlFor="password">
                              Password <span className="required">*</span>
                            </label>
                            <input
                              className="input-text"
                              data-val="true"
                              data-val-required="The Password field is required."
                              id="password"
                              name="Password"
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span style={{color: "red"}} id="pwderr">
                              <span
                                className="field-validation-valid"
                                data-valmsg-for="Password"
                                data-valmsg-replace="true"
                              ></span>
                            </span>
                          </p>
                        </div>
                        <div className="form-action">
                          <div className="actions-log">
                            <a
                              style={{cursor: "pointer"}}
                              className="crbtn"
                              onClick={()=>{authenticate()}}
                            >
                              Login
                            </a>
                            <span style={invalidCreds}>Invalid Credentials.</span>
                          </div>
                          <p className="lost_password">
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#exampleModalCenter"
                            >
                              Forgot Your Password?
                            </a>
                          </p>
                        </div>
                    </div>
                  </div>

                  <div className="col-md-2"></div>

                  <div className="col-md-6 col-xs-12">
                    <div className="customer-login my-account">
                        <div className="form-fields">
                          <h2>New Customers</h2>
                          <p>
                            Creating an account has many benefits: check out
                            faster, keep more than one address, track orders and
                            more.
                          </p>
                        </div>
                        <div className="form-action">
                          <div className="actions-log">
                            <br />
                            <a href="/create-account" className="crbtn">
                              CREATE AN ACCOUNT
                            </a>
                          </div>
                        </div>
                    </div>
                  </div>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Calculator Popup Modal --> */}
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Forgot Your Password
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body popwin wallpapwin">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <input id="CustomerId" name="Id" type="hidden" value="" />

                    <div className="form-group">
                      <label>Email :</label>
                      <span style={{color: "red"}}>*</span>
                      <input
                        className="form-control"
                        id="Cemail"
                        name="Email"
                        type="text"
                        style={{width:"90%"}}
                        value={passwordRecoveryEmail}
                        onChange={(e)=>setPasswordRecoveryEmail(e.target.value)}
                      />
                    </div>
                    <div className="clearfix"></div>

                    <div
                      className="form-group"
                      style={{marginTop: 10}}
                      id="checkdiv"
                    >
                      <a
                        style={{cursor: "pointer"}}
                        className="crbtn"
                        onClick={sendPasswordRecoveryEmail}
                      >
                        SUBMIT
                      </a>
                    </div>
                    <span style={{display:emailSent?"block":"none",color:"red"}}> Mail With Password sent 
                    </span>

                    <div id="maindiv" style={{display: "none"}}>
                      <div className="container-inner">
                        <div
                          className="alert alert-danger"
                          role="alert"
                          id="dangerdiv"
                          style={{display: "none"}}
                        >
                          <span id="dangerresult"></span>
                        </div>
                        <div
                          className="alert alert-success"
                          role="alert"
                          id="successdiv"
                          style={{display: "none"}}
                        >
                          <span id="successresult"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div style={{display: "none"}}>
              <table className="table" id="carttable">
                <tbody></tbody>
              </table>
              <table className="table" id="totaltable">
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

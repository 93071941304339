import React, { useRef, useState, useEffect } from "react";
import { Api } from "./lib/Api";
import { OrderDetailsModal } from "./user-components/OrderDetailsModal";


export const OrderFailure = ({setNCartItems}) => {

  const orderId = JSON.parse(localStorage.getItem("orderid")) || 0;
  const email = localStorage.getItem("email");

  const emptyOrder = {
    orderid:"",
    email:"", 
    payment : {
      products : [], amount : {total:0}
    },
    razorpayGW : {
      orderid : "",
      client : {},
      server : {},
    }
  }

  const [order, setOrder] = useState(emptyOrder);
  
  async function getOrder(){
    let response = await Api.get_all("orders", `email=${email}&orderid=${orderId}`);
    console.log("getOrder:resp", response);
    if (response.data.length < 1 ){
      alert(`Order Id ${orderId} does not exist in DB`)
      return;
    }
    const _order = response.data[0];
    if (_order.razorpayGW == undefined) {
      _order.razorpayGW = emptyOrder.razorpayGW;
    }
    if (_order.razorpayGW.server == undefined) {
      _order.razorpayGW.server = emptyOrder.razorpayGW.server;
    }
    if (_order.razorpayGW.client == undefined) {
      _order.razorpayGW.server = emptyOrder.razorpayGW.client;
    }
    await setOrder( _order);
    if (_order.status == "captured"){
      localStorage.removeItem("cartProducts");
      setNCartItems(0)
    }
  }
  
  useEffect(async () => {
    console.log("useEffect");
    // effect
    Api.verifyToken();
    getOrder();

    return () => {
      // cleanup
    };
  }, []);
  
  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="category3">
                    <span>Your Order Is Successful</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cart-area-start">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
              <table className="cart-table" id="cart">
                  <thead>
                    <tr>
                      <th>Order #</th>
                      <th>Status</th>
                      <th>Razorpay orderid</th>
                      <th>Amount</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>
                          {order.orderid}
                        </td>
                        <td>
                          <h6>
                            {order.status}
                          </h6>
                        </td>
                        <td>
                          <h6>
                            {order.razorpayGW.orderid}
                          </h6>
                        </td>
                        <td>
                          <div className="cart-price">
                            ₹{order.razorpayGW.server.amount}
                          </div>
                        </td>
                        <td>
                        <a
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            href="#"
                            // className="crbtn"
                          >
                            Details
                          </a>
                        </td>
                      </tr>

                      <tr id="lastrow">
                        <td className="actions-crt" colspan="7">
                          <div align="center">
                            <a
                              className="crbtn"
                              href="products"
                            >
                              Continue Shopping
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr id="cart-is-empty">
                        <td className="actions-crt" colspan="7">
                          <div className="">
                            <div className="col-md-12 col-sm-4 col-xs-4 align-left">
                              <h6>
                                <a className="cbtn" href="/orders">
                                  Check All Orders Here
                                </a>
                              </h6>
                              <br />
                            </div>
                          </div>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <OrderDetailsModal order={order}/>
    
    </div>
  );
};

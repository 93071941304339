export const Config =  {
    // IMAGES_BASE: `${window.location.protocol}//${window.location.hostname}:${80}`,
    // API_BASE: `${window.location.protocol}//${window.location.hostname}:${80}`,
    
    IMAGES_BASE: `https://wallpap.in`,
    API_BASE: `https://wallpap.in`,
    PAYMENT_BASE: `https://wallpap.in`,
    // PAYMENT_BASE: `${window.location.origin}`,

    MERCHANT_ID : "570987",
}

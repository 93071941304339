import { SocialIconList } from "./SocialIconList";

export const Footer = () => {
  return (
    <>
      <footer>
        <div className="top-footer-area">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="single-snap-footer">
                  <div className="snap-footer-title">
                    <h4>Nimal Maula Incociar Projects</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-4 hidden-sm" align="right">
                <div className="single-snap-footer">
                  <div className="cakewalk-footer-content social-footer">
                    <SocialIconList />
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <br />
              <div className="col-md-12">
                <hr className="footer--line" />
              </div>
              <div className="clearfix"></div>
              <br />
              <br />
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="single-snap-footer">
                  <div className="snap-footer-title">
                    <h4>About Wallpap</h4>
                  </div>
                  <div className="row">
                    <div className="col-md-6 cakewalk-footer-content">
                      <ul>
                        <li>
                          <a href="/about-us">About Us</a>
                        </li>
                        <li>
                          <a href="/contact-us">Contact us</a>
                        </li>
                        <li>
                          <a href="/faqs">FAQ's</a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 cakewalk-footer-content"></div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="single-snap-footer">
                  <div className="snap-footer-title">
                    <h4>CUSTOMER SERVICE</h4>
                  </div>
                  <div className="cakewalk-footer-content">
                    <ul>
                      <li>
                        <a href="/calculator-page">Wallpap Calculator</a>
                      </li>
                      <li>
                        <a href="/t-and-c">Terms & Conditions</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="refund-policy">Refund Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="single-snap-footer">
                  <div className="snap-footer-title">
                    <h4>CONTACT INFORMATION</h4>
                  </div>
                  <div className="cakewalk-footer-content">
                    <p className="footer-des">
                      <b>EMAIL</b>
                      <br /> care@wallpap.in
                      <br />
                      <b>CALL US</b>
                      <br /> 9100 44 64 25
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="address-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-xs-12" align="center">
                <address>Copyright © 2023 Wallpap All Rights Reserverd</address>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

import { Api } from "../lib/Api";
import React, { useRef, useState, useEffect } from "react";
import { Config } from "../Config";

export const OurProductArea = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);

  const getFeaturedProducts = async ()=>{
    let response = await Api.get_all("products","categories=FEATURED PRODUCTS");
    console.log("getFeaturedProducts:resp", response);
    response.data.map((el)=>el.image_url = `${Config.IMAGES_BASE}/products/${el.image}`);
    await setFeaturedProducts(response.data);
    console.log('featuredProducts:',featuredProducts);;
  };


  const selectThisCat = () =>{
    console.log("selected FEATURED PRODUCTS");
    localStorage.setItem(
      "selectedMainMenu",
      JSON.stringify({ categories: 'FEATURED PRODUCTS' })
    );
  }  
  const selectThisProduct = (ele) =>{
    console.log("selected PRODUCT",ele);
    selectThisCat();
    localStorage.setItem('selectedProduct',JSON.stringify(ele));
  }

  useEffect(async () => {
    await getFeaturedProducts();

    window.jQuery(".features-curosel").owlCarousel({
      autoPlay: true,
      slideSpeed: 2000,
      items: 4,
      pagination: false,
      navigation: true,
      navigationText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>",
      ],
      itemsDesktop: [1199, 4],
      itemsDesktopSmall: [979, 3],
      itemsMobile: [768, 2],
      rewindNav: true,
      lazyLoad: true,
    });




    return () => {
      // cleanup
    };

  },[]);


  return (
    <div className="our-product-area">
      <div className="container">
        <div className="area-title">
          <h2>FEATURED PRODUCTS</h2>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="features-tab">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane fade in active">
                  <div className="row">
                    <div className="features-curosel">

                      {
                        featuredProducts.map((el,idx)=>(

                        <div key={idx} className="col-lg-12 col-md-12">
                          <div className="single-product first-sale fetuprowin">
                            <span className="sale-text">{el.discount}% </span>
                            <div className="product-img">
                              <a href={`/details/${el.productId}`/*`/details?productid=${el._id}`*/}
                                onClick={()=>selectThisProduct(el)}>
                                <img
                                  className="primary-image"
                                  src={el.image_url}
                                  alt=""
                                />
                              </a>
                            </div>
                            <div className="product-content">
                              <h2 className="product-name">
                                <a href={`/details/${el.productId}`/*`/details?productid=${el._id}`*/}
                                  onClick={()=>selectThisProduct(el)}>
                                  {el.productName} <br/>
                                  {el.designVariant}{el.designCode}
                                </a>
                              </h2>
                            </div>
                            <p>
                              {/* ₹{el.mrp -(el.mrp*el.discount/100)} &nbsp; <del>₹{el.mrp}</del> */}
                              ₹{el.customerPrice} &nbsp; <del>₹{el.mrp}</del>

                            </p>
                          </div>
                        </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

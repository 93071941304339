import React, { useState, useEffect } from "react";

function HeaderLogoArea() {
  const [search,setSearch] = useState("");
  
  const doSearch = () => {
    console.log("selected NEW PRODUCTS");
    localStorage.setItem(
      "selectedMainMenu",
      JSON.stringify({ search: search })
    );
    window.location.href = `/products?search=${search}`;
  }

  return (
    <div className="container header-logo--area">
      <div className="row">
        <div className="col-md-3 langwin text-left"></div>
        <div className="col-md-6 text-center">
          <div className="top-logo">
            <a href="/">
              <img src="/img/logo.png" alt="wallpap logo" width="300" />
            </a>
          </div>
        </div>

          <div className="col-md-3 text-right">
            <div className="input-group searchwin">
              <input
                className="form-control"
                id="Searchbox"
                name="Searchbox"
                placeholder="Search entire store here..."
                type="text"
                value={search}
                onChange={(e)=>setSearch(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    console.log('do validate')
                    doSearch();
                  }
                }}
              />

              <span className="input-group-btn">
                <button type="submit" className="btn btn-default" onClick={doSearch}>
                  <i className="fa fa-search"></i>
                </button>
              </span>
            </div>
          </div>
      </div>
    </div>
  );
}

export default HeaderLogoArea;

import React from "react";

export const OrderDetailsModal = ({ order }) => {
  return (
    <div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Order Details #{order.orderid}
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

            </div>
            <div className="modal-body wallpapwin">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <input id="Id" name="Id" type="hidden" value="20035" />
                    <div className="form-group col-md-10">
                      {order.payment.products.map((product, idx) => (
                        <div className="row">
                          <div className="form-group col-md-2">
                            <img src={product.image_url} />
                          </div>
                          <div className="form-group col-md-8">
                            {product.productName} {product.designCode}-
                            {product.Variant}
                          </div>
                          <div className="form-group col-md-2">
                            x {product.quantity}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">Total</div>
                      <div className="form-group col-md-6">
                        {order.payment.amount.total}
                      </div>
                    </div>
                    {order.payment.amount.couponDiscountAmount > 0 ? (
                      <div className="row">
                        <div className="form-group col-md-6">
                          Coupon Discount
                        </div>
                        <div className="form-group col-md-6">
                          {order.payment.amount.couponDiscountAmount}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {order.payment.amount.membershipDiscountAmount > 0 ? (
                      <div className="row">
                        <div className="form-group col-md-6">
                          Membership Discount
                        </div>
                        <div className="form-group col-md-6">
                          {order.payment.amount.membershipDiscountAmount}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="row">
                      <div className="form-group col-md-6">Grand Total</div>
                      <div className="form-group col-md-6">
                        {order.payment.amount.grandTotal}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">Razorpay orderid</div>
                      <div className="form-group col-md-6">
                        {order.razorpayGW.orderid}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">Status</div>
                      <div className="form-group col-md-6">
                        {order.status}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">Payment Mode</div>
                      <div className="form-group col-md-6">
                        {order.razorpayGW.server.method}
                      </div>
                    </div>

                    <div
                      className="form-group col-md-12"
                      style={{ marginTop: 25, textAlign: "center" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <input
                type="submit"
                value="Dismiss"
                data-dismiss="modal"
                className="btn btn-primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import axios from "axios";
import {Config} from "../Config"

let endpoint = {
  // base: "http://localhost",
  // port: "3001",
  db : 'db',
};

// endpoint.db_uri = `${endpoint.base}:${endpoint.port}/${endpoint.db}`;
// endpoint.upload_images_uri = `${endpoint.base}:${endpoint.port}${endpoint.upload_images}`;

endpoint.db_uri = `${Config.IMAGES_BASE}/${endpoint.db}`;
endpoint.upload_images_uri = `${Config.IMAGES_BASE}${endpoint.upload_images}`;

const headers = {
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem("token"),
  }
}


export const Api = {
  endpoint : endpoint,
  updateUser: function(userId,userUpdate){
    return (
        axios.put(`${Config.API_BASE}/users/${userId}`, userUpdate, headers)
    )
  },
  addUserAddress: function(userId,address){
    return ( axios.post(`${Config.API_BASE}/users/${userId}/addresses`, address, headers));
  },
  updateUserAddress: function(userId,addressId,addressUpdate){
    return (
        axios.put(`${Config.API_BASE}/users/${userId}/addresses/${addressId}`, addressUpdate, headers)
    )
  },
  get_all : function(collection,filter){
    const query = filter?`?${filter}`:"";
    return (
      axios.get(`${endpoint.db_uri}/${collection}/all${query}`,headers)
    )
  },
  getProductByProductId:function(productId){
    return (
        axios.get(`${endpoint.db_uri}/products/all?productId=${productId}`,headers)//TODO: use one instead of all to return single product...
    )
  },
  get_one : function(collection,_id){
    return (
      axios.get(`${endpoint.db_uri}/${collection}/one?id=${_id}`,headers)
    )
  },
  update : function(collection,params){
    return (
      axios.put(`${endpoint.db_uri}/${collection}/one`, params,headers)
    )
  },
  upsert : function(collection,filter,params){
    const query = filter?`?${filter}`:"";
    return (
      axios.put(`${endpoint.db_uri}/${collection}/upsert${query}`, params, headers)
    )
  },
  save : function(collection,params){
    return (
      axios.post(`${endpoint.db_uri}/${collection}/one`, params, headers)
    )
  },
  delete : function(collection,_id, image){
    return (
      axios.delete(`${endpoint.db_uri}/${collection}/one?id=${_id}&image=${image}`, headers)
    )
  },
  get_count : function(collection){
    return (
      axios.get(`${endpoint.db_uri}/${collection}/count`, headers)
    )
  },
  get_count_colorsthemes : function(collection){
    return (
      axios.get(`${endpoint.db_uri}/${collection}/count/colorsthemes`, headers)
    )
  },
  createAccount : function(params){
    return (
      axios.post(`${Config.IMAGES_BASE}/auth/account`,params, headers)
    )
  },
  authenticate: function (params){
    return (
      axios.post(`${Config.IMAGES_BASE}/login/users`, params, headers)
    )
  },
  email: function (params){
    return (
      axios.post(`${Config.IMAGES_BASE}/sg-mail/text`, params, headers)
    )
  },
  payment: function (params){
    return (
      axios.post(`${Config.PAYMENT_BASE}/api/v1/payment`, params, headers)
    )
  }, 
  statusApi: function (params){
    return (
      axios.get(`${Config.PAYMENT_BASE}/api/v1/status-api?${params}`, headers)
    )
  },
  verifyToken : async function(){
    const token = (localStorage.getItem("token"));
    const email = (localStorage.getItem("email"));
    console.log("VerifyToken token, email",token,email);
    
    if (email && !token){
      localStorage.removeItem("email");
      window.location.href = "/login";
      return;
    }
    if (!email && token){
      localStorage.removeItem("token");
      window.location.href = "/login";
      return;
    }
    if (!email && !token){
      window.location.href = "/login";
    }

    try {
      const result = await axios.post(`${Config.PAYMENT_BASE}/auth/token/users?email=${email}`, {token: token});
      console.log('verifyToken success',result)
    }
    catch(e){
      console.log('verifyToken for token',localStorage.getItem('token'));
      console.log('verifyToken failed',e);
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      window.location.href = "/login";
    }
  },
  razorPayCreateOrder: function (params){
    return (
      axios.post(`${Config.PAYMENT_BASE}/api/v1/razorpay/createpayment`, params, headers)
    )
  },
  cashfreeCreateOrder: function (params){
    return (
      axios.post(`${Config.PAYMENT_BASE}/api/v1/cashfree/create-order`, params, headers)
    )
  },
  cashfreeGetOrder: function (params){
    return (
      axios.post(`${Config.PAYMENT_BASE}/api/v1/cashfree/get-order`, params, headers)
    )
  },
};

export function loadScript(src){
  return new Promise((resolve)=>{
    const script = document.createElement('script');
    script.src = src;
    document.body.appendChild(script);
    script.onload = () => {
      resolve(true);
    }
    script.onerror = () => {
      resolve(false);
    }
  })
}
import React, { useRef, useState, useEffect } from "react";
import { Api } from "./lib/Api"

export const Contactus = () => {
  const [name, setName]  = useState("");
  const [email, setEmail]  = useState("");
  const [mobile, setMobile]  = useState("");
  const [message, setMessage]  = useState("");
  const [sending, setSending]  = useState(false);

  const [error, setError]  = useState({
    name : false,
    email : false,
    mobile : false,
    message : false,
  });


  async function sendEmail(){
    setError({
      name : false,
      email : false,
      mobile : false,
      message : false,
    });
    
    if (name.length < 1) {
      setError({
        name : true,
        email : false,
        mobile : false,
        message : false,
      });
      return;
    }
    if (email.length < 3) {
      setError({
        name : false,
        email : true,
        mobile : false,
        message : false,
      });
      return;
    }
    if (mobile.length < 1) {
      setError({
        name : false,
        email : false,
        mobile : true,
        message : false,
      });
      return;
    }
    if (message.length < 1) {
      setError({
        name : false,
        email : false,
        mobile : false,
        message : true,
      });
      return;
    }

    //everything is hunky dory so now send the email
    setSending(true);
    const html =`
        Hi Wallpap Admin
        Some has generated the following request using the contact us page
        name : ${name}
        email : ${email}
        mobile : ${mobile}
        message: ${message}
        
        Regards
        The Wallpap Team`;

        //send email
        const msg = {
          to: [email,"info@incociar.com"],
          from: "info@incociar.com", // Use the email address or domain you verified above
          subject: "Contact Us Request ",
          // text: "and easy to do anywhere, even with Node.js",
          html : html,
        }
    
    
        const response = await Api.email({email:msg});
        console.log("contactus:resp", response);

        setTimeout(()=>{setSending(false);},1000)
        
  }

  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/UI/Index">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="home">
                    <a href="/UI/Contactus">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-details-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="product-list-wrapper">
                <div className="single-product">
                  <div className="product-content">
                    <h2 className="product-name" style={{ display: "none" }}>
                      <a href="#">Contact Us</a>
                    </h2>

                    {/* <div className="product-desc">
                      <p style={{ textAlign: "justify" }}>
                        <b>Our Wallpaper Consulting Team</b>
                        <br />
                        We are a team of Qualified Interior Design
                        Professionals, with exclusive customer support working
                        behind the screens to make your wallpaper dreams come
                        true. Wallpaper hanging and installation is an art,
                        Weather you’re using wallpaper to accent architectural
                        details within your home or to transform a room with
                        decent shades of color, our wallpaper installation
                        professionals will gladly meet your vision. Our
                        professional wallpaper team has been trained in the
                        craft of wall-covering applications.
                        <br />
                        <br />
                        Our team is committed to providing the finest quality
                        wallpaper installation in Hyderabad. From choosing the
                        wallpaper design to the final walkthrough, until the job
                        is finished, our main priority is customer satisfaction.
                        <br />
                        <br />
                        At wallpap, we strive to provide as much value to our
                        customers as possible to make it as pleasant a shopping
                        experience can be.
                        <br />
                        <br />
                        ● Any and all complaints are welcome as we are always
                        looking to better our business for our customers.
                        <br />
                        <br />
                        ● If there happens to be a problem in any aspect of our
                        business, pre- or post-sale please feel free to contact
                        our customer support team and we are more than happy to
                        assist you in resolving the issue.
                        <br />
                        <br />
                        Please note - we are an online wallpapers store.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="product-list-wrapper">
                <div className="single-product">
                  <div className="product-content">
                    <div className="product-desc">
                      <p style={{ textAlign: "justify" }}>
                        <b style={{ textTransform: "uppercase" }}>
                          Contact Address
                        </b>
                        <br />
                        NIMAL MAULA INCOCIAR LLP.
                        <br />
                        404, KONARK TOWERS, ANDHRA BANK COLONY, DILSUKHNAGAR,
                        <br />
                        HYDERABAD- 500036, TELANGANA.
                        <br />
                        e-Mail: Care@wallpap.in
                        <br />
                        Call: 9100 44 64 25
                      </p>
                      <br />
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.8986419654902!2d78.51655291418727!3d17.368609607919637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99c0d55062ad%3A0x610b0f215167d6ef!2sNimal%20Maula%20Incociar%20Projects%20%7C%20Interior%20Designing%20%7C%20Contractions%20%7C%20Civil%20Engineering%20%7C%20Architecture!5e0!3m2!1sen!2sin!4v1605702658383!5m2!1sen!2sin"
                        width="100%"
                        height="223"
                        frameborder="0"
                        style={{border:"5px solid #e4e4e4"}}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                      ></iframe>
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="product-list-wrapper">
                <div className="single-product">
                  <div className="product-content">
                    <div className="product-desc">
                      <p style={{ textAlign: "justify" }}>
                        <b style={{ textTransform: "uppercase" }}>
                          Send Enquiry
                        </b>
                        <br />
                      </p>

                      <div className="clearfix"></div>
                      <br />
                      <div className="row">
                        <div className="col-md-12">
                          <label>
                            {" "}
                            Name{" "}
                            <span className="required" style={{color:"red"}}>
                              *
                            </span>
                          </label>
                          <input
                            style = {error.name?{border:"1px solid red"}:{}}
                            type="text"
                            id="txtname"
                            className="input-text form-control"
                            value={name}
                            onChange={(e)=>{setName(e.target.value)}}
                          />
                        </div>
                        <div className="clearfix"></div>
                        <br />
                        <div className="col-md-12">
                          <label>
                            {" "}
                            Email Address{" "}
                            <span className="required" style={{color:"red"}}>
                              *
                            </span>
                          </label>
                          <input
                            style = {error.email?{border:"1px solid red"}:{}}
                            type="email"
                            id="txtemail"
                            className="input-text form-control"
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}
                          />
                        </div>
                        <div className="clearfix"></div>
                        <br />
                        <div className="col-md-12">
                          <label>
                            {" "}
                            Mobile Number{" "}
                            <span className="required" style={{color:"red"}}>
                              *
                            </span>
                          </label>
                          <input
                            style = {error.mobile?{border:"1px solid red"}:{}}
                            type="text"
                            id="txtnumber"
                            className="input-text form-control"
                            value={mobile}
                            onChange={(e)=>{setMobile(e.target.value)}}
                          />
                        </div>
                        <div className="clearfix"></div>
                        <br />
                        <div className="col-md-12">
                          <label>
                            {" "}
                            Message{" "}
                            <span className="required" style={{color:"red"}}>
                              *
                            </span>
                          </label>
                          <input
                            style = {error.message?{border:"1px solid red"}:{}}
                            type="text"
                            id="txtmsg"
                            className="input-text form-control"
                            value={message}
                            onChange={(e)=>{setMessage(e.target.value)}}
                          />
                        </div>
                        <div className="clearfix"></div>
                        <br />
                        <div className="col-md-12">
                          <button
                            type="button"
                            id="btnsend"
                            onClick={sendEmail}
                            className="calbtn"
                          >
                            {sending?"SENDING...":"SEND"}
                          </button>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

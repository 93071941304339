import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';


import { Landing } from './Landing';
import { ProductsPg } from './user-components/ProductsPg';
import { Details } from './Details';


import { Footer } from './user-components/Footer';
import HeaderLogoArea from './user-components/HeaderLogoArea';
import HeaderMenu1 from './user-components/HeaderMenu1';
import HeaderTop from './user-components/HeaderTop';
import MainMenuTop from './user-components/MainMenuTop';
import { MobileNav } from './user-components/MobileNav';
import { Cart } from './Cart';


import React, { useRef, useState, useEffect } from "react";
import { Login } from './user-components/Login';
import { CreateAccount } from './CreateAccount';
import { Profile } from './Profile';
import { Membership } from './Membership';
import { Orders } from './Orders';
import { OrdersCashfree } from './OrdersCashfree';
import { Wishlist } from './Wishlist';
import { Checkout } from './Checkout';
import { Aboutus } from './Aboutus';
import { Contactus } from './Contactus';
import { Faqs } from './Faqs';
import { TandC } from './TandC';
import { PrivacyPolicy } from './PrivacyPolicy';
import { RefundPolicy } from './RefundPolicy';
import { CalculatorPg } from './CalculatorPg';
import { OrderSuccess } from './OrderSuccess';
import { OrderFailure } from './OrderFailure';
import { OrderCashfree } from './OrderCashfree';

function App() {
  const cartProducts = JSON.parse(localStorage.getItem('cartProducts')) || [];
  const [nCartItems, setNCartItems] = useState(cartProducts.length);

  return (
    <Router>
      <div>
        <MobileNav nCartItems={nCartItems} setNCartItems={setNCartItems} />
        <HeaderTop />
        <HeaderMenu1 />
        <HeaderLogoArea />
        <MainMenuTop nCartItems={nCartItems} setNCartItems={setNCartItems} />

        <Switch>
          <Route exact path='/' component={Landing}></Route>
          <Route exact path='/products' component={ProductsPg}></Route>
          <Route path='/details/:productId' render={(props) =>
            <Details nCartItems={nCartItems} setNCartItems={setNCartItems} props={props} />}>
          </Route>
          <Route exact path='/login' component={Login}></Route>
          <Route exact path='/create-account' component={CreateAccount}></Route>
          <Route exact path='/cart' render={(props) =>
            <Cart nCartItems={nCartItems} setNCartItems={setNCartItems} props={props} />}>
          </Route>
          <Route exact path='/profile' component={Profile}></Route>
          {/*<Route exact path='/membership' component={Membership}></Route>*/}
          {/* <Route exact path='/orders' component={Orders}></Route> */}
          <Route exact path='/orders-cashfree' component={OrdersCashfree}></Route>
          <Route exact path='/wishlist' render={(props) =>
            <Wishlist nCartItems={nCartItems} setNCartItems={setNCartItems} props={props} />}>
          </Route>
          <Route exact path='/checkout' component={Checkout}></Route>
          <Route exact path='/about-us' component={Aboutus}></Route>
          <Route exact path='/contact-us' component={Contactus}></Route>
          <Route exact path='/faqs' component={Faqs}></Route>
          <Route exact path='/t-and-c' component={TandC}></Route>
          <Route exact path='/privacy-policy' component={PrivacyPolicy}></Route>
          <Route exact path='/refund-policy' component={RefundPolicy}></Route>
          <Route exact path='/calculator-page' component={CalculatorPg}></Route>

          {/* <Route exact path='/order-success' component={OrderSuccess}></Route> */}
          {/* <Route exact path='/order-success' render={(props) => 
            <OrderSuccess setNCartItems={setNCartItems} props={props} /> }>
          </Route> */}
          <Route exact path='/order-cashfree' render={(props) =>
            <OrderCashfree setNCartItems={setNCartItems} props={props} />}>
          </Route>
          {/* <Route exact path='/order-failure' component={OrderFailure}></Route> */}
          <Route exact path='/order-failure' render={(props) =>
            <OrderFailure setNCartItems={setNCartItems} props={props} />}>
          </Route>

        </Switch>

        <Footer />

      </div>
    </Router>
  );
}

export default App;

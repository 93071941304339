import { Api } from "../lib/Api";
import React, { useRef, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export const MobileNav = ({ nCartItems, setNCartItems }) => {
  const [search, setSearch] = useState("");

  const doSearch = () => {
    console.log("selected NEW PRODUCTS");
    localStorage.setItem(
      "selectedMainMenu",
      JSON.stringify({ search: search })
    );
    window.location.href = `/products?search=${search}`;
  };
  const [categories, setCategories] = useState([]);
  const [themes, setThemes] = useState([]);
  const [colors, setColors] = useState([]);

  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");

  const $ = window.jQuery;

  const closeMobileSideMenu = () => {
    $("body").removeClass("sidenav-bar-visible");
    $(".nav-toggler").parent(".btn-box").addClass("no-touch");
  };

  const mobileMenuSetup = () => {
    //Submenu Dropdown Toggle
    if ($(".sidenav-bar li.dropdown ul").length) {
      $(".sidenav-bar .navigation li.dropdown").append(
        '<div class="dropdown-btn"><span class="fa fa-plus"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".sidenav-bar").length) {
      $(".sidenav-bar .close-btn").on("click", closeMobileSideMenu);

      //Dropdown Button
      $(".sidenav-bar li.dropdown .dropdown-btn").on("click", function () {
        $(this).prev("ul").slideToggle(500);
        $(this).toggleClass("active");
      });

      //Menu Toggle Btn
      $(".nav-toggler").on("click", function () {
        $("body").toggleClass("sidenav-bar-visible");
        $(this).parent(".btn-box").toggleClass("no-touch");
      });
    }

    //Header Search
    if ($(".search-btn").length) {
      $(".search-btn").on("click", function () {
        $("body").addClass("search-active");
      });
      $(".close-search").on("click", function () {
        $("body").removeClass("search-active");
      });
    }

    //circular-menu
    if ($(".circular-menu").length) {
      $(".circular-menu .floating-btn").on("click", function () {
        $(".circular-menu").toggleClass("active");
      });
    }

    //circular-menu
    if ($(".square-menu").length) {
      $(".square-menu .floating-btn").on("click", function () {
        $(".square-menu").toggleClass("active");
      });
    }

    //Hidden Sidebar
    if ($(".sidenav-bar").length) {
      $(".sidenav-bar").mCustomScrollbar({
        theme: "dark",
      });
    }
  };

  const getSelectLists = async () => {
    let response = await Api.get_all("categories");
    console.log("getSelectLists:categories", response);
    await setCategories(response.data.map((el) => el.name));

    response = await Api.get_all("themes");
    console.log("getSelectLists:themes", response);
    await setThemes(response.data.map((el) => el.name));

    response = await Api.get_all("colors");
    console.log("getSelectLists:colors", response);
    await setColors(response.data.map((el) => el.name));

    mobileMenuSetup();

    // setTimeout(()=>{mobileMenuSetup();},2000)
  };

  const selectColor = (color) => {
    console.log("selected color", color);
    localStorage.setItem("selectedMainMenu", JSON.stringify({ colors: color }));
    $("body").removeClass("sidenav-bar-visible");
    $(".nav-toggler").parent(".btn-box").addClass("no-touch");
  };

  const selectTheme = (theme) => {
    console.log("selected theme", theme);
    localStorage.setItem("selectedMainMenu", JSON.stringify({ themes: theme }));
    $("body").removeClass("sidenav-bar-visible");
    $(".nav-toggler").parent(".btn-box").addClass("no-touch");
  };

  const logout = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("email");
    localStorage.clear();
    window.location.href = "/";
  };

  useEffect(async () => {
    console.log("useEffect");
    // effect
    getSelectLists();
  }, []);

  return (
    <div className="container">
      <div className="mobile_navwin">
        <div className="mob_toog">
          <button className="nav-toggler">
            <span></span>
          </button>
        </div>
        <div className="mob_logo" align="left">
          <a href="/">
            <img src="/img/logo.png" alt="" width="100%" />
          </a>
        </div>
        <div className="mob_cart">
          <div className="disflow crt-edt">
            <div className="circle-shopping expand">
              <div className="shopping-carts text-right">
                <div className="cart-toggler">
                  <a href={email ? "/cart" : "/login?redirect=cart"}>
                    <i className="icon-bag"></i>
                  </a>
                  <a href={email ? "/cart" : "/login?redirect=cart"}>
                    <span className="cart-quantity">{nCartItems}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mob_search">
          <button
            type="button"
            id="mob-search-button"
            className="btn btn-link"
            data-toggle="collapse"
            data-target="#mobileSearchBar"
          >
            <i className="fa fa-search"></i>
          </button>

          <div id="mobileSearchBar" className="collapse">
            <div className="col-md-3 text-right">
              <div className="input-group searchwin">
                <input
                  className="form-control"
                  id="Searchbox--mob"
                  name="Searchbox"
                  placeholder="Search entire store here..."
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      console.log("do validate");
                      doSearch();
                    }
                  }}
                />

                <span className="input-group-btn">
                  <button
                    type="submit"
                    className="btn btn-default"
                    onClick={doSearch}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="clearfix"></div>
        <section className="sidenav-bar">
          <div className="nav-box">
            <div className="inner-box">
              <div className="upper-box">
                <div className="btn-box">
                  <button className="close-btn">
                    <span className="fa fa-times"></span>
                  </button>
                </div>
              </div>
              <nav className="side-nav">
                <ul className="navigation clearfix" id="nav">
                  <li>
                    <a href="/">Home</a>
                  </li>

                  <li className="dropdown">
                    <a href="#">My Account</a>
                    <ul>
                      {email ? (
                        <>
                          <li>
                            <a href="/profile">PROFILE</a>
                          </li>
                          {/*<li>
                            <a href="/membership">MEMBERSHIP</a>
                          </li>*/}
                          <li>
                            <a href="/orders-cashfree">ORDERS</a>
                          </li>
                          <li>
                            <a href="/wishlist">WISHLIST</a>
                          </li>
                          <li>
                            <a href="/" onClick={logout}>
                              LOGOUT
                            </a>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <a href="/login">SIGN IN</a>
                          </li>
                          <li>
                            <a href="/create-account">CREATE AN ACCOUNT</a>
                          </li>
                        </>
                      )}
                    </ul>
                  </li>

                  <li className="current dropdown">
                    <a href="#">COLOUR</a>
                    <ul>
                      {colors.map((color, idx) => (
                        <li key={idx}>
                          <Link
                            to={`/products?colors=${color}`}
                            onClick={() => {
                              selectColor(color);
                            }}
                          >
                            {" "}
                            {color}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className="current dropdown">
                    <a href="#">THEME</a>
                    <ul>
                      {themes.map((theme, idx) => (
                        <li key={idx}>
                          <Link
                            to={`/products?themes=${theme}`}
                            onClick={() => {
                              selectTheme(theme);
                            }}
                          >
                            {" "}
                            {theme}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <Link
                      to={`/products?categories=NEW PRODUCTS`}
                      onClick={() => {
                        console.log("selected NEW PRODUCTS");
                        localStorage.setItem(
                          "selectedMainMenu",
                          JSON.stringify({ categories: "NEW PRODUCTS" })
                        );
                        closeMobileSideMenu();
                      }}
                    >
                      {" "}
                      NEW PRODUCTS
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/products?categories=BEST SELLERS`}
                      onClick={() => {
                        console.log("selected BEST SELLERS");
                        localStorage.setItem(
                          "selectedMainMenu",
                          JSON.stringify({ categories: "BEST SELLERS" })
                        );
                        closeMobileSideMenu();
                      }}
                    >
                      {" "}
                      BEST SELLERS
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/products"
                      onClick={() => {
                        console.log("selected CATALOGUE");
                        localStorage.setItem(
                          "selectedMainMenu",
                          JSON.stringify({ categories: null })
                        );
                        closeMobileSideMenu();
                      }}
                    >
                      {" "}
                      CATALOGUE
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

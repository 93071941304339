import React, { useState, useEffect } from "react";
import { Api } from "../lib/Api";
import { Config } from "../Config";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


export const UnitBannerArea = () => {

  const [docs, setDocs] = useState([]);
  const populateTable = async () => {
    const response = await Api.get_all("homecat_imgs");
    await setDocs(response.data);
    console.log("docs", docs);
  };

  
  useEffect(() => {
    populateTable();
  }, []);


    return (
        
    <div className="unit-banner-area">
    <div className="container">
        <div className="row bn-un">

          {
            docs.map((doc,idx)=>(
              <div key={idx} className="col-md-4 col-sm-4 col-xs-12">
                <div className="single-banner" align="center">
                    {/* <a href="/products" target="_blank">
                    <img src={`${Config.IMAGES_BASE}/homecat_imgs/${doc.image}`} />
                    </a> */}
                    {/* <a href="/products" target="_blank">
                        <h2>{`${doc.title}`}</h2>
                    </a> */}
                    {/* <Link
                      to={`/products?categories=${doc.title}`}
                    > */}
                    <img src={`${Config.IMAGES_BASE}/homecat_imgs/${doc.image}`} />

                    <h2>{`${doc.title}`}</h2>
                    {/* </Link> */}
                    <p>{`${doc.subtitle}`}</p>
                </div>
            </div>
            ))
          }
        </div>
    </div>
</div>


    )
}

import React, { useRef, useState, useEffect } from "react";
import { sendCalculatorEmail } from "../lib/email";

export const Calculator = ({ addToCart,setQuantity }) => {
  
  // let _calculations = (JSON.parse(localStorage.getItem("calculations"))) || [] ;

  const [calculations, setCalculations] = useState((JSON.parse(localStorage.getItem("calculations"))) || [] );
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [wall, setWall] = useState("");
  const [nRolls, setNRolls] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [error, setError] = useState({width:false,height:false,wall:false});
  
  console.log('calculations 2',calculations);

  const selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));

  const calculate = async () => {
    if ( height < 1 ){
      setError({width:false,height:true,wall:false});
      return;
    }
    if ( width < 1 ){
      setError({width:true,height:false,wall:false});
      return;
    }
    if ( wall == "" ){
      setError({width:false,height:false,wall:true});
      return;
    }

    let _calculations = JSON.parse(JSON.stringify(calculations));
    
    const _nRolls = (Math.ceil((height * width) / selectedProduct.calcSqft));
    const _subtotal = (_nRolls * selectedProduct.customerPrice).toFixed(2);
    
    _calculations.push({
      height: height,
      width: width,
      wall: wall,
      nRolls: _nRolls,
      subtotal: _subtotal,
    });
    
    setCalculations(_calculations);
    localStorage.setItem("calculations",JSON.stringify(_calculations));

    setWidth(0);
    setHeight(0);
    setWall("");
    setNRolls(0);
    setSubtotal(0);
  };

  const removeWall = (idx) => {
    let _calculations = JSON.parse(JSON.stringify(calculations));
    _calculations.splice(idx,1);
    setCalculations(_calculations);
    localStorage.setItem("calculations",JSON.stringify(_calculations));
  }

  const prepareAndAddToCart = async() => {
    let _calculations = JSON.parse(JSON.stringify(calculations));
    let total = _calculations.reduce((total, wall) => total + wall.nRolls, 0);
    await setQuantity(total);
    await addToCart(total);
    setCalculations([]);
  }

  const [showEmailSent, setShowEmailSent] = useState(false);

  const sendEstimation = async() => {
    //send email here
    const _email = localStorage.getItem("email");
    const _calculations = localStorage.getItem("calculations");
    if (!_email){
      window.location.href ="/login";
      return;
    }

    await sendCalculatorEmail(_email);
    setShowEmailSent(true);

    setTimeout(()=>setShowEmailSent(false),2000);
  }

  return (
    <div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Wallpaper Calculator
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body wallpapwin disable-dbl-tap-zoom">
              <div className="form-group">
                <label>WALL HEIGHT (ft):</label>
                <input
                  type="number"
                  className="form-control"
                  id="height"
                  value={height}
                  onChange={(e) => {
                    setHeight(e.target.value);
                  }}
                  style={error.height?{border:"1px solid red"}:{}}
                />
              </div>

              <div className="form-group">
                <label>WALL WIDTH (ft):</label>
                <input
                  type="number"
                  className="form-control"
                  id="width"
                  value={width}
                  onChange={(e) => {
                    setWidth(e.target.value);
                  }}
                  style={error.width?{border:"1px solid red"}:{}}
                />
              </div>

              <div className="form-group">
                <label>WALL NAME:</label>
                <input type="text" className="form-control" id="name" 
                  value={wall}
                  onChange={(e) => {
                    setWall(e.target.value);
                  }}
                  style={error.wall?{border:"1px solid red"}:{}}
                />
              </div>

              <button type="submit" onClick={calculate} >
                Calculate
              </button>
              
              <table className="table" id="carttable">
                <tbody>
                  {calculations.map((wall,idx)=>(
                  <tr key={idx}>
                    <td>
                      <i class="fa fa-trash remove-handler" onClick={()=>{removeWall(idx)}} style={{cursor:"pointer"}}></i>
                    </td>
                    <td>
                      <span>{wall.wall}</span>
                    </td>
                    <td>
                      <span>{`${wall.height} ft * ${wall.width} ft = ${wall.height*wall.width} sqft (${wall.nRolls} Rolls)`} </span>
                    </td>
                    <td align="right">₹{wall.subtotal}</td>
                  </tr>
                  ))}
                </tbody>
              </table>
              <table className="table" id="totaltable">
                <tbody>
                  <tr id="0010">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td align="right">
                      <b>
                        Total : ₹
                        {calculations.reduce(
                          (total, wall) => (total + Number(wall.subtotal)),
                          0
                        )}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-sm-6 text-center" style={{ marginTop: 2 }}>
                  <button
                    style={{ cursor: "pointer" }}
                    className="calbtn sendmail"
                    onClick={sendEstimation}
                    data-dismiss="modal"
                  >
                    <i className="fa fa-envelope"></i> Send Estimation
                  </button>
                </div>
                <div className="col-sm-6 text-center" style={{ marginTop: 2 }}>
                  <button
                    style={{ cursor: "pointer" }}
                    className="calbtn addtocart1"
                    onClick={prepareAndAddToCart}
                    data-dismiss="modal"
                  >
                    <i className="fa fa-shopping-cart"></i> Add to cart
                  </button>
                </div>
              </div>
            </div>

            <div
              className="modal-footer"
              id="divdangermsg"
              style={{ textAlign: "center", display: "none" }}
            >
              <div className="col-md-12">
                <div className="container-inner">
                  <div className="alert alert-danger" role="alert">
                    <span id="txtspan"></span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal-footer"
              id="divsuccessmsg"
              style={{ textAlign: "center", display: showEmailSent?"block":"none" }}
            >
              <div className="col-md-12">
                <div className="container-inner">
                  <div className="alert alert-success" role="alert">
                    Mail Sent Successfully!.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

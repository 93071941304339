import React from "react";

export const Faqs = () => {
  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/UI/Index">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="home">
                    <a href="/UI/faqs">Faq's</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-details-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="product-list-wrapper">
                <div className="single-product">
                  <div className="product-content">
                    <h2 className="product-name">
                      <a href="#">FAQ'S</a>
                    </h2>

                    <div className="product-desc">
                      <p style={{ textAlign: "justify" }}>
                        <b>1. Do you deliver outside of Hyderabad?</b>
                        <br />
                        A. Yes, we deliver our wallpapers in and around
                        Hyderabad.
                        <br />
                        <br />
                        <b>
                          2. Do you have virtual consultation of wallpapers?
                        </b>
                        <br />
                        A. Yes, we do have our team of expert design consultants
                        who would review your home over a videocall and give you
                        the best possible suggestions in selection of your
                        wallpapers.
                        <br />
                        <br />
                        <b>3. How are your products priced?</b>
                        <br />
                        A. It is a fixed price for the wallpaper, it depends on
                        the Pattern & size. We’ve cut out all unnecessary costs
                        which enable us to offer Luxury wallpapers &
                        Eco-friendly wallcoverings to all our customers at the
                        best value for their money. (Members get a flat 10%
                        Discount every time they make a purchase and Points
                        which can be redeemed in next purchases).
                        <br />
                        <br />
                        <b>4. How can I order?</b>
                        <br />
                        A. Step 1. Click "www.wallpap.in".
                        <br />
                        Step 2. Select the wallpaper pattern & colour from the
                        collection.
                        <br />
                        Step 3. Use Wallpaper calculator to calculate accurately
                        and Click “Add to cart”.
                        <br />
                        Step 4. From ‘Cart” you may proceed to check out and add
                        necessary information for delivery and make the payment
                        online.
                        <br />
                        Step 5. Once we receive the order confirmation, our
                        expert team will deliver the product and Install it as
                        per the scheduled appointment for Wallpaper Installation
                        to your satisfaction.
                        <br />
                        <br />
                        <b>5. Do you do special requests?</b>
                        <br />
                        A. Yes, we accept orders of special requests like.
                        <br />
                        * Customized wallpaper installation services.
                        <br />
                        * Wallpaper consultation
                        <br />
                        * Home Design & Transformation Consultation.
                        <br />
                        * Expert guidance on selection of wallcoverings.
                        <br />
                        <br />
                        <b>6. Where are your products manufactured?</b>
                        <br />
                        A. All our wallpapers are imported.
                        <br />
                        <br />
                        <b>7. How can I clean the product?</b>
                        <br />
                        A. Our wallpapers are highly washable; use a water-wet
                        towel/sponge to clean the wallpaper.
                        <br />
                        <br />
                        <b>8. Can I place the wallpaper out in the open?</b>
                        <br />
                        A. To keep the product in good conditions, it is advised
                        to have it placed inside home.
                        <br />
                        <br />
                        <b>9. How long does it take to deliver?</b>
                        <br />
                        A. Local delivery takes 7-10 days. Our Professional
                        installers would bring the Wallpaper rolls at the time
                        of Installation.
                        <br />
                        <br />
                        <b>
                          10. What is a suitable wall condition to place the
                          product?
                        </b>
                        <br />
                        A. To have the product in its best shape, it is
                        recommended to make sure that your wall is flat, wiped
                        clean & a coated with oil-based primer to achieve the
                        best result.
                        <br />
                        <br />
                        <b>11. What kind of glue is used for the wallpaper?</b>
                        <br />
                        A. We recommend the use of Wallpaper adhesive or
                        wallpaper paste which is a specific adhesive, based on
                        modified starch, used to fix wallpaper to walls.
                        <br />
                        <br />
                        <b>12. Do you have a warranty on your product?</b>
                        <br />
                        A. Yes, we offer a 5 years warranty for the wallpaper
                        colors only.
                        <br />
                        <br />
                        <b>
                          13.Do you have a guidance I can use to help me
                          wallpaper?
                        </b>
                        <br />
                        A. Yes, we offer dedicated expert consultation by one of
                        our qualified interior designers. you may Write to us @
                        Care@wallpap.in / Call: 9100 44 64 25 to avail FREE
                        consultation.
                        <br />
                        <br />
                        <b>14. What are the best patterns for me to use?</b>
                        <br />
                        A. It is totally up to you what patterns you choose.
                        Wallpapers are used in every room of the home so we
                        would recommend you have a look at your surrounds and
                        decide what overall look you like. Get ideas from our
                        articles on styling your home. Allow a decent amount of
                        time before deciding on a pattern. Wallpaper is designed
                        to be there for years if you choose to leave it, so take
                        your time before making the final decision. You can
                        choose from the vast collection we have on the website
                        www.wallpap.in. we insist you add to cart your
                        selections and chose the best suitable when you checkout
                        and place the orders.
                        <br />
                        <br />
                        <b>15. How do I remove wallpaper?</b>
                        <br />
                        A. We recommend you to use our expert wallpaper
                        Installation team for your Wallpaper Installation/
                        Removal/ Replacement services.
                        <br />
                        <br />
                        <b>
                          16. Should I wallpaper by myself or have someone
                          helping me?
                        </b>
                        <br />
                        Hmmmm...that's a hard one. If you have an experience in
                        wallpaper installation well by yourself and have your
                        own way of being organised then we may suggest work by
                        yourself. We recommend having some professional doing it
                        is better. Also, we offer Professional Wallpaper
                        Installation services for FREE.
                        <br />
                        <br />
                        <b>
                          17. Will you refund wallpaper if I decide I don't want
                          it?
                        </b>
                        <br />
                        A. Unfortunately, we do not refund rolls of wallpaper.
                        This is because we have ordered the wallpaper from
                        overseas. These orders are non-refundable.
                        <br />
                        <br />
                        <b>
                          18. What happens if I have a roll or two left over?
                          Can I return them?
                        </b>
                        <br />
                        A. We do not take excess wallpaper rolls back. Sometimes
                        you will have excess because if there is a risk of you
                        having a shortfall, we may suggest you order extra. The
                        reason for this is if you run short during installation,
                        the chance of getting the same roll is less and time
                        consuming. It is best to have more than less. It's also
                        good to have in case you get some damage in the future.
                        <br />
                        <br />
                        At Wallpap we do our best to assist you to work out
                        quantities. We have online calculator. You will also
                        need to consider the wallpaper roll size which is 57 Sq.
                        feet and can easily cover an area of 50 Sq. feet in
                        application. We will help where we can but will not be
                        held responsible for over or under ordering. What we
                        order will always be as per your instructions.
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

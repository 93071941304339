import React, { useRef, useState, useEffect } from "react";

export const CalculatorPg = () => {
  const [calculations, setCalculations] = useState([]);
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [wall, setWall] = useState("");
  const [nRolls, setNRolls] = useState(0);
  const [subtotal, setSubtotal] = useState(0);

  console.log("calculations 2", calculations);


  const calculate = async () => {
    let _calculations = JSON.parse(JSON.stringify(calculations));

    const _nRolls = Math.ceil((height * width) / 50);
    const _subtotal = (_nRolls * 4000).toFixed(2);

    _calculations.push({
      height: height,
      width: width,
      wall: wall,
      nRolls: _nRolls,
      subtotal: _subtotal,
    });

    setCalculations(_calculations);

    setWidth(0);
    setHeight(0);
    setWall("");
    setNRolls(0);
    setSubtotal(0);
  };

  const removeWall = (idx) => {
    let _calculations = JSON.parse(JSON.stringify(calculations));
    _calculations.splice(idx, 1);
    setCalculations(_calculations);
    localStorage.setItem("calculations", JSON.stringify(_calculations));
  };

  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/UI/%20Index">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="home">
                    <a href="/UI/%20WallpaperCalculator">
                      Wallpaper Calculator
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-details-area">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-8 col-xs-12">
              <div className="product-list-wrapper">
                <div className="single-product">
                  <div className="product-content">
                    <h2 className="product-name" style={{ display: "none" }}>
                      <a href="#">Sample Wallpaper Calculator</a>
                    </h2>

                    <div className="product-desc">
                      <p style={{ textAlign: "justify" }}>
                        <b>Cover your room with our wallpaper calculator</b>
                        <br />
                        Calculate the perfect number of rolls to purchase for
                        your room with wallcovering calculator above.
                        <br />
                        We Suggest you avoid guessing while you place an order
                        online and don’t get caught halfway through your home
                        makeover without enough wallpaper to complete the job
                        and match up your carefully chosen wallpaper patterns.
                        <br />
                        <br />
                        You can also plan your budget efficiently by ensuring
                        you don’t over order on backing wallpaper and wallpaper
                        rolls and save money to put towards other home
                        improvements.
                        <br />
                        <br />
                        Our standard Wallpap stock is 10 metres long by 53
                        centimetres wide and many have regular repeating
                        patterns making it simple to decorate a whole room or
                        feature wall.
                        <br />
                        <br />
                        <b>How to use our wallpaper calculator</b>
                        <br />
                        The way your wallpaper fits will differ depending on the
                        features of your walls. For simple bedrooms if you’re
                        intending to cover fully with wallpaper you can send in
                        a query with the form and your intended measurements, we
                        will get to work.
                        <br />
                        <br />
                        When measuring we suggest having a person on each end of
                        the tape measure to ensure you are measuring a straight
                        line.
                        <br />
                        <br />
                        You can also add a window to the wallpaper calculator so
                        measure the whole frame at its largest height and width.
                        <br />
                        <br />
                        Then click Calculate and order your wallpaper by colour
                        or theme from our website.
                        <br />
                        <br />
                        We will ensure smooth wallpaper installation process.
                        <br />
                        Ready to get wallpapering? Pick your favourite wallpaper
                        theme here and get stuck in!
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="product-list-wrapper">
                <div className="single-product">
                  <div className="product-content">
                    <h5 className="modal-title" id="exampleModalCenterTitle">
                      Wallpaper Calculator{" "}
                    </h5>
                    <br />
                    <div className="wallpapwin">
                      <div className="form-group">
                        <label>WALL HEIGHT (ft):</label>
                        <input
                          type="number"
                          className="form-control"
                          id="height"
                          value={height}
                          onChange={(e) => {
                            setHeight(e.target.value);
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label>WALL WIDTH (ft):</label>
                        <input
                          type="number"
                          className="form-control"
                          id="width"
                          value={width}
                          onChange={(e) => {
                            setWidth(e.target.value);
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label>WALL NAME:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          value={wall}
                          onChange={(e) => {
                            setWall(e.target.value);
                          }}
                        />
                      </div>

                      <button type="submit" onClick={calculate}>
                        Calculate
                      </button>

                      <table className="table" id="carttable">
                        <tbody>
                          {calculations.map((wall, idx) => (
                            <tr key={idx}>
                              <td>
                                <i
                                  class="fa fa-trash remove-handler"
                                  onClick={() => {
                                    removeWall(idx);
                                  }}
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </td>
                              <td>
                                <span>{wall.wall}</span>
                              </td>
                              <td>
                                <span>
                                  {`${wall.height} ft * ${wall.width} ft = ${
                                    wall.height * wall.width
                                  } sqft (${wall.nRolls} Rolls)`}{" "}
                                </span>
                              </td>
                              <td align="right">₹{wall.subtotal}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <table className="table" id="totaltable">
                        <tbody>
                          <tr id="0010">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td align="right">
                              <b>
                                Total : ₹
                                {calculations.reduce(
                                  (total, wall) =>
                                    total + Number(wall.subtotal),
                                  0
                                )}
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

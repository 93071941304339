import React, { useRef, useState, useEffect } from "react";
import { Api } from "./lib/Api";
import { OrderDetailsModal } from "./user-components/OrderDetailsModal";
import { OrderDetailsModalCashfree } from "./user-components/OrderDetailsModalCashfree";

export const OrdersCashfree = () => {
  const orderId = JSON.parse(localStorage.getItem("orderid")) || 0;
  const email = localStorage.getItem("email");

  const emptyOrder = {
    orderid:"",
    amount : 0,
    status : "NOT AVAILABLE",
    payment : {
      products : [],
      amount : {total:0},

    }
  }


  const [getting, setGetting] = useState(true);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(emptyOrder);
  
  async function getAllOrders(){
    let response = await Api.get_all("orders", `email=${email}`);
    console.log("getOrder:resp", response);
    let _statusAPI = [];
    
    setGetting(false);
    setOrders(response.data);
  }
  
  useEffect(async () => {
    console.log("useEffect");
    // effect
    getAllOrders();
    Api.verifyToken();

    return () => {
      // cleanup
    };
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="category3">
                    <span>My Orders</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cart-area-start">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
              <table className="cart-table" id="cart">
                  <thead>
                    <tr>
                      <th>Order #</th>
                      <th>Date/Time</th>
                      <th>Status</th>
                      <th>Amount</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order,idx)=>(
                      <tr key={idx}>
                        <td>
                          {order.orderid}
                        </td>
                        <td>
                          {new Date(Number(order.orderid)).toISOString().split('T')[0]}
                        </td>
                        <td>
                          <h6>
                            {/* {order.cashfreeGW.order_status} */}
                            {order.status === "PAID" || order.status === 'SUCCESS'?"PAID": ( order.status || "PENDING" )}
                          </h6>
                        </td>
                        <td>
                          <div className="cart-price">
                            {/* ₹{order.payment.amount.grandTotal} */}
                            ₹{order.payment.amount.grandTotal}
                          </div>
                        </td>
                        <td>
                        <a
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            href="#"
                            onClick={()=>setSelectedOrder(order)}
                          >
                            Details
                          </a>
                        </td>
                      </tr>
                    ))}

                    {orders.length > 0 ? (
                      <tr id="lastrow">
                        <td className="actions-crt" colspan="7">
                          <div align="center">
                            <a
                              className="crbtn"
                              href="products"
                            >
                              Continue Shopping
                            </a>
                          </div>
                        </td>
                      </tr>
                    ) : getting? 
                    (
                      <tr id="cart-is-empty">
                        <td className="actions-crt" colspan="7">
                          <div className="">
                            <div className="col-md-12 col-sm-4 col-xs-4 align-left">
                              <h4>Fetching Orders .. Please Wait</h4>
                              <br />
                              
                            </div>
                          </div>
                        </td>
                      </tr>
                    ):(
                      <tr id="cart-is-empty">
                        <td className="actions-crt" colspan="7">
                          <div className="">
                            <div className="col-md-12 col-sm-4 col-xs-4 align-left">
                              <h4>No Order found!</h4>
                              <br />
                              <a className="cbtn" href="/products">
                                Shop Now
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <OrderDetailsModalCashfree order={selectedOrder}/>
    </div>
  );
};

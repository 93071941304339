import React, { useRef, useState, useEffect } from "react";
import { Api } from "./lib/Api";
import { OrderDetailsModal } from "./user-components/OrderDetailsModal";
import { OrderDetailsModalCashfree } from "./user-components/OrderDetailsModalCashfree";


export const OrderCashfree = ({setNCartItems}) => {


  let query = window.location.href.split("?")[1] || "";
  const orderId = query.split("order_id=")[1];

  console.log("orderid",orderId);

  // const orderId = JSON.parse(localStorage.getItem("orderid")) || 0;
  const email = localStorage.getItem("email");

  const emptyOrder = {
    orderid:"",
    amount : 0,
    status : "NOT AVAILABLE",
  }

  const emptyDetails = {
    orderid:"",
    amount : 0,
    status : "NOT AVAILABLE",
    payment : {
      products : [],
      amount : {total:0},

    }
  }

  const [order, setOrder] = useState(emptyOrder);
  const [details, setDetails] = useState(emptyDetails);
  
  async function getOrder(){
    let response = await Api.get_all("orders", `email=${email}&orderid=${orderId}`);
    console.log("getOrder:resp", response);
    if (response.data.length < 1 ){
      alert(`Order Id ${orderId} does not exist in DB`)
      return;
    }
    setDetails( response.data[0]);

    let response1 = await Api.cashfreeGetOrder({"email":email,"orderid":orderId});
    console.log("cash free getOrder:resp", response1);
    if (response1.data.length < 1 ){
      alert(`Order Id ${orderId} does not exist in DB`)
      return;
    }

    const _order = response1.data;
    if (_order.status == "PAID" || _order.status == "SUCCESS"){
      localStorage.removeItem("cartProducts");
      setNCartItems(0)
    }else {
      _order.status = _order.status || "PENDING";
    }
    setOrder( _order);
    
  }
  
  useEffect(async () => {
    console.log("useEffect");
    // effect
    Api.verifyToken();
    getOrder();

    return () => {
      // cleanup
    };
  }, []);
  
  return (
    <div>
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container-inner">
                <ul>
                  <li className="home">
                    <a href="/">
                      <i className="fa fa-home"></i>
                    </a>
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="category3">
                    <span>Your Order Is Successful</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cart-area-start">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
              <table className="cart-table" id="cart">
                  <thead>
                    <tr>
                      <th>Order #</th>
                      <th>Status</th>
                      <th>Amount</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>
                          {order.orderid}
                        </td>
                        <td>
                          <h6>
                            {order.status}
                          </h6>
                        </td>
                        <td>
                          <div className="cart-price">
                            ₹{order.amount}
                          </div>
                        </td>
                        <td>
                        <a
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            href="#"
                            // className="crbtn"
                          >
                            Details
                          </a>
                        </td>
                      </tr>

                      <tr id="lastrow">
                        <td className="actions-crt" colspan="7">
                          <div align="center">
                            <a
                              className="crbtn"
                              href="products"
                            >
                              Continue Shopping
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr id="cart-is-empty">
                        <td className="actions-crt" colspan="7">
                          <div className="">
                            <div className="col-md-12 col-sm-4 col-xs-4 align-left">
                              <h6>
                                <a className="cbtn" href="/orders-cashfree">
                                  Check All Orders Here
                                </a>
                              </h6>
                              <br />
                              
                            </div>
                          </div>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <OrderDetailsModalCashfree order={details}/>
    
    </div>
  );
};

import { Config } from "../Config";
import { Api, loadScript } from "../lib/Api";
import React, { useRef, useState, useEffect } from "react";

export const PlaceOrderCashfree = ({ profile, placeOrder, payment, orderid }) => {
  const email = localStorage.getItem("email");

  var cashfree;
  // function Razorpay(){console.log("Empty Placeholder")};

  async function loadCashfree() {
    const res = await loadScript(
        "https://sdk.cashfree.com/js/ui/2.0.0/cashfree.prod.js"
        // "https://sdk.cashfree.com/js/ui/2.0.0/cashfree.sandbox.js"

    );
    if (!window.Cashfree) {
      alert("Payment Gateway not reachable, check your internet connection");
    } else {
      console.log("CashfreeLoaded");
    }
  }

  async function showCashfree(e) {
    let updateOrder = await Api.upsert(
        "orders",
        `email=${email}&orderid=${orderid}`,
        { payment: payment,}
    );
    let params = {
      orderid: orderid.toString(),
      amount: (payment.amount && payment.amount.grandTotal) || 0,
      name: profile.name,
      email: profile.email,
      mobile: profile.mobile,
      customerid : profile._id,
    };

    console.log("params", params);
    try {
      let response = await Api.cashfreeCreateOrder(params);
      console.log("cashfree response", response);

      const paymentSessionId = response.data.payment_session_id;
      if (!paymentSessionId) {
        throw Error(`Cashfree response invalid, no payment-session-id`);
      }
      const cf = new window.Cashfree(paymentSessionId);
      cf.redirect();
    } catch (e) {
      console.error(e);
      const errMsg = e?.body?.message || e.message || 'Invalid Cashfree Request';
      window.alert(`Unable to initiate payment. Reason: ${errMsg}`); //TODO: show this as a modal dialog...
    }
  }

  useEffect(async () => {
    console.log("useEffect PlaceOrderCashfree");
    await loadCashfree().catch(err=>err);

    return () => {
      // cleanup
    };
  }, []);

  return (
      <div>
        <button
            style={{ marginLeft: "45%" }}
            className="crbtn"
            onClick={showCashfree}
        >
          {" "}
          PAY VIA Cashfree
        </button>
      </div>
  );
};

import React, { useState, useEffect } from "react";
import { Api } from "../lib/Api";
import { Config } from "../Config";

export const InstagramArea = () => {

  const [docs, setDocs] = useState([]);
  const populateTable = async () => {
    const response = await Api.get_all("insta_imgs");
    await setDocs(response.data);
    console.log("docs", docs);
  };

  
  useEffect(() => {
    populateTable();
  }, []);

  return (
    <>
      <main className="unit-banner-area instgram--main">
        <div className="container">
          <div className="column main">
            <div className="widget block block-static-block">
              <div className="row instagram--block">
                <div className="insta-item">
                  {
                    docs.map((doc,idx)=>(
                      <div key = {idx} className="instaimg_win hidden-sm col-lg-2 col-md-3 col-sm4 col-xs-6 pad_10_10">
                        <a className="image-link">
                        <img src={`${Config.IMAGES_BASE}/insta_imgs/${doc.image}`} />
                          {/* <img width="100%" src="/insta-imgs/INSTA12.jpg" /> */}
                        </a>
                      </div>
                  
                    ))
                  }

                  
                

                <div className="insta-follow">
                  <i className="fa icon-instagram"></i>
                  <h3>Instagram</h3>
                  <span>@WallpapWallcoverings</span>{" "}
                  <a
                    className="btn btn-default"
                    href="https://www.instagram.com/wallpapwallcoverings/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Follow
                  </a>
                </div>
              
              
              </div>
            </div>
          </div>
        </div>
        </div>
      </main>
    </>
  );
};
